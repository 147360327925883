import { hasValue } from '@lego/mst-error-utilities';
import { Grid } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback, useEffect, useState } from 'react';
import { useFragment } from 'react-relay';
import { useTranslation } from '../../utility/i18n/translation';
import { EquipmentSearchTextField } from '../components/equipment/EquipmentSearchTextField';
import { ErrorMessage } from '../components/ErrorMessage';
import { useCreateTicketContext } from './create-ticket-context';
import { CreateTicketStepButtons } from './CreateTicketStepButtons';
import { useQueryParam } from './useQueryParam';
import { CreateTicketEquipmentStep_equipment$key } from './__generated__/CreateTicketEquipmentStep_equipment.graphql';

interface CreateTicketEquipmentStepProps {
  equipment: CreateTicketEquipmentStep_equipment$key | null;
  loading?: boolean;
}

export const CreateTicketEquipmentStep: FC<CreateTicketEquipmentStepProps> = ({
  equipment: equipmentRef,
  loading: isLoading,
}) => {
  const { translate } = useTranslation();
  const params = useQueryParam();
  const { state, dispatch } = useCreateTicketContext();
  const routeId = params.get('equipmentId');

  const [error, setError] = useState<string | undefined>();

  const equipment = useFragment(
    graphql`
      fragment CreateTicketEquipmentStep_equipment on Mould {
        statusCode
      }
    `,
    equipmentRef
  );

  const onSearchStringCleared = useCallback(() => {
    dispatch({ type: 'set_equipment_search', payload: null });
  }, [dispatch]);

  const onSearchStringChanged = useCallback(
    (equipmentNumber: number | null) => {
      if (hasValue(equipmentNumber)) {
        dispatch({ type: 'set_equipment_search', payload: equipmentNumber });
      } else {
        onSearchStringCleared();
      }
    },
    [dispatch, onSearchStringCleared]
  );

  const { currentStep, equipmentId } = state;

  useEffect(() => {
    if (equipmentId) {
      setError(undefined);
    }
  }, [equipmentId]);

  const moveForward = () => {
    if (hasValue(equipmentId)) {
      dispatch({ type: 'step_forward' });
    } else {
      setError(translate('CREATE_TICKET.ERROR.EQUIPMENT_NOT_SET', 'No equipment selected'));
    }
  };

  return (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <EquipmentSearchTextField
          onEquipmentSelected={onSearchStringChanged}
          showPrefixLabel={currentStep === 'equipment'}
          showInput={currentStep === 'equipment'}
          initialEquipmentNumber={equipmentId?.toString() ?? routeId ?? undefined}
        />
      </Grid>
      {currentStep === 'equipment' && (
        <Grid item>
          {error && <ErrorMessage message={error} />}
          <CreateTicketStepButtons
            moveForward={moveForward}
            forwardDisabled={equipment?.statusCode === 999}
            loading={isLoading}
          />
        </Grid>
      )}
    </Grid>
  );
};

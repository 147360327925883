/**
 * @generated SignedSource<<10a6f42197139e2130e7b25cf912310a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentDetailsHeaderFragment$data = {
  readonly __typename: string;
  readonly id: string;
  readonly qualityManagementInspection?: {
    readonly validationResult: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentDescription_equipment" | "EquipmentGroupChip_equipment" | "EquipmentNumber_equipment">;
  readonly " $fragmentType": "EquipmentDetailsHeaderFragment";
};
export type EquipmentDetailsHeaderFragment$key = {
  readonly " $data"?: EquipmentDetailsHeaderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentDetailsHeaderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentDetailsHeaderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QualityManagementInspection",
          "kind": "LinkedField",
          "name": "qualityManagementInspection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "validationResult",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Mould",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquipmentNumber_equipment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquipmentDescription_equipment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquipmentGroupChip_equipment"
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "7dbaf712415b10e629f17cab19bd48d8";

export default node;

import { hasValue } from '@lego/mst-error-utilities';
import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback, useState } from 'react';
import { useFragment, useMutation } from 'react-relay';
import { SimpleDialog } from '../../../components/shared/SimpleDialog';
import { useTranslation } from '../../../utility/i18n/translation';
import { useGMSnackbar } from '../../../utility/snackbar';
import {
  AddOrEditDimmingDialogContent,
  DimmingMutationSharedVariables,
  isDimmingAddOrUpdateValid,
} from './AddDimmingDialog';
import { EditDimmingDialogMutation } from './__generated__/EditDimmingDialogMutation.graphql';
import { EditDimmingDialog_dimmingDetails$key } from './__generated__/EditDimmingDialog_dimmingDetails.graphql';

export const EditDimmingDialog: FC<{
  onDismiss: () => void;
  dimmingDetailRef: EditDimmingDialog_dimmingDetails$key;
}> = ({ dimmingDetailRef, onDismiss }) => {
  const { cavityNumber, isPermanent, expectedRemovalDescription, reason, id } = useFragment(
    graphql`
      fragment EditDimmingDialog_dimmingDetails on DimmingDetails {
        id
        cavityNumber
        expectedRemovalDescription
        isPermanent
        reason
      }
    `,
    dimmingDetailRef
  );

  const { translate } = useTranslation();
  const { showSnack } = useGMSnackbar();

  const [editVariables, setEditVariables] = useState<DimmingMutationSharedVariables | undefined>({
    cavityNumber,
    permanent: isPermanent,
    expectedRemovalDescription: expectedRemovalDescription,
    reason: reason ?? '',
  });

  const [commitMutation, loading] = useMutation<EditDimmingDialogMutation>(graphql`
    mutation EditDimmingDialogMutation($input: MutationMouldUpdateDimmingInput!) {
      mouldUpdateDimming(input: $input) {
        __typename
        ... on DimmingNeedsRemovalDateError {
          message
        }
        ... on MutationMouldUpdateDimmingSuccess {
          data {
            dimmings {
              details {
                ...TDDimmingDetails_dimmingDetails
              }
            }
          }
        }
      }
    }
  `);

  const variablesValid = isDimmingAddOrUpdateValid(editVariables);
  const onSavePressed = useCallback(() => {
    if (editVariables && variablesValid) {
      commitMutation({
        variables: {
          input: {
            expectedRemovalDescription: editVariables.expectedRemovalDescription,
            permanent: editVariables.permanent,
            reason: editVariables.reason,
            dimmingId: id,
          },
        },
        onCompleted: (result) => {
          if (result.mouldUpdateDimming?.__typename === 'DimmingNeedsRemovalDateError') {
            showSnack({
              message: translate(
                'TICKET_DETAILS.CARDS.DIMMINGS.NON_PERM_NEEDS_REMOVAL_DATE',
                'A non permanent dimming needs to provide an expected removal date'
              ),
              variant: 'warning',
            });
            return;
          }
          setEditVariables(undefined);
          onDismiss();
        },
      });
    }
  }, [editVariables, variablesValid, commitMutation, id, onDismiss, showSnack, translate]);

  return (
    <SimpleDialog
      onDismiss={onDismiss}
      open={true}
      title={translate('TICKET_DETAILS.CARDS.DIMMINGS.EDIT.TITLE', 'Edit dimming')}
      primaryAction={{
        primaryActionLabel: translate('TICKET_DETAILS.CARDS.DIMMINGS.EDIT.SAVE', 'Save'),
        primaryActionPressed: onSavePressed,
        primaryActionLoading: loading,
        primaryActionDisabled: !hasValue(editVariables) || !variablesValid,
      }}
      secondaryAction={{
        secondaryActionLabel: translate('TICKET_DETAILS.CARDS.DIMMINGS.EDIT.CANCEL', 'Cancel'),
        secondaryActionPressed: onDismiss,
        secondaryActionDisabled: loading,
      }}
      content={{
        type: 'node',
        node: (
          <AddOrEditDimmingDialogContent
            onVariablesChange={setEditVariables}
            currentVariables={editVariables}
            variant="edit"
          />
        ),
      }}
      maxWidth={'md'}
    />
  );
};

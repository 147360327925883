import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider, Grid, List, ListItemText, styled } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef, useCallback, useState } from 'react';
import { useTranslation } from '../i18n/translation';

const StyledSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    minWidth: '30vw',
  },
}));

interface StyledIconButtonDynamicProps extends IconButtonProps {
  expanded?: boolean;
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<StyledIconButtonDynamicProps>(({ expanded, theme }) => ({
  color: 'white',
  padding: '8px 8px',
  transform: 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  ...(expanded && {
    transform: 'rotate(0deg)',
  }),
}));

// https://iamhosseindhv.com/notistack/demos#custom-snackbar
// eslint-disable-next-line react/display-name
export const CustomSnackWithErrorDetails = forwardRef<
  HTMLDivElement,
  { id: string | number; message: string; errorLines: string[] }
>(({ id, message, errorLines }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const { translate } = useTranslation();

  const errorDetailsModalTitle = translate('MODALS.ERROR_DETAILS_MODAL.TITLE', 'Error details');

  const errorDetailsModalPreface = translate(
    'MODALS.ERROR_DETAILS_MODAL.PREFACE',
    'This is the technical error details, provided in English. Ask a specialist or support for further assistance'
  );

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <StyledSnackbarContent ref={ref}>
      <Card sx={{ backgroundColor: 'red', width: '100%' }}>
        <CardActions
          style={{
            padding: '8px 8px 8px 16px',
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={{ color: 'white' }}>{message}</Typography>
          <div style={{ marginLeft: 'auto' }}>
            {errorLines && (
              <StyledIconButton onClick={handleExpandClick} size="large" expanded={expanded}>
                <ExpandMoreIcon />
              </StyledIconButton>
            )}
            <StyledIconButton onClick={handleDismiss} size="large">
              <CloseIcon />
            </StyledIconButton>
          </div>
        </CardActions>
        {errorLines && (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Paper sx={{ padding: 2 }}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h5">{errorDetailsModalTitle}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{errorDetailsModalPreface}</Typography>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid item maxHeight={200} overflow="auto">
                  <List>
                    {errorLines.map((text) => (
                      <ListItemText key={text} primary={text} />
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Collapse>
        )}
      </Card>
    </StyledSnackbarContent>
  );
});

import { FC } from 'react';
import { isEquipmentOfTypeMould } from '../../../utility/graphqlTypeguards';
import { useTranslation } from '../../../utility/i18n/translation';
import { TDDimmingsContent } from '../../ticket-details/TDDimmingDetails';
import { TicketDetailsMouldDimmingDetails } from '../../ticket-details/__apollo__/TicketDetailsMouldDimmingDetails';
import { ARDCollapsibleSidebarWidget } from './ARDCollapsibleSidebarWidget';

export const ARDDimmings: FC<TicketDetailsMouldDimmingDetails> = ({ equipment }) => {
  const { translate } = useTranslation();

  if (!isEquipmentOfTypeMould(equipment)) {
    return null;
  }

  return (
    <ARDCollapsibleSidebarWidget
      title={translate('ADD_REPAIR_DOCUMENTATION.SIDEBAR_WIDGETS.DIMMINGS.TITLE', 'Dimmings')}
    >
      <TDDimmingsContent {...equipment.value} />
    </ARDCollapsibleSidebarWidget>
  );
};

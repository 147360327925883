/**
 * @generated SignedSource<<f5ab3052c492418aba4a94632c0fcb53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentHistoryDamageFilter_ticketsConnectionFacets$data = {
  readonly damage: ReadonlyArray<{
    readonly catalogId: string;
    readonly shortText: string;
  }>;
  readonly " $fragmentType": "EquipmentHistoryDamageFilter_ticketsConnectionFacets";
};
export type EquipmentHistoryDamageFilter_ticketsConnectionFacets$key = {
  readonly " $data"?: EquipmentHistoryDamageFilter_ticketsConnectionFacets$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryDamageFilter_ticketsConnectionFacets">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentHistoryDamageFilter_ticketsConnectionFacets",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CatalogFacet",
      "kind": "LinkedField",
      "name": "damage",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "catalogId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shortText",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TicketsConnectionFacets",
  "abstractKey": null
};

(node as any).hash = "c47ea4ff1d75b02ea2df167b63683c6a";

export default node;

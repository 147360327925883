import { useEffect } from 'react';
import { getNodeEnvironment, PossibleEnvironments } from '../../utility/environment';
import { useScript } from './script';

const Sources: Partial<Record<PossibleEnvironments, string>> = {
  local: 'https://assets.adobedtm.com/113bc1fcf650/594050d70a74/launch-c4a6334e668b-staging.min.js',
  uat: 'https://assets.adobedtm.com/113bc1fcf650/594050d70a74/launch-c4a6334e668b-staging.min.js',
  production: 'https://assets.adobedtm.com/113bc1fcf650/594050d70a74/launch-3417095f6faf.min.js',
};

type DataLayer = {
  application?: string;
  team?: string;
  area?: string;
  process?: string;
  language?: string;
};

declare global {
  interface Window {
    dataLayer: DataLayer;
  }
}

export const useAnalytics = (dataLayer: DataLayer = {}) => {
  window.dataLayer = window.dataLayer || {};
  window.dataLayer = {
    application: 'MaintainIt-web',
    team: '07.02.05 Plant Maintenance',
    ...window.dataLayer,
    ...dataLayer,
  };
  useEffect(() => {
    window.dispatchEvent(new Event('pmStartEvent'));
  }, [dataLayer.application, dataLayer.team, dataLayer.area, dataLayer.process, dataLayer.language]);
  useScript(Sources[getNodeEnvironment()]);
};

import { hasValue } from '@lego/mst-error-utilities';
import { Container, Grid, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { ImageSearchComponent } from '../components/image-search/ImageSearchComponent';
import { ImageSearchListComponent } from '../components/image-search/ImageSearchListComponent';
import { useConfidentialityQuery } from '../components/image-search/ImageSearchQueries';
import { ConfidentialityReportListVariables } from '../components/image-search/__apollo__/ConfidentialityReportList';
import { AREA_ALL_OPTION } from '../components/profile/Area';
import { PROCESSES_ALL_OPTION } from '../components/profile/Process';
import {
  ConfidentialitySearchProvider,
  useConfidentialitySearchContext,
} from '../contexts/confidentiality-search/confidentiality-search-context';
import { RouteRequiringPermission } from '../Router';
import { useTranslation } from '../utility/i18n/translation';
import { usePermissions } from '../utility/permissions/usePermissions';

export const ImageSearchContainer: FC = () => {
  const { canViewConfidentialityReport } = usePermissions();

  return (
    <RouteRequiringPermission allowed={canViewConfidentialityReport}>
      <ConfidentialitySearchProvider>
        <Wrapped />
      </ConfidentialitySearchProvider>
    </RouteRequiringPermission>
  );
};

const Wrapped: FC = () => {
  const { translate } = useTranslation();
  const [loadImages, queryStatus] = useConfidentialityQuery();

  const variables = useVariablesFromContext();

  const onSearchPressed = useCallback(() => {
    loadImages({ variables });
  }, [loadImages, variables]);

  const handleOnEndReached = useCallback(() => {
    const { data } = queryStatus;
    if (data && data.confidentialityQuery.pageInfo.hasNextPage) {
      queryStatus.fetchMore({
        variables: {
          after: data.confidentialityQuery.pageInfo.endCursor,
        },
      });
    }
  }, [queryStatus]);

  return (
    <Container maxWidth="xl" style={{ marginTop: 24 }}>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h1">{translate('IMAGE_SEARCH.TITLE', 'Image search')}</Typography>
        </Grid>
        <Grid item>
          <Typography style={{ marginTop: 20 }}>
            {translate(
              'IMAGE_SEARCH.TITLE_DESCRIPTION',
              'Use the search criteria to find images from the MaintainIt database'
            )}
          </Typography>
        </Grid>
        <Grid item>
          <ImageSearchComponent onSearchPressed={onSearchPressed} />
        </Grid>
        <Grid item style={{ marginBottom: 200 }}>
          <ImageSearchListComponent {...queryStatus} handleOnEndReached={handleOnEndReached} />
        </Grid>
      </Grid>
    </Container>
  );
};

const useVariablesFromContext = (): ConfidentialityReportListVariables => {
  const {
    state: { createdFrom, createdTo, selectedAreaId, selectedProcessId, usernameSearchTerm },
  } = useConfidentialitySearchContext();

  const pageSize = 32;

  return {
    first: pageSize,
    input: {
      areaId: selectedAreaId !== AREA_ALL_OPTION ? selectedAreaId : undefined,
      processId: selectedProcessId !== PROCESSES_ALL_OPTION ? selectedProcessId : undefined,
      createdAtFrom: createdFrom?.toISOString(),
      createdAtTo: createdTo?.toISOString(),
      userSearchTerm: hasValue(usernameSearchTerm) && usernameSearchTerm.length > 0 ? usernameSearchTerm : undefined,
    },
  };
};

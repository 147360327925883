import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Fab, useScrollTrigger, Zoom } from '@mui/material';
import { Box } from '@mui/system';
import { MouseEventHandler, useCallback, VFC } from 'react';

export const BackToTopAnchor = 'back-to-top-anchor';

export const BackToTop: VFC = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
    const anchor = document.querySelector(`#${BackToTopAnchor}`);

    if (!anchor) {
      return;
    }

    anchor.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, []);

  return (
    <Zoom in={trigger}>
      <Box onClick={handleClick} role="presentation" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </Box>
    </Zoom>
  );
};

import { hasValue } from '@lego/mst-error-utilities';
import { Grid } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, ReactElement } from 'react';
import { useFragment } from 'react-relay';
import { CardWithTitle } from '../../../components/shared/CardWithTitle';
import { GMImageWithFallback } from '../../../components/shared/GMImageComponents';
import { TextWithLabel } from '../../../components/shared/TextWithLabel';
import { useTranslation } from '../../../utility/i18n/translation';
import { TDDqpData_Mould$key } from './__generated__/TDDqpData_Mould.graphql';

export const TDDqpData: FC<{
  equipment: TDDqpData_Mould$key;
}> = ({ equipment: equipmentRef }) => {
  const { translate } = useTranslation();
  const { developmentQualifyingProduction } = useFragment(
    graphql`
      fragment TDDqpData_Mould on Mould {
        developmentQualifyingProduction {
          constructionDqp
          projectId
          constructionTechnology1
          constructionTechnology2
          constructionTechnology3
          projectOwner {
            employeeNumber
            image {
              uri
            }
          }
        }
      }
    `,
    equipmentRef
  );
  if (!hasValue(developmentQualifyingProduction)) {
    return null;
  }

  return (
    <CardWithTitle title={translate('TICKET_DETAILS.CARDS.DQP_DATA.TITLE', 'Dqp Data')}>
      <Grid container direction="row" rowSpacing={3}>
        <Grid item xs={4}>
          <DQPDataItem
            text={developmentQualifyingProduction?.constructionDqp}
            label={translate('TICKET_DETAILS.CARDS.DQP_DATA.LABELS.CONSTRUCTION_STATUS', 'Construction Status')}
          />
        </Grid>
        <Grid item xs={4}>
          <DQPDataItem
            icon={
              <GMImageWithFallback
                style={{
                  height: 32,
                  width: 32,
                  borderRadius: 50,
                }}
                url={developmentQualifyingProduction?.projectOwner?.image.uri}
              />
            }
            text={developmentQualifyingProduction?.projectOwner?.employeeNumber.toString()}
            label={translate('TICKET_DETAILS.CARDS.DQP_DATA.LABELS.PROJECT_OWNER', 'Project Owner')}
          />
        </Grid>
        <Grid item xs={4}>
          <DQPDataItem
            text={developmentQualifyingProduction?.projectId}
            label={translate('TICKET_DETAILS.CARDS.DQP_DATA.LABELS.PROJECT_ID', 'Project ID')}
          />
        </Grid>
        <Grid item xs={4}>
          <DQPDataItem
            text={developmentQualifyingProduction?.constructionTechnology1}
            label={translate('TICKET_DETAILS.CARDS.DQP_DATA.LABELS.CONSTRUCTION_TECHNOLOGY', 'Construction Technology')}
          />
        </Grid>
        <Grid item xs={4}>
          <DQPDataItem
            text={developmentQualifyingProduction?.constructionTechnology2}
            label={translate(
              'TICKET_DETAILS.CARDS.DQP_DATA.LABELS.CONSTRUCTION_TECHNOLOGY2',
              'Construction Technology 2'
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <DQPDataItem
            text={developmentQualifyingProduction?.constructionTechnology3}
            label={translate(
              'TICKET_DETAILS.CARDS.DQP_DATA.LABELS.CONSTRUCTION_TECHNOLOGY3',
              'Construction Technology 3'
            )}
          />
        </Grid>
      </Grid>
    </CardWithTitle>
  );
};

const DQPDataItem: FC<{
  icon?: ReactElement;
  text: string | undefined | null;
  label: string;
}> = ({ icon, text, label }) => {
  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid
        item
        sx={{
          mr: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {icon}
      </Grid>
      <Grid item>
        <TextWithLabel label={label} text={text ?? '---'} />
      </Grid>
    </Grid>
  );
};

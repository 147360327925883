import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from '../../../utility/i18n/translation';
import { Icons } from '../../../utility/icons';
import { DurationLabelWithChange } from './AddTimeOverviewRow';

export const AddTimeTotalTimeSpentRow: FC<{
  totalTimeSpentInMinutes: number;
  totalTimeAfterChanges: number;
}> = ({ totalTimeSpentInMinutes, totalTimeAfterChanges }) => {
  const { translate } = useTranslation();

  return (
    <Grid container data-cy="AddTimeTotalTimeSpentRow" flexDirection="row">
      <Grid
        item
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: 8,
        }}
      >
        <Icons.Hashtag fill="black" style={{ fontSize: 16 }} />
      </Grid>
      <Grid item style={{ marginLeft: 18, flex: 1 }}>
        <Typography variant="subtitle2">{translate('ADD_TIME.OVERVIEW.TOTAL_MANHOURS', 'Total time spent')}</Typography>
      </Grid>
      <Grid item>
        <DurationLabelWithChange
          totalTimeInMinutesBeforeChanges={totalTimeSpentInMinutes}
          totalTimeInMinutesAfterChanges={totalTimeAfterChanges}
        />
      </Grid>
    </Grid>
  );
};

import { Grid, Skeleton, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { ReactElement } from 'react';
import { useFragment } from 'react-relay';
import { useTranslation } from '../../utility/i18n/translation';
import { TicketErrorDescription } from '../components/ticket/TicketErrorDescription';
import { skeletonify } from '../skeleton';
import { EquipmentHistoryCardDescription_ticket$key } from './__generated__/EquipmentHistoryCardDescription_ticket.graphql';

type StructureComponentProps = {
  title: ReactElement;
  errorDescription: ReactElement;
};

const ActualComponent = (props: { ticket: EquipmentHistoryCardDescription_ticket$key }): StructureComponentProps => {
  const { ticket: ticketRef } = props;
  const { translate } = useTranslation();

  const ticket = useFragment(
    graphql`
      fragment EquipmentHistoryCardDescription_ticket on Ticket {
        ...TicketErrorDescription_ticket
      }
    `,
    ticketRef
  );

  return {
    title: (
      <Typography variant="subtitle2" textAlign="start">
        {translate('TICKET_HISTORY_CARD.ERROR_DESCRIPTION', 'Error description')}
      </Typography>
    ),
    errorDescription: <TicketErrorDescription ticket={ticket} />,
  };
};

const SkeletonComponent = () => ({
  title: <Skeleton width={150} height={32} />,
  errorDescription: <TicketErrorDescription.Skeleton />,
});

const StructureComponent = (props: StructureComponentProps) => {
  const { title, errorDescription } = props;
  return (
    <Grid container spacing={1} direction="column" alignItems="flex-start">
      <Grid item>{title}</Grid>
      <Grid item>{errorDescription}</Grid>
    </Grid>
  );
};

export const EquipmentHistoryCardDescription = skeletonify(
  'EquipmentHistoryCardDescription',
  ActualComponent,
  SkeletonComponent,
  StructureComponent
);

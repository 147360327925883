import { gql } from '@apollo/client';
import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { ManufacturerFragment } from './__apollo__/ManufacturerFragment';

export const TICKET_CARD_MANUFACTURER_FRAGMENT = gql`
  fragment ManufacturerFragment on IEquipment {
    id
    manufacturer
    modelNumber
  }
`;

export const EquipmentManufacturer: FC<ManufacturerFragment> = ({ manufacturer, modelNumber }) => {
  return (
    <Grid container direction="row">
      <Typography variant="body1" textAlign="left" color="textSecondary" style={{ marginRight: 20 }}>
        {manufacturer}
      </Typography>
      <Typography variant="body1" textAlign="left" color="textSecondary">
        {modelNumber}
      </Typography>
    </Grid>
  );
};

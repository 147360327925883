import { hasValue } from '@lego/mst-error-utilities';
import { Card, Grid, Step, StepContent, Stepper, StepProps, styled, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useEffect } from 'react';
import { useFragment } from 'react-relay';
import { ARDStepIcon } from '../../components/add-repair-documentation/ARDStepIcon';
import { useBannerContext } from '../../components/shared/banner/banner-context';
import { StyledStepLabel } from '../../components/shared/StyledStepLabel';
import { useTranslation } from '../../utility/i18n/translation';
import { CreateTicketStep, useCreateTicketContext } from './create-ticket-context';
import { CreateTicketDescriptionStep } from './CreateTicketDescriptionStep';
import { CreateTicketEquipmentStep } from './CreateTicketEquipmentStep';
import { CreateTicketLocationStep } from './CreateTicketLocationStep';
import { CreateTicketPriorityStep } from './CreateTicketPriorityStep';
import { CreateTicketCard_equipment$key } from './__generated__/CreateTicketCard_equipment.graphql';

const StyledStepContent = styled(StepContent)(({ theme }) => ({
  marginLeft: 15,
  marginRight: 35,
  paddingLeft: theme.spacing(4),
}));

interface Props {
  equipment: CreateTicketCard_equipment$key | null;
  loading?: boolean;
}

export const CreateTicketCard: FC<Props> = ({ equipment: equipmentRef, loading }) => {
  const { translate } = useTranslation();
  const { dispatch, state } = useCreateTicketContext();
  const { dispatch: bannerDispatch } = useBannerContext();

  const { currentStep } = state;

  const equipment = useFragment(
    graphql`
      fragment CreateTicketCard_equipment on QueryEquipmentResult {
        ... on QueryEquipmentSuccess {
          data {
            __typename
            ... on Mould {
              hasQARejection
            }
            ...CreateTicketEquipmentStep_equipment
            ...CreateTicketLocationStep_equipment
            ...CreateTicketPriorityStep_equipment
            ...CreateTicketDescriptionStep_equipment
          }
        }
      }
    `,
    equipmentRef
  );

  useEffect(() => {
    if (equipment?.data?.__typename) {
      dispatch({
        type: 'set_equipment_type',
        value: equipment?.data?.__typename === 'Mould' ? 'Mould' : 'Equipment',
      });
    }
  }, [dispatch, equipment?.data?.__typename]);

  useEffect(() => {
    if (equipment?.data?.__typename === 'Mould' && equipment.data.hasQARejection) {
      const text = translate(
        'CREATE_TICKET.QA_REJECTION_BANNER',
        'This mould has been rejected by QA, please provide more details.'
      );
      bannerDispatch({
        type: 'show',
        payload: { text, type: 'warning' },
      });
    }

    return () => {
      bannerDispatch({
        type: 'hide',
      });
    };
  }, [bannerDispatch, dispatch, equipment?.data?.__typename, equipment?.data?.hasQARejection, translate]);

  const equipmentLabel = translate('CREATE_TICKET.STEP_LABELS.EQUIPMENT', 'Equipment');
  const locationLabel = translate('CREATE_TICKET.STEP_LABELS.LOCATION', 'Location');
  const priorityLabel = translate('CREATE_TICKET.STEP_LABELS.PRIORITY', 'Priority');
  const descriptionLabel = translate('CREATE_TICKET.STEP_LABELS.DESCRIPTION', 'Description');

  const indexForStep = getIndexForStep(currentStep, equipment?.data?.__typename === 'Mould');

  const getPropsForStep = (step: CreateTicketStep): Pick<StepProps, 'completed' | 'expanded'> => {
    switch (step) {
      case 'equipment':
        return {
          completed: hasValue(state.equipmentId),
          expanded: hasValue(state.equipmentId),
        };
      case 'location': {
        const valid =
          hasValue(state.locationId) && (state.sublocation.isMandatory ? hasValue(state.sublocation.text) : true);
        return {
          completed: valid,
          expanded: valid,
        };
      }
      case 'priority':
        return {
          completed: hasValue(state.priority),
          expanded: hasValue(state.priority),
        };
      case 'description':
        return {
          completed: hasValue(state.description),
          expanded: hasValue(state.description),
        };
    }
  };

  const handleStepClicked = (currentStep: CreateTicketStep) => {
    dispatch({
      type: 'set_current_step',
      currentStep,
    });
  };

  return (
    <Grid container direction="column">
      <Card>
        <Grid item ml={1} mt={1} mb={1}>
          <Typography variant="subtitle2">{translate('CREATE_TICKET.CARD_HEADER', 'Create new ticket')}</Typography>
        </Grid>
        <Stepper
          activeStep={indexForStep}
          orientation="vertical"
          style={{ width: '100%' }}
          sx={{
            ml: 2,
            '& .MuiStepConnector-lineVertical': {
              marginLeft: '3px',
            },
          }}
        >
          <Step {...getPropsForStep('equipment')}>
            <StyledStepLabel StepIconComponent={ARDStepIcon} onClick={() => handleStepClicked('description')}>
              {equipmentLabel}
            </StyledStepLabel>
            <StyledStepContent data-cy="ARDStepContent-damage">
              <CreateTicketEquipmentStep equipment={equipment?.data ?? null} loading={loading} />
            </StyledStepContent>
          </Step>

          {equipment?.data?.__typename !== 'Mould' && (
            <Step {...getPropsForStep('location')}>
              <StyledStepLabel StepIconComponent={ARDStepIcon} onClick={() => handleStepClicked('location')}>
                {locationLabel}
              </StyledStepLabel>
              {equipment?.data && (
                <StyledStepContent>
                  <CreateTicketLocationStep equipment={equipment.data} />
                </StyledStepContent>
              )}
            </Step>
          )}

          <Step {...getPropsForStep('priority')}>
            <StyledStepLabel StepIconComponent={ARDStepIcon} onClick={() => handleStepClicked('priority')}>
              {priorityLabel}
            </StyledStepLabel>
            <StyledStepContent>
              <CreateTicketPriorityStep equipment={equipment?.data} />
            </StyledStepContent>
          </Step>

          <Step {...getPropsForStep('description')}>
            <StyledStepLabel StepIconComponent={ARDStepIcon} onClick={() => handleStepClicked('description')}>
              {descriptionLabel}
            </StyledStepLabel>
            <StyledStepContent>
              <CreateTicketDescriptionStep equipment={equipment?.data} />
            </StyledStepContent>
          </Step>
        </Stepper>
      </Card>
    </Grid>
  );
};

const getIndexForStep = (currentStep: CreateTicketStep, isMould: boolean): number => {
  if (isMould) {
    switch (currentStep) {
      default:
      case 'equipment':
        return 0;
      case 'priority':
        return 1;
      case 'description':
        return 2;
    }
  }

  switch (currentStep) {
    default:
    case 'equipment':
      return 0;
    case 'location':
      return 1;
    case 'priority':
      return 2;
    case 'description':
      return 3;
  }
};

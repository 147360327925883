import { Box, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { ReactNode } from 'react';
import { useFragment } from 'react-relay';
import { useTranslation } from '../../../utility/i18n/translation';
import { skeletonify } from '../../skeleton';
import { PriorityPill_ticket$key, TicketPriority } from './__generated__/PriorityPill_ticket.graphql';

const useColorForPriority = (priority: TicketPriority): string => {
  const { palette } = useTheme();
  switch (priority) {
    case 'Priority1':
      return palette.error.main;

    case 'Priority2':
      return palette.warning.dark;

    case 'Priority3':
      return palette.warning.main;

    case 'PriorityA':
    case 'Priority4':
    case 'Priority6':
    case 'Priority7':
    case 'Priority8':
    case 'Priority9':
      return palette.text.secondary;

    case 'Priority5':
    default:
      return palette.text.disabled;
  }
};

const Size = 48;

const ActualComponent = (props: { ticket: PriorityPill_ticket$key }) => {
  const { ticket: ticketRef } = props;
  const { priority, equipment, __typename } = useFragment(
    graphql`
      fragment PriorityPill_ticket on Ticket {
        __typename
        priority
        equipment {
          __typename
        }
      }
    `,
    ticketRef
  );

  const { translate } = useTranslation();
  const color = useColorForPriority(priority);

  const label =
    __typename !== 'MPEETicket' && equipment?.__typename === 'Mould' && priority === 'Priority3'
      ? 'MouldCall'
      : priority;

  return (
    <Box
      sx={{
        backgroundColor: color,
        height: Size,
        width: Size,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography color="white" variant="subtitle2" sx={{ fontSize: Size / 3, textAlign: 'center' }}>
        {translate(`PRIORITIES.${label}`, label)}
      </Typography>
    </Box>
  );
};

const SkeletonComponent = () => <Skeleton variant="circular" width={Size} height={Size} />;

const StructureComponent = ({ children }: { children?: ReactNode }) => {
  const { translate } = useTranslation();
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>{children}</Grid>
      <Grid item>
        <Typography variant="caption" color="textSecondary">
          {translate('PRIORITIES.PRIORITY', 'Priority')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const PriorityPill = skeletonify('PriorityPill', ActualComponent, SkeletonComponent, StructureComponent);

import { Box, Chip, Grid, styled, Typography } from '@mui/material';
import { CSSProperties, FC } from 'react';
import { useParams } from 'react-router-dom';
import { AppRoutes } from '../../Router';
import { CARD_DATE_FORMAT, useDateFromMiddlewareWithLocale } from '../../utility/date';
import { useTranslation } from '../../utility/i18n/translation';
import { Icons } from '../../utility/icons';
import { usePermissions } from '../../utility/permissions/usePermissions';
import { RepairDocumentationAccordionComponent } from '../close-ticket/CTRepairDocumentation';
import { RepairDocumentationAccordion_repairDocumentation_damageList } from '../close-ticket/__apollo__/RepairDocumentationAccordion';
import { CardWithTitle } from '../shared/CardWithTitle';
import { ImageRow } from '../shared/ImageRow';
import { TDWidgetAddButton } from './TDWidgetAddButton';
import { RepairDocumentationDamage } from './__apollo__/RepairDocumentationDamage';
import {
  RepairDocumentationSubEquipment,
  RepairDocumentationSubEquipment_components,
} from './__apollo__/RepairDocumentationSubEquipment';
import {
  TicketDetailsRepairDescriptions,
  TicketDetailsRepairDescriptions_repairDocumentation_damageList,
  TicketDetailsRepairDescriptions_repairDocumentation_damageList_RepairDocumentationDamageList_damages_activities,
  TicketDetailsRepairDescriptions_repairDocumentation_damageList_RepairDocumentationDamageList_damages_causes,
  TicketDetailsRepairDescriptions_repairDocumentation_damageList_RepairDocumentationDamageList_damages_comments,
} from './__apollo__/TicketDetailsRepairDescriptions';

export const TDRepairDescriptionApollo: FC<TicketDetailsRepairDescriptions> = ({
  repairDocumentation: { damageList },
  ...data
}) => {
  const { translate } = useTranslation();
  const title = translate('TICKET_DETAILS.CARDS.REPAIR_DOCUMENTATION.TITLE', 'Repair documentation');

  const {
    ticket: { canAddRepairDescription },
  } = usePermissions();

  const canAddRepair = canAddRepairDescription(data);

  const rightItem = canAddRepair ? <AddButton /> : undefined;

  const length = repairDamageListLength(damageList);

  if (length === 0) {
    return (
      <CardWithTitle title={title} rightItem={rightItem}>
        <Typography>
          {translate('TICKET_DETAILS.CARDS.REPAIR_DOCUMENTATION.NO_REPAIR_DOCS', 'No repair documentation yet')}
        </Typography>
      </CardWithTitle>
    );
  }

  return (
    <CardWithTitle title={title} rightItem={rightItem}>
      <RepairDocumentationAccordionComponent damageList={damageList} padding={0} />
    </CardWithTitle>
  );
};

const AddButton: FC = () => {
  const { translate } = useTranslation();
  const { id } = useParams() as { id: string };

  return (
    <TDWidgetAddButton
      icon={<Icons.Plus />}
      routeTo={AppRoutes.tickets.repair(id)}
      title={translate('TICKET_DETAILS.CARDS.REPAIR_DOCUMENTATION.BUTTON', 'Documentation')}
    />
  );
};

export const TDRepairDescriptionContent: FC<{
  damageList: TicketDetailsRepairDescriptions_repairDocumentation_damageList;
}> = ({ damageList }) => {
  const { translate } = useTranslation();

  const length = repairDamageListLength(damageList);

  return (
    <Grid container direction="column" data-cy="TDRepairDescriptionContent">
      {length === 0 && (
        <Grid item>
          <Typography>
            {translate(
              'TICKET_DETAILS.CARDS.REPAIR_DOCUMENTATION.NO_REPAIR_DOCUMENTATION_YET',
              'No repair documentation on this ticket yet'
            )}
          </Typography>
        </Grid>
      )}
      {damageList.__typename === 'RepairDocumentationDamageList'
        ? damageList.damages.map((damage, index) => (
            <Grid item key={index}>
              <TDRepairRow {...damage} />
            </Grid>
          ))
        : damageList.subEquipments.map((damage, index) => (
            <Grid item key={index}>
              <TDPackingRepairRow {...damage} />
            </Grid>
          ))}
    </Grid>
  );
};

const iconSize = 48;

const TDPackingRepairRow: FC<RepairDocumentationSubEquipment> = ({ description, components }) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <HeaderAndIcon text={description} icon="subEquipment" />
      </Grid>
      <Grid item>
        <TDPackingRepairRowContent components={components} />
      </Grid>
    </Grid>
  );
};

export const TDPackingRepairRowContent: FC<{
  components: RepairDocumentationSubEquipment_components[];
}> = ({ components }) => {
  const { translate } = useTranslation();

  return (
    <Grid item container direction="row">
      <Grid item>
        <Box borderLeft={1} style={{ marginLeft: iconSize / 2 }}>
          <Grid container item direction="column">
            {components.map((component) => {
              return (
                <Grid key={component.code} container direction="row" sx={{ mt: 2 }}>
                  {/* Horizontal divider */}
                  <Grid item>
                    <Box
                      width={35}
                      borderTop={1}
                      sx={{
                        mt: 1.5,
                      }}
                    />
                  </Grid>

                  {/* Main content */}
                  <Grid item sx={{ ml: 2, flex: 1 }}>
                    <Grid item>
                      <Typography>
                        {translate('TICKET_DETAILS.CARDS.REPAIR_DOCUMENTATION.COMPONENT', 'Component')}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ my: 1 }}>
                      <Chip variant="outlined" label={component.description} />
                    </Grid>
                    <Grid sx={{ mt: 1 }}>
                      {component.damages.map((damage) => {
                        return <TDRepairRow key={damage.damageId} {...damage} />;
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

const marginBetweenIconAndContent = 28;
const marginBetweenHeaderAndContent = 12;

const TDRepairRow: FC<RepairDocumentationDamage> = ({ activities, causes, comments, damageCodeText, images }) => {
  return (
    <>
      <HeaderAndIcon text={damageCodeText} icon="repair" />
      <Box borderLeft={1} style={{ marginLeft: iconSize / 2 }}>
        <Grid container item direction="column" style={{ marginLeft: marginBetweenIconAndContent + iconSize / 2 }}>
          {/* Cause and activity chips */}
          <Grid item container direction="row">
            <Grid item xs={12}>
              <Causes causes={causes} />
            </Grid>
            <Grid item xs={12}>
              <Activities activities={activities} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Comments comments={comments} />
          </Grid>
          <Grid item xs={12}>
            <ImageRow images={images} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const Header = styled(Typography)({ marginBottom: 12, fontWeight: 700 });

const HeaderAndIcon: FC<{
  text: string;
  icon: 'subEquipment' | 'repair';
}> = ({ text, icon }) => {
  const style: CSSProperties = {
    fontSize: iconSize,
    marginRight: marginBetweenIconAndContent,
  };
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item>{icon === 'repair' ? <Icons.Hardhat style={style} /> : <Icons.SubEquipment style={style} />}</Grid>
      <Grid item>
        <Typography variant="h3">{text}</Typography>
      </Grid>
    </Grid>
  );
};

const Causes: FC<{
  causes: TicketDetailsRepairDescriptions_repairDocumentation_damageList_RepairDocumentationDamageList_damages_causes[];
}> = ({ causes }) => {
  const { translate } = useTranslation();
  return (
    <Grid
      item
      style={{
        marginRight: 32,
        marginBottom: marginBetweenHeaderAndContent,
      }}
    >
      <Header>{translate('TICKET_DETAILS.CARDS.REPAIR_DOCUMENTATION.CAUSE', 'Cause')}</Header>
      <Grid container spacing={2}>
        {causes.map((cause) => {
          return (
            <Grid item key={cause.description}>
              <Chip variant="outlined" label={cause.description} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

const Activities: FC<{
  activities: TicketDetailsRepairDescriptions_repairDocumentation_damageList_RepairDocumentationDamageList_damages_activities[];
}> = ({ activities }) => {
  const { translate } = useTranslation();
  if (activities.length === 0) {
    return null;
  }

  return (
    <Grid
      item
      style={{
        marginBottom: marginBetweenHeaderAndContent,
        marginRight: 32,
      }}
    >
      <Header>{translate('TICKET_DETAILS.CARDS.REPAIR_DOCUMENTATION.ACTIVITY', 'Activity')}</Header>
      <Grid container spacing={2}>
        {activities.map((activity) => {
          return (
            <Grid item key={activity.description}>
              <Chip variant="outlined" label={activity.description} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

const Comments: FC<{
  comments: TicketDetailsRepairDescriptions_repairDocumentation_damageList_RepairDocumentationDamageList_damages_comments[];
}> = ({ comments }) => {
  const { translate } = useTranslation();
  const { format } = useDateFromMiddlewareWithLocale();
  if (comments.length === 0) {
    return null;
  }

  return (
    <Grid
      item
      style={{
        paddingRight: 140,
        marginBottom: 16,
      }}
    >
      <Header>{translate('TICKET_DETAILS.CARDS.REPAIR_DOCUMENTATION.DESCRIPTION', 'Description')}</Header>

      {comments.map((comment) => {
        const dateWithPrefix = `${translate('MISC.CREATED_PREFIX', 'Created')} ${format(
          comment.createdDate,
          CARD_DATE_FORMAT
        )}`;

        return (
          <Grid item key={`${comment.createdDate}:${comment.description}`}>
            <Typography
              style={{
                marginBottom: 16,
                wordWrap: 'break-word',
              }}
            >
              {comment.description}
            </Typography>
            <Typography variant="caption">{dateWithPrefix}</Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

export const repairDamageListLength = (
  damageList: RepairDocumentationAccordion_repairDocumentation_damageList
): number => {
  return damageList.__typename === 'RepairDocumentationDamageList'
    ? damageList.damages.length
    : damageList.subEquipments.length;
};

import { hasValue } from '@lego/mst-error-utilities';
import { styled } from '@mui/material';
import Step, { StepProps } from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import Stepper from '@mui/material/Stepper';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import {
  ARDStep,
  useAddRepairDescriptionContext,
} from '../../contexts/add-repair-description/add-repair-description-context';
import { usePrefetchRepairDescriptionData } from '../../contexts/add-repair-description/add-repair-description-gql-queries';
import { useTranslation } from '../../utility/i18n/translation';
import { RepairDocumentationFlowVariant } from '../../__apollo__/types';
import { StyledStepLabel } from '../shared/StyledStepLabel';
import { ARDStepIcon } from './ARDStepIcon';
import { ARDAddComment } from './steps/ARDAddComment';
import { ARDChooseActivities } from './steps/ARDChooseActivities';
import { ARDChooseCause } from './steps/ARDChooseCause';
import { ARDChooseComponent } from './steps/ARDChooseComponent';
import { ARDChooseDamage } from './steps/ARDChooseDamage';
import { ARDChooseSubEquipment } from './steps/ARDChooseSubEquipment';
import { ARDSummary } from './steps/ARDSummary';

const StyledStepContent = styled(StepContent)(({ theme }) => ({
  marginLeft: 15,
  paddingLeft: theme.spacing(4),
}));

export const ARDStepper: FC = () => {
  const { id } = useParams() as { id: string };
  usePrefetchRepairDescriptionData(id);

  const { translate } = useTranslation();
  const { dispatch, state } = useAddRepairDescriptionContext();

  const { currentStep, showActivities, variant } = state;

  const subEquipmentLabel = translate('ADD_REPAIR_DOCUMENTATION.STEP_LABELS.SUB_EQUIPMENT', 'Sub equipment');
  const componentLabel = translate('ADD_REPAIR_DOCUMENTATION.STEP_LABELS.COMPONENT', 'Component');
  const damageLabel = translate('ADD_REPAIR_DOCUMENTATION.STEP_LABELS.DAMAGE', 'Damage');
  const causeLabel = translate('ADD_REPAIR_DOCUMENTATION.STEP_LABELS.CAUSE', 'Cause');
  const activitiesLabel = translate('ADD_REPAIR_DOCUMENTATION.STEP_LABELS.ACTIVITIES', 'Activities');
  const commentLabel = translate('ADD_REPAIR_DOCUMENTATION.STEP_LABELS.COMMENT', 'Comment');
  const summaryLabel = translate('ADD_REPAIR_DOCUMENTATION.STEP_LABELS.SUMMARY', 'Summary');

  const indexForStep = getIndexForStep(currentStep, showActivities, variant);

  const getPropsForStep = (step: ARDStep): Pick<StepProps, 'completed' | 'expanded'> => {
    switch (step) {
      case 'subEquipment':
        return {
          completed: hasValue(state.chosenSubEquipment),
          expanded: hasValue(state.chosenSubEquipment),
        };
      case 'component':
        return {
          completed: hasValue(state.chosenComponent),
          expanded: hasValue(state.chosenComponent),
        };
      case 'damage':
        return {
          completed: hasValue(state.chosenDamage),
          expanded: hasValue(state.chosenDamage),
        };
      case 'cause':
        return {
          completed: hasValue(state.chosenCause) && currentStep !== 'damage',
          expanded: hasValue(state.chosenCause),
        };
      case 'activities':
        return {
          completed: currentStep === 'activities' || currentStep === 'comment' || currentStep === 'summary',
          expanded: state.chosenActivities.length > 0,
        };
      case 'comment':
        return {
          completed: currentStep === 'comment' || currentStep === 'summary',
          expanded: hasValue(state.repairComment),
        };
      case 'summary':
        return {
          completed: currentStep === 'summary',
          expanded: currentStep === 'summary',
        };
    }
  };

  const handleStepClicked = (currentStep: ARDStep) => {
    dispatch({
      type: 'set_current_step',
      currentStep,
    });
  };

  return (
    <Stepper
      activeStep={indexForStep}
      orientation="vertical"
      style={{ width: '100%' }}
      sx={{
        ml: 2,
        '& .MuiStepConnector-lineVertical': {
          marginLeft: '3px',
        },
      }}
    >
      {variant === RepairDocumentationFlowVariant.Packing && (
        <Step {...getPropsForStep('subEquipment')}>
          <StyledStepLabel
            StepIconComponent={ARDStepIcon}
            onClick={() => handleStepClicked('subEquipment')}
            data-cy="ARDStepLabel-subEquipment"
          >
            {subEquipmentLabel}
          </StyledStepLabel>
          <StyledStepContent data-cy="ARDStepContent-subEquipment">
            <ARDChooseSubEquipment />
          </StyledStepContent>
        </Step>
      )}
      {variant === RepairDocumentationFlowVariant.Packing && (
        <Step {...getPropsForStep('component')}>
          <StyledStepLabel
            StepIconComponent={ARDStepIcon}
            onClick={() => handleStepClicked('component')}
            data-cy="ARDStepLabel-component"
          >
            {componentLabel}
          </StyledStepLabel>
          <StyledStepContent data-cy="ARDStepContent-component">
            <ARDChooseComponent />
          </StyledStepContent>
        </Step>
      )}
      <Step {...getPropsForStep('damage')}>
        <StyledStepLabel
          StepIconComponent={ARDStepIcon}
          onClick={() => handleStepClicked('damage')}
          data-cy="ARDStepLabel-damage"
        >
          {damageLabel}
        </StyledStepLabel>
        <StyledStepContent data-cy="ARDStepContent-damage">
          <ARDChooseDamage />
        </StyledStepContent>
      </Step>

      <Step {...getPropsForStep('cause')}>
        <StyledStepLabel
          StepIconComponent={ARDStepIcon}
          onClick={() => handleStepClicked('cause')}
          data-cy="ARDStepLabel-cause"
        >
          {causeLabel}
        </StyledStepLabel>
        <StyledStepContent>
          <ARDChooseCause />
        </StyledStepContent>
      </Step>

      {showActivities && (
        <Step {...getPropsForStep('activities')}>
          <StyledStepLabel
            StepIconComponent={ARDStepIcon}
            onClick={() => handleStepClicked('activities')}
            data-cy="ARDStepLabel-activities"
          >
            {activitiesLabel}
          </StyledStepLabel>
          <StyledStepContent>
            <ARDChooseActivities />
          </StyledStepContent>
        </Step>
      )}

      <Step {...getPropsForStep('comment')}>
        <StyledStepLabel
          StepIconComponent={ARDStepIcon}
          onClick={() => handleStepClicked('comment')}
          data-cy="ARDStepLabel-comment"
        >
          {commentLabel}
        </StyledStepLabel>
        <StyledStepContent>
          <ARDAddComment />
        </StyledStepContent>
      </Step>

      <Step {...getPropsForStep('summary')}>
        <StyledStepLabel
          StepIconComponent={ARDStepIcon}
          onClick={() => handleStepClicked('summary')}
          data-cy="ARDStepLabel-summary"
        >
          {summaryLabel}
        </StyledStepLabel>
        <StyledStepContent>
          <ARDSummary />
        </StyledStepContent>
      </Step>
    </Stepper>
  );
};

const getIndexForStep = (
  currentStep: ARDStep,
  showActivities: boolean,
  variant: RepairDocumentationFlowVariant
): number => {
  if (variant === RepairDocumentationFlowVariant.Baseline) {
    switch (currentStep) {
      default:
      case 'damage':
        return 0;
      case 'cause':
        return 1;
      case 'activities':
        return 2;
      case 'comment':
        return showActivities ? 3 : 2;
      case 'summary':
        return showActivities ? 4 : 3;
    }
  } else {
    switch (currentStep) {
      default:
      case 'subEquipment':
        return 0;
      case 'component':
        return 1;
      case 'damage':
        return 2;
      case 'cause':
        return 3;
      case 'activities':
        return 4;
      case 'comment':
        return showActivities ? 5 : 4;
      case 'summary':
        return showActivities ? 6 : 5;
    }
  }
};

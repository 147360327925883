/**
 * @generated SignedSource<<8ac2f4f3e34b4f3fa989c4e1dc410fa0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuerySparePartInput = {
  sparePartNumber: string;
};
export type SparePartSearchTextFieldQuery$variables = {
  input: QuerySparePartInput;
  skip: boolean;
};
export type SparePartSearchTextFieldQuery$data = {
  readonly sparePart?: {
    readonly data?: {
      readonly sparePartNumber: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"SparePartSearchLabel_querySparePartResult">;
  };
};
export type SparePartSearchTextFieldQuery = {
  response: SparePartSearchTextFieldQuery$data;
  variables: SparePartSearchTextFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sparePartNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SparePartSearchTextFieldQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "sparePart",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SparePartSearchLabel_querySparePartResult"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SparePart",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "QuerySparePartSuccess",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SparePartSearchTextFieldQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "sparePart",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isQuerySparePartResult"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SparePart",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "QuerySparePartSuccess",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "85f5187bfd0e54f5187ebd6312f12b33",
    "id": null,
    "metadata": {},
    "name": "SparePartSearchTextFieldQuery",
    "operationKind": "query",
    "text": "query SparePartSearchTextFieldQuery(\n  $input: QuerySparePartInput!\n  $skip: Boolean!\n) {\n  sparePart(input: $input) @skip(if: $skip) {\n    __typename\n    ...SparePartSearchLabel_querySparePartResult\n    ... on QuerySparePartSuccess {\n      data {\n        sparePartNumber\n        id\n      }\n    }\n  }\n}\n\nfragment SparePartSearchLabel_querySparePartResult on QuerySparePartResult {\n  __isQuerySparePartResult: __typename\n  __typename\n  ... on QuerySparePartSuccess {\n    data {\n      description\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e088c92eb0643d0c4552629f14387720";

export default node;

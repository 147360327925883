import { hasValue } from '@lego/mst-error-utilities';
import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { useTranslation } from '../../utility/i18n/translation';
import { EquipmentHistoryCountsWidget_ticketsConnection$key } from './__generated__/EquipmentHistoryCountsWidget_ticketsConnection.graphql';
import {
  CORRECTIVE_PRIORITIES,
  OBSERVATIONS_PRIORITIES,
  PREVENTIVE_PRIORITIES,
} from '../../utility/constants/ticketConstants';

export const EquipmentHistoryCountsWidget: FC<{
  ticketsConnections: EquipmentHistoryCountsWidget_ticketsConnection$key | null | undefined;
}> = ({ ticketsConnections }) => {
  const calculatedNumbers = useCalculatedNumbers(ticketsConnections);

  if (!hasValue(calculatedNumbers)) {
    return null;
  }

  return (
    <Card sx={{ mb: 30 }}>
      <CardContent>
        <Grid container flexDirection="column" spacing={3}>
          <Grid item xs={12}>
            <CountsRow {...calculatedNumbers.corrective} />
          </Grid>
          <Grid item xs={12}>
            <CountsRow {...calculatedNumbers.observations} />
          </Grid>
          <Grid item xs={12}>
            <CountsRow {...calculatedNumbers.preventive} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

type CountEntity = { count: number; label: string; percentage: number };

const CountsRow: FC<CountEntity> = ({ count, label, percentage }) => {
  const {
    palette: {
      text: { secondary },
    },
  } = useTheme();

  return (
    <Grid container direction="row">
      <Grid item sx={{ flex: 7 }}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <Typography fontWeight={700} fontSize="3rem">
              {count}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption" color={secondary}>
              {label}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ flex: 3 }}>
        <Typography variant="caption" color={secondary}>
          {`${percentage}%`}
        </Typography>
      </Grid>
    </Grid>
  );
};

type CalculatedNumbers = {
  corrective: CountEntity;
  observations: CountEntity;
  preventive: CountEntity;
};

const useCalculatedNumbers = (
  ticketsConnections: EquipmentHistoryCountsWidget_ticketsConnection$key | null | undefined
): CalculatedNumbers | null => {
  const { translate } = useTranslation();

  const data = useFragment(
    graphql`
      fragment EquipmentHistoryCountsWidget_ticketsConnection on TicketsConnection {
        facets {
          priority {
            count
            value
          }
        }
      }
    `,
    ticketsConnections ?? null
  );

  const total = data?.facets.priority?.reduce((acc, curr) => (acc += curr.count), 0) ?? 0;
  const correctiveCounts =
    data?.facets.priority
      ?.filter((val) => CORRECTIVE_PRIORITIES.includes(val.value))
      .reduce((acc, curr) => (acc += curr.count), 0) ?? 0;
  const observationCounts =
    data?.facets.priority
      ?.filter((val) => OBSERVATIONS_PRIORITIES.includes(val.value))
      .reduce((acc, curr) => (acc += curr.count), 0) ?? 0;
  const preventiveCounts =
    data?.facets.priority
      ?.filter((val) => PREVENTIVE_PRIORITIES.includes(val.value))
      .reduce((acc, curr) => (acc += curr.count), 0) ?? 0;

  const correctivePercentage = Math.round((correctiveCounts / total) * 100);
  const observationsPercentage = Math.round((observationCounts / total) * 100);
  // Make sure sum of percentages is 100
  const preventivePercentage = 100 - correctivePercentage - observationsPercentage;

  const correctiveLabel = translate('EQUIPMENT_HISTORY.COUNTS_WIDGET.CORRECTIVE', 'Corrective');
  const observationsLabel = translate('EQUIPMENT_HISTORY.COUNTS_WIDGET.OBSERVATIONS', 'Observations');
  const preventiveLabel = translate('EQUIPMENT_HISTORY.COUNTS_WIDGET.PREVENTIVE', 'Preventive');

  return {
    corrective: {
      count: correctiveCounts,
      label: correctiveLabel,
      percentage: correctivePercentage,
    },
    observations: {
      count: observationCounts,
      label: observationsLabel,
      percentage: observationsPercentage,
    },
    preventive: {
      count: preventiveCounts,
      label: preventiveLabel,
      percentage: preventivePercentage,
    },
  } as CalculatedNumbers;
};

import { Button, Card, CardContent, Chip, Collapse, Grid, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { createContext, Dispatch, FC, ReactNode, SetStateAction, useCallback, useContext, useMemo } from 'react';
import { useFragment } from 'react-relay';
import { TransitionGroup } from 'react-transition-group';
import { useDateFromMiddlewareWithLocale } from '../../utility/date';
import { useTranslation } from '../../utility/i18n/translation';
import { useLocalStorage } from '../hooks/local-storage';
import { TicketSearchCompletedDateFilter } from './TicketSearchCompletedDateFilter';
import { TicketSearchEquipmentGroupFilter } from './TicketSearchEquipmentGroupFilter';
import { TicketSearchLocationFilter } from './TicketSearchLocationFilter';
import { TicketSearchMouldStatusFilter, useMouldStatusCodeLabel } from './TicketSearchMouldStatusFilter';
import { TicketSearchPriorityFilter } from './TicketSearchPriorityFilter';
import { TicketSearchStatusFilter } from './TicketSearchStatusFilter';
import { TicketSearchSublocationFilter } from './TicketSearchSublocationFilter';
import { TicketSearchFilters_plant$key } from './__generated__/TicketSearchFilters_plant.graphql';
import { TicketSearchFilters_process$key } from './__generated__/TicketSearchFilters_process.graphql';
import { TicketSearchFilters_query$key } from './__generated__/TicketSearchFilters_query.graphql';
import { TicketSearchFilters_ticketsConnection$key } from './__generated__/TicketSearchFilters_ticketsConnection.graphql';
import {
  LocalTimestampInput,
  MouldStatusInput,
  ProcessTicketsInput,
  TicketsPriorityInput,
  TicketsStatusInput,
} from './__generated__/TicketSearchQuery.graphql';

interface TicketSearchFilters {
  priority?: Array<TicketsPriorityInput>;
  status?: Array<TicketsStatusInput>;
  mouldStatus?: Array<MouldStatusInput>;
  completedBeforeDate?: LocalTimestampInput;
  completedAfterDate?: LocalTimestampInput;
  location?: { id: string; locationNumber: number; description: string };
  sublocation?: { id: string; code: string; description: string };
  equipmentGroup?: { id: string; code: string; description: string };
}
interface TicketSearchFilterContext {
  filters: TicketSearchFilters;
  readonly input: ProcessTicketsInput;
  onChange: Dispatch<SetStateAction<TicketSearchFilters>>;
}

const Context = createContext<TicketSearchFilterContext | undefined>(undefined);
export const useTicketSearchFilterContext = (): TicketSearchFilterContext => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('Missing TicketSearchFilterProvider');
  }
  return context;
};
export const TicketSearchFilterProvider: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;
  const defaultStatusFilter: TicketsStatusInput[] = ['NotStarted', 'InProgress', 'Released'];

  const [filters, setFilters] = useLocalStorage<TicketSearchFilters>('MaintainIt_TicketsSearch', {
    status: defaultStatusFilter,
  });

  const value = useMemo<TicketSearchFilterContext>(() => {
    const input: ProcessTicketsInput = {
      priority: filters.priority,
      status: filters.status,
      completedDate: {
        le: filters.completedBeforeDate,
        ge: filters.completedAfterDate,
      },
      mouldStatus: filters.mouldStatus,
      location: filters.location?.id,
      sublocationId: filters.sublocation?.id,
      equipmentGroup: filters.equipmentGroup?.id,
    };

    return {
      filters,
      onChange(value) {
        setFilters(value);
      },
      input,
    };
  }, [filters, setFilters]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export type TicketFilterChipProps = {
  [K in keyof TicketSearchFilters]-?: {
    type: K;
    value: NonNullable<TicketSearchFilters[K]> extends Array<infer R> ? R : NonNullable<TicketSearchFilters[K]>;
  };
}[keyof TicketSearchFilters];

export const TicketFilterChip: FC<
  TicketFilterChipProps & {
    onDelete(): void;
  }
> = (props) => {
  const { type, value, onDelete } = props;
  const { translate } = useTranslation();
  const { getMouldStatusLabelFromEnum } = useMouldStatusCodeLabel();
  const { format } = useDateFromMiddlewareWithLocale();

  const label = useMemo((): string => {
    switch (type) {
      case 'status': {
        switch (value) {
          case 'InProgress':
            return translate('TICKET_LIST.FILTERS.TICKET_STATUS.ASSIGNED', undefined);

          case 'NotStarted':
            return translate('TICKET_LIST.FILTERS.TICKET_STATUS.UNASSIGNED', undefined);

          case 'Released':
            return translate('TICKET_LIST.FILTERS.TICKET_STATUS.RELEASED', undefined);

          case 'Closed':
            return translate('TICKET_LIST.FILTERS.TICKET_STATUS.CLOSED', undefined);

          default:
            return '?';
        }
      }
      case 'priority':
        return `${translate('TICKET_LIST.FILTERS.PRIORITY.TITLE', undefined)} ${value.substring('Priority'.length)}`;
      case 'location':
        return `${value.locationNumber}, ${value.description}`;
      case 'sublocation':
      case 'equipmentGroup':
        return `${value.code}, ${value.description}`;
      case 'mouldStatus':
        return getMouldStatusLabelFromEnum(value);
      case 'completedBeforeDate':
        return translate('TICKET_LIST.FILTERS.COMPLETED_DATE.END_DATE_CHIP', 'Closed before: {{date}}', {
          date: format(new Date(value.date).toISOString(), 'P'),
        });
      case 'completedAfterDate':
        return translate('TICKET_LIST.FILTERS.COMPLETED_DATE.START_DATE_CHIP', 'Closed after: {{date}}', {
          date: format(new Date(value.date).toISOString(), 'P'),
        });
    }
  }, [format, getMouldStatusLabelFromEnum, translate, type, value]);

  return <Chip label={label} onDelete={onDelete} color="primary" />;
};

export interface TicketSearchFiltersProps {
  ticketsConnection: TicketSearchFilters_ticketsConnection$key | null;
  plant: TicketSearchFilters_plant$key | null;
  process: TicketSearchFilters_process$key | null;
  query: TicketSearchFilters_query$key | null;
}

export const TicketSearchFilters: FC<TicketSearchFiltersProps> = (props) => {
  const { ticketsConnection: connectionRef, plant: plantRef, process: processRef, query: queryRef } = props;
  const { onChange: onFiltersChange, filters } = useTicketSearchFilterContext();

  const connection = useFragment(
    graphql`
      fragment TicketSearchFilters_ticketsConnection on TicketsConnection {
        facets {
          ...TicketSearchStatusFilter_ticketsConnectionFacets
          ...TicketSearchPriorityFilter_ticketsConnectionFacets
          ...TicketSearchMouldStatusFilter_ticketsConnectionFacets
        }
      }
    `,
    connectionRef
  );

  const plant = useFragment(
    graphql`
      fragment TicketSearchFilters_plant on Plant {
        ...TicketSearchLocationFilter_plant
      }
    `,
    plantRef
  );

  const process = useFragment(
    graphql`
      fragment TicketSearchFilters_process on Process {
        ...TicketSearchSublocationFilter_process
        type
      }
    `,
    processRef
  );

  const query = useFragment(
    graphql`
      fragment TicketSearchFilters_query on Query {
        ...TicketSearchEquipmentGroupFilter_query
      }
    `,
    queryRef
  );

  const { translate } = useTranslation();

  const handleClearFilters = useCallback(() => {
    onFiltersChange({ status: [] });
  }, [onFiltersChange]);

  const filtersList = useMemo<TicketFilterChipProps[]>(() => {
    const statuses =
      filters.status?.map((status) => ({
        type: 'status' as const,
        value: status,
      })) ?? [];
    const priorities =
      filters.priority?.map((priority) => ({
        type: 'priority' as const,
        value: priority,
      })) ?? [];
    const mouldStatus =
      filters.mouldStatus?.map((mouldStatusCode) => ({
        type: 'mouldStatus' as const,
        value: mouldStatusCode,
      })) ?? [];
    const completedToDate = filters.completedAfterDate
      ? [
          {
            type: 'completedAfterDate' as const,
            value: filters.completedAfterDate,
          },
        ]
      : [];

    const completedFromDate = filters.completedBeforeDate
      ? [
          {
            type: 'completedBeforeDate' as const,
            value: filters.completedBeforeDate,
          },
        ]
      : [];

    return [
      ...statuses,
      ...priorities,
      ...mouldStatus,
      ...completedToDate,
      ...completedFromDate,
      ...(filters.location ? [{ type: 'location' as const, value: filters.location }] : []),
      ...(filters.sublocation ? [{ type: 'sublocation' as const, value: filters.sublocation }] : []),
      ...(filters.equipmentGroup ? [{ type: 'equipmentGroup' as const, value: filters.equipmentGroup }] : []),
    ];
  }, [
    filters.completedAfterDate,
    filters.completedBeforeDate,
    filters.equipmentGroup,
    filters.location,
    filters.mouldStatus,
    filters.priority,
    filters.status,
    filters.sublocation,
  ]);

  const handleFilterDelete = (filter: TicketFilterChipProps) => () => {
    if (filter.type === 'status' && filter.value === 'Closed') {
      filters.completedAfterDate = undefined;
      filters.completedBeforeDate = undefined;
    }

    onFiltersChange({
      ...filters,
      [filter.type]:
        filter.type === 'status'
          ? filters[filter.type]?.filter((value) => value !== filter.value)
          : filter.type === 'priority'
          ? filters[filter.type]?.filter((value) => value !== filter.value)
          : filter.type === 'mouldStatus'
          ? filters[filter.type]?.filter((value) => value !== filter.value)
          : undefined,
    });
  };

  const showMouldStatusFilter = process?.type === 'MouldMaintenance';

  return (
    <Card sx={{ p: 0 }}>
      <CardContent>
        <Grid container spacing={1} flexDirection="column">
          <Grid item container flexDirection="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="subtitle1">{translate('TICKET_LIST.FILTERS.CHIP_TITLE', 'FILTERS')}</Typography>
            </Grid>
            <Grid item>
              <Button onClick={handleClearFilters} variant="text" disabled={filtersList.length === 0}>
                {translate('TICKET_LIST.FILTERS.CLEAR_ALL', 'Clear all')}
              </Button>
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <TransitionGroup component={null}>
              {filtersList.map((filter, index) => (
                <Grid item component={Collapse} key={`${filter.type}:${filter.value}:${index}`}>
                  <TicketFilterChip {...filter} onDelete={handleFilterDelete(filter)} />
                </Grid>
              ))}
            </TransitionGroup>
          </Grid>
          <Grid item>
            <TicketSearchStatusFilter ticketsConnectionFacets={connection?.facets ?? null} />
          </Grid>
          <Grid item>
            <TicketSearchCompletedDateFilter />
          </Grid>
          <Grid item>
            <TicketSearchPriorityFilter ticketsConnectionFacets={connection?.facets ?? null} />
          </Grid>
          <Grid item>
            <TicketSearchLocationFilter plant={plant} />
          </Grid>
          <Grid item>
            <TicketSearchSublocationFilter process={process} />
          </Grid>
          <Grid item>
            <TicketSearchEquipmentGroupFilter query={query} />
          </Grid>
          {showMouldStatusFilter && (
            <Grid item>
              <TicketSearchMouldStatusFilter ticketsConnectionFacets={connection?.facets ?? null} />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

import { hasValue } from '@lego/mst-error-utilities';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useMemo } from 'react';
import { useFragment } from 'react-relay';
import { useTranslation } from '../../utility/i18n/translation';
import { useTicketSearchFilterContext } from './TicketSearchFilters';
import { TicketSearchLocationFilter_plant$key } from './__generated__/TicketSearchLocationFilter_plant.graphql';

export interface TicketSearchLocationFilterProps {
  plant: TicketSearchLocationFilter_plant$key | null;
}

export const TicketSearchLocationFilter: FC<TicketSearchLocationFilterProps> = (props) => {
  const { plant: plantRef } = props;
  const data = useFragment(
    graphql`
      fragment TicketSearchLocationFilter_plant on Plant {
        # TODO: Make required and wrap in error boundary
        locations {
          id
          locationNumber
          description
        }
      }
    `,
    plantRef
  );

  const {
    filters: { location: selectedLocation },
    onChange,
  } = useTicketSearchFilterContext();

  const { translate } = useTranslation();

  const options = useMemo(() => data?.locations?.filter(hasValue) ?? [], [data?.locations]);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="subtitle2">{translate('TICKET_LIST.FILTERS.LOCATION.TITLE', 'Hall / section')}</Typography>
      </Grid>
      <Grid item>
        <Autocomplete
          onChange={(_, value) => onChange((prev) => ({ ...prev, location: value ?? undefined }))}
          value={selectedLocation ?? null}
          getOptionLabel={({ locationNumber, description }) => `${locationNumber}, ${description}`}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disabled={options.length === 0}
          options={options}
          renderInput={(params) => <TextField {...params} />}
        />
      </Grid>
    </Grid>
  );
};

import { DatePicker, DesktopDatePickerProps } from '@mui/lab';
import { Grid, TextField, Typography, TypographyTypeMap } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from '../../utility/i18n/translation';

export const GMDatePicker: FC<
  Omit<DesktopDatePickerProps<Date>, 'renderInput'> & {
    title: string;
    showOptional?: boolean;
    titleVariant?: TypographyTypeMap['props']['variant'];
  }
> = ({ title, showOptional = true, titleVariant, ignoreInvalidInputs = true, ...rest }) => {
  return (
    <Grid container direction="column" spacing={1} flex={1}>
      <Grid item>
        <InputTitle title={title} showOptionalLabel={showOptional} titleVariant={titleVariant} />
      </Grid>
      <Grid item>
        <DatePicker
          {...rest}
          renderInput={(params) => <TextField {...params} style={{ width: '100%' }} />}
          clearable={true}
          ignoreInvalidInputs={ignoreInvalidInputs}
        />
      </Grid>
    </Grid>
  );
};

export const InputTitle: FC<{
  title: string;
  showOptionalLabel?: boolean;
  titleVariant?: TypographyTypeMap['props']['variant'];
}> = ({ title, showOptionalLabel = true, titleVariant }) => {
  const { translate } = useTranslation();

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant={titleVariant}>{title}</Typography>
      </Grid>
      <Grid item>
        {showOptionalLabel && (
          <Typography variant="caption" color="text.secondary">
            {translate('IMAGE_SEARCH.SEARCH_FILTERS.DATE_OPTIONAL', '(optional)')}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

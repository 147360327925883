import { hasValue } from '@lego/mst-error-utilities';
import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import { FC, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes, RouteTypes } from '../../Router';
import { SubLocationTypeEnum, TicketPriorityEnum } from '../../__apollo__/types';
import { GetDataForCloseTicketFlow_ticket_Ticket } from '../../containers/__apollo__/GetDataForCloseTicketFlow';
import { CloseTicketState, useCloseTicketContext } from '../../contexts/close-ticket/close-ticket-context';
import { useTranslation } from '../../utility/i18n/translation';
import { useConfirmDialog } from '../shared/ConfirmDialog';
import { repairDamageListLength } from '../ticket-details/TDRepairDescription';
import { useCloseTicketMutation } from './CTMutation';

export const StyledCloseTicketStepper = styled(MobileStepper)(() => ({
  flex: 1,
  padding: '18px 0px 0px',
}));
const StyledButton = styled(Button)(() => ({
  padding: '16px 24px',
}));
type NavType = ReturnType<typeof useNavigateStepIfPossible>['navigate'];
type CommonInput = Omit<Parameters<NavType>['0'], 'direction'>;

export const CTStepper: FC<GetDataForCloseTicketFlow_ticket_Ticket> = ({
  repairDocumentation: { damageList },
  totalManHoursInMinutes,
  equipment,
  underGAT,
  priority,
}) => {
  const { translate } = useTranslation();

  const { id, step } = useParams() as RouteTypes['close'];
  const { navigate } = useNavigateStepIfPossible();
  const {
    dispatch,
    state: { springStepEnabled },
  } = useCloseTicketContext();
  const length = repairDamageListLength(damageList);

  useEffect(() => {
    // If sublocation is not free text, and has a value, dispatch that it is valid
    if (
      equipment?.__typename === 'EquipmentValue' &&
      equipment.value.sublocationMetaInfo.sublocationType === SubLocationTypeEnum.SubLocation &&
      equipment.value.subLocation?.__typename === 'SubLocation' &&
      equipment.value.subLocation.id
    ) {
      dispatch({ type: 'setSublocationInputValid', valid: true });
    }
  }, [dispatch, equipment]);

  const {
    state: { flowVariant },
  } = useCloseTicketContext();

  const common: CommonInput = {
    ticketId: id,
    currentStep: step,
    hasRepairDocs: length > 0,
    hasManhours: totalManHoursInMinutes > 0,
    underGAT,
  };

  const handleBack = () => {
    navigate({
      direction: 'back',
      ...common,
    });
  };

  return (
    <StyledCloseTicketStepper
      variant="dots"
      steps={getNumberOfSteps(flowVariant, springStepEnabled, priority)}
      position="static"
      activeStep={getNumberFromStep(common.currentStep, flowVariant, springStepEnabled, priority)}
      nextButton={<NextButton commonNavigationInput={common} equipment={equipment} priority={priority} />}
      backButton={
        <StyledButton size="small" variant="outlined" onClick={handleBack} disabled={step === 'repair'}>
          {translate('CLOSE_TICKET.STEPPER.BACK', 'Back')}
        </StyledButton>
      }
    />
  );
};

const NextButton: FC<{
  equipment: GetDataForCloseTicketFlow_ticket_Ticket['equipment'];
  commonNavigationInput: CommonInput;
  priority: TicketPriorityEnum;
}> = ({ equipment, commonNavigationInput, priority }) => {
  const { id, step } = useParams() as RouteTypes['close'];
  const { translate } = useTranslation();
  const { navigate } = useNavigateStepIfPossible();

  const {
    state: {
      flowVariant,
      closeTicketLoading,
      location: { sublocationInputValid },
      gatInfo,
      flowType,
      manHoursValid,
    },
    dispatch,
  } = useCloseTicketContext();

  const { closeTicket: closeTicketMutation } = useCloseTicketMutation(
    id,
    priority,
    equipment?.__typename === 'EquipmentValue' ? equipment.value.id : undefined,
    commonNavigationInput.underGAT
  );

  const closeTicket = () => {
    if (hasValue(gatInfo)) {
      if (!gatInfo.approved && (!hasValue(gatInfo.rejectionReason) || gatInfo.rejectionReason?.length < 1)) {
        dispatch({ type: 'setShowGATrejectionReasonWarning', show: true });
        return;
      }
    }

    closeTicketMutation();
  };

  const handleNext = () => {
    navigate({
      direction: 'forward',
      ...commonNavigationInput,
    });
  };

  const gatInputValid = commonNavigationInput.underGAT ? hasValue(gatInfo?.approved) : true;

  const closeButtonDisabled = () => {
    if (closeTicketLoading || !gatInputValid) {
      return true;
    }
    if (flowVariant === 'mould') {
      if (priority === TicketPriorityEnum.Priority5) {
        return !manHoursValid;
      }
      return !commonNavigationInput.underGAT && !flowType;
    }
    if (flowVariant === 'equipment') {
      return !sublocationInputValid;
    }
    return false;
  };

  const closeButtonLabel =
    commonNavigationInput.underGAT && !gatInfo?.approved
      ? translate('CLOSE_TICKET.STEPPER.GAT_REJECT_SAVE_TICKET_BUTTON', 'Save ticket')
      : translate('CLOSE_TICKET.STEPPER.CLOSE_TICKET_BUTTON', 'Close Ticket');

  const nextButton = (
    <StyledButton
      size="small"
      variant="contained"
      data-cy="CloseTicketNextButton"
      onClick={handleNext}
      disabled={step === 'location' && !sublocationInputValid}
    >
      {translate('CLOSE_TICKET.STEPPER.NEXT', 'Next')}
    </StyledButton>
  );

  const closeButton = (
    <Button
      data-cy="CloseTicketCloseButton"
      size="small"
      variant="contained"
      onClick={closeTicket}
      disabled={closeButtonDisabled()}
    >
      {closeTicketLoading
        ? translate('CLOSE_TICKET.STEPPER.CLOSE_TICKET_BUTTON_SAVING', 'Saving...')
        : closeButtonLabel}
    </Button>
  );

  const mouldEndOfFlow = () => {
    if (priority === TicketPriorityEnum.Priority5) {
      return step === 'manhours';
    }
    return step === 'flow' || step === 'gat';
  };

  if (flowVariant === 'mpee') {
    return step === 'manhours' ? closeButton : nextButton;
  } else if (flowVariant === 'mould') {
    return mouldEndOfFlow() ? closeButton : nextButton;
  } else {
    return step === 'location' ? closeButton : nextButton;
  }
};

type NavigateInput = {
  direction: 'back' | 'forward';
  currentStep: RouteTypes['close']['step'];
  hasRepairDocs: boolean;
  hasManhours: boolean;
  ticketId: string;
  underGAT: boolean;
};

const useNavigateStepIfPossible = () => {
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const openConfirmModal = useConfirmDialog();
  const {
    dispatch,
    state: { didCleanMould, cleaningState, flowVariant, cleaningRequired, springStepEnabled, didSpringChange },
  } = useCloseTicketContext();

  const sisterIdsToUpdate = cleaningState?.cleaningIntervalUpdate.sisterIdsToUpdate;

  const navigateFn = async ({
    ticketId,
    currentStep,
    direction,
    hasManhours,
    hasRepairDocs,
    underGAT,
  }: NavigateInput) => {
    switch (currentStep) {
      case 'repair':
        if (direction === 'forward') {
          if (!hasRepairDocs) {
            dispatch({ type: 'setShowErrorOnRepairStep', show: true });
          } else {
            navigate(AppRoutes.tickets.closeTicket(ticketId, 'manhours'), {
              replace: true,
            });
          }
        }
        break;
      case 'manhours':
        if (direction === 'forward') {
          if (!hasManhours) {
            dispatch({ type: 'setShowErrorOnManHourStep', show: true });
          } else {
            if (flowVariant === 'mould') {
              navigate(AppRoutes.tickets.closeTicket(ticketId, 'cleaning'), {
                replace: true,
              });
            } else {
              navigate(AppRoutes.tickets.closeTicket(ticketId, 'location'), {
                replace: true,
              });
            }
          }
        } else {
          navigate(AppRoutes.tickets.closeTicket(ticketId, 'repair'), {
            replace: true,
          });
        }
        break;
      case 'cleaning':
        if (direction === 'forward') {
          if (!hasValue(didCleanMould) || (cleaningRequired && !didCleanMould)) {
            dispatch({ type: 'setShowCleaningWarning', show: true });
          } else {
            if (didCleanMould && sisterIdsToUpdate?.length) {
              await openConfirmModal({
                title: translate(
                  'CLOSE_TICKET.SISTER_MOULD_INTERVAL_UPDATE_CONFIRM_TITLE',
                  'Assigning new interval to more than one mould'
                ),
                message: (
                  <Trans
                    t={translate}
                    i18nKey="CLOSE_TICKET.SISTER_MOULD_INTERVAL_UPDATE_CONFIRM_MESSAGE"
                    values={{ count: sisterIdsToUpdate?.length }}
                    components={{ 1: <strong /> }}
                    defaults="You are currently assigning a new cleaning interval to <1>{{count}}</1> sister moulds. Do you want to save your changes?"
                  />
                ),
              });
            }
            navigate(
              AppRoutes.tickets.closeTicket(ticketId, springStepEnabled ? 'spring' : underGAT ? 'gat' : 'flow'),
              {
                replace: true,
              }
            );
          }
        } else {
          navigate(AppRoutes.tickets.closeTicket(ticketId, 'manhours'), {
            replace: true,
          });
        }
        break;
      case 'spring':
        if (direction === 'forward') {
          if (didSpringChange === undefined) {
            dispatch({ type: 'setShowSpringWarning', show: true });
          } else {
            navigate(AppRoutes.tickets.closeTicket(ticketId, underGAT ? 'gat' : 'flow'), {
              replace: true,
            });
          }
        } else {
          navigate(AppRoutes.tickets.closeTicket(ticketId, 'cleaning'), {
            replace: true,
          });
        }
        break;
      case 'location':
        if (direction === 'forward') {
          if (underGAT) {
            navigate(AppRoutes.tickets.closeTicket(ticketId, 'gat'), {
              replace: true,
            });
            break;
          }
          navigate(AppRoutes.tickets.closeTicket(ticketId, 'flow'), {
            replace: true,
          });
        } else {
          if (flowVariant === 'mould') {
            navigate(AppRoutes.tickets.closeTicket(ticketId, springStepEnabled ? 'spring' : 'cleaning'), {
              replace: true,
            });
          } else {
            navigate(AppRoutes.tickets.closeTicket(ticketId, 'manhours'), {
              replace: true,
            });
          }
        }
        break;
      case 'flow':
      case 'gat':
        if (direction === 'back') {
          navigate(AppRoutes.tickets.closeTicket(ticketId, springStepEnabled ? 'spring' : 'cleaning'), {
            replace: true,
          });
        }
        break;
    }
  };

  return { navigate: navigateFn };
};

const getNumberOfSteps = (
  flowVariant: CloseTicketState['flowVariant'],
  springStepEnabled: boolean,
  priority: TicketPriorityEnum
): number => {
  if (flowVariant === 'mpee') {
    return 2;
  }
  if (flowVariant === 'mould') {
    if (priority === TicketPriorityEnum.Priority5) {
      return 2;
    }
    return springStepEnabled ? 5 : 4;
  }
  return 3;
};

const getNumberFromStep = (
  step: RouteTypes['close']['step'],
  flowVariant: CloseTicketState['flowVariant'],
  springStepEnabled: boolean,
  priority: TicketPriorityEnum
): number => {
  if (flowVariant === 'mpee') {
    switch (step) {
      case 'repair':
        return 0;
      case 'manhours':
        return 1;
    }
  } else if (flowVariant === 'mould') {
    if (priority === TicketPriorityEnum.Priority5) {
      switch (step) {
        case 'repair':
          return 0;
        case 'manhours':
          return 1;
      }
    }

    if (springStepEnabled) {
      switch (step) {
        case 'repair':
          return 0;
        case 'manhours':
          return 1;
        case 'cleaning':
          return 2;
        case 'spring':
          return 3;
        case 'flow':
        case 'gat':
          return 4;
      }
    } else {
      switch (step) {
        case 'repair':
          return 0;
        case 'manhours':
          return 1;
        case 'cleaning':
          return 2;
        case 'flow':
        case 'gat':
          return 3;
      }
    }
  } else {
    switch (step) {
      case 'repair':
        return 0;
      case 'manhours':
        return 1;
      case 'location':
        return 2;
    }
  }
  return 0;
};

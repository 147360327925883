import { useTranslation } from '../../../utility/i18n/translation';
import { MouldFlowType } from './__generated__/MouldCloseFlowStatusChip_mould.graphql';

export const useCloseFlowStatusTranslation = (): ((key: MouldFlowType) => string | null) => {
  const { translate } = useTranslation();

  return (key: MouldFlowType): string | null => {
    switch (key) {
      case 'Normal':
        return translate('CLOSE_TICKET.FLOW_TYPE.NORMAL', 'Normal');
      case 'Green':
        return translate('CLOSE_TICKET.FLOW_TYPE.GREEN', 'Green');
      case 'YellowToProduction':
        return translate('CLOSE_TICKET.FLOW_TYPE.YELLOW_TO_PRODUCTION', 'Waiting for production approval');
      case 'YellowToProductionRejected':
        return translate('CLOSE_TICKET.FLOW_TYPE.YELLOW_TO_PRODUCTION_REJECTED', 'Rejected by production');
      case 'YellowToQA':
        return translate('CLOSE_TICKET.FLOW_TYPE.YELLOW_TO_QA', 'Waiting for QA approval');
      case 'YellowToQARejected':
        return translate('CLOSE_TICKET.FLOW_TYPE.YELLOW_TO_QA_REJECTED', 'Rejected by QA');
      case 'YellowToQAApproved':
        return translate('CLOSE_TICKET.FLOW_TYPE.YELLOW_TO_QA_APPROVED', 'Approved by QA');
      case 'YellowToMPEE':
        return translate('CLOSE_TICKET.FLOW_TYPE.YELLOW_TO_MPEE', 'Waiting for MPE&E approval');
      case 'YellowToMPEERejected':
        return translate('CLOSE_TICKET.FLOW_TYPE.YELLOW_TO_MPEE_REJECTED', 'Rejected by MPEE');
      case 'GatTest':
        return translate('CLOSE_TICKET.FLOW_TYPE.GAT', 'GAT');
      default:
        return null;
    }
  };
};

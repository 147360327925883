import { hasValue } from '@lego/mst-error-utilities';
import { Card, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { AreaWithFlag } from '../../components/shared/AreaWithFlag';
import { ChipWithDefaultBackground } from '../../components/ticket-card/components/TicketCardMouldChips';
import { useTranslation } from '../../utility/i18n/translation';
import { Icons } from '../../utility/icons';
import { useMouldStatusCode } from '../components/mould/statusCodeHook';
import { StatusPill } from '../components/mould/StatusPill';
import { skeletonify } from '../skeleton';
import { MtsLogCardDataRow } from './MtsLogCardDataRow';
import { MtsLogCard_mould$key } from './__generated__/MtsLogCard_mould.graphql';
import { MtsLogCard_mtsLog$key } from './__generated__/MtsLogCard_mtsLog.graphql';

const ActualComponent: FC<
  { isHighlighted?: boolean } & ({ mtsLog: MtsLogCard_mtsLog$key } | { mould: MtsLogCard_mould$key })
> = (props) => {
  const { isHighlighted = false } = props;
  const { palette } = useTheme();
  const borderColor = palette.secondary.main;
  const { translate } = useTranslation();
  const isMtsLog = 'mtsLog' in props;
  const mtsLogData = useFragment(
    graphql`
      fragment MtsLogCard_mtsLog on MTSLog {
        employee {
          firstName
          lastName
        }
        location {
          description
        }
        plant {
          ...AreaWithFlag_plant
        }
        createdDate {
          date
          time
        }
        statusCode
        rack
        sublocation {
          ... on FreeTextSublocation {
            description
          }
          ... on Sublocation {
            description
          }
        }
        mouldMachine {
          equipmentNumber
        }
      }
    `,
    isMtsLog ? props.mtsLog : null
  );

  const mouldData = useFragment(
    graphql`
      fragment MtsLogCard_mould on Mould {
        location {
          description
        }
        plant {
          ...AreaWithFlag_plant
        }
        statusCode
        rack
        sublocation {
          ... on FreeTextSublocation {
            description
          }
          ... on Sublocation {
            description
          }
        }
        mouldMachine {
          equipmentNumber
        }
      }
    `,
    isMtsLog ? null : props.mould
  );

  const data = mtsLogData ?? mouldData;
  const statusDescription = useMouldStatusCode(data?.statusCode ?? null);

  if (!hasValue(data)) {
    return null;
  }

  return (
    <Card
      sx={{
        p: 2,
        ...(isHighlighted && {
          border: 'solid 2px',
          borderColor: borderColor,
        }),
      }}
    >
      <Grid container direction="row">
        <Grid container item direction="column" xs={1}>
          <StatusPill
            statusCode={data.statusCode ?? 0}
            backgroundColor={!isMtsLog ? palette.success.main : undefined}
          />
        </Grid>
        <Grid item xs>
          <Grid container direction="column" spacing={1} pt={1}>
            <Grid item>
              <Typography variant="h5">{statusDescription}</Typography>
            </Grid>
            <Grid container item>
              <MtsLogCardDataRow title={translate('TICKET_DETAILS.MTS_LOG_TAB.PLANT', 'Plant')}>
                <AreaWithFlag plant={data.plant ?? null} />
              </MtsLogCardDataRow>
            </Grid>
            {data.location?.description && (
              <Grid container item>
                <MtsLogCardDataRow title={translate('TICKET_DETAILS.MTS_LOG_TAB.LOCATION', 'Location')}>
                  <Typography>{data.location.description}</Typography>
                </MtsLogCardDataRow>
              </Grid>
            )}
            {data.rack && (
              <Grid container item>
                <MtsLogCardDataRow title={translate('TICKET_DETAILS.MTS_LOG_TAB.RACK', 'Rack')}>
                  <Typography>{data.rack}</Typography>
                </MtsLogCardDataRow>
              </Grid>
            )}
            {data.sublocation?.description && (
              <Grid container item>
                <MtsLogCardDataRow title={translate('TICKET_DETAILS.MTS_LOG_TAB.ROOM', 'Room (Row/Place)')}>
                  <Typography>{data.sublocation.description}</Typography>
                </MtsLogCardDataRow>
              </Grid>
            )}
            {data.mouldMachine?.equipmentNumber && (
              <Grid container item>
                <MtsLogCardDataRow title={translate('TICKET_DETAILS.MTS_LOG_TAB.MACHINE', 'Machine')}>
                  <Typography>{data.mouldMachine.equipmentNumber}</Typography>
                </MtsLogCardDataRow>
              </Grid>
            )}
            {'employee' in data && (
              <Grid container item>
                <MtsLogCardDataRow title={translate('TICKET_DETAILS.MTS_LOG_TAB.EMPLOYEE', 'Employee')}>
                  <Typography>
                    {data.employee?.firstName} {data.employee?.lastName}
                  </Typography>
                </MtsLogCardDataRow>
              </Grid>
            )}
            {'createdDate' in data && (
              <Grid container item direction="row" spacing={1}>
                <Grid item>
                  <ChipWithDefaultBackground icon={<Icons.Calendar />} label={data.createdDate.date} />
                </Grid>
                <Grid item>
                  <ChipWithDefaultBackground icon={<Icons.Clock />} label={data.createdDate.time} />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

const SkeletonComponent: FC = () => {
  return <Skeleton variant="rectangular" width={100} />;
};

export const MtsLogCard = skeletonify('MtsLogCard', ActualComponent, SkeletonComponent);

import { FormGroup, Grid, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { useTranslation } from '../../utility/i18n/translation';
import { TicketSearchBooleanFilterWithFacet } from '../ticket-search/TicketSearchBooleanFilterComponents';
import {
  EquipmentHistoryPriorityFilter_ticketsConnectionFacets$data,
  EquipmentHistoryPriorityFilter_ticketsConnectionFacets$key,
} from './__generated__/EquipmentHistoryPriorityFilter_ticketsConnectionFacets.graphql';
import { TicketsPriorityInput } from './__generated__/EquipmentHistoryTabQuery.graphql';

type Facets = NonNullable<EquipmentHistoryPriorityFilter_ticketsConnectionFacets$data['priority']>;

const Priorities: TicketsPriorityInput[] = [
  'Priority1',
  'Priority2',
  'Priority3',
  'Priority4',
  'Priority5',
  'Priority6',
  'Priority7',
  'Priority8',
  'Priority9',
];

export const EquipmentHistoryPriorityFilter: FC<{
  ticketsConnectionFacets: EquipmentHistoryPriorityFilter_ticketsConnectionFacets$key | null;
  selectedPriorities: TicketsPriorityInput[];
  onChange: (selectedPriorities: TicketsPriorityInput[]) => void;
}> = ({ ticketsConnectionFacets: facetsRef, selectedPriorities, onChange }) => {
  const data = useFragment(
    graphql`
      fragment EquipmentHistoryPriorityFilter_ticketsConnectionFacets on TicketsConnectionFacets {
        priority {
          count
          value
        }
      }
    `,
    facetsRef
  );

  const facets = data?.priority?.map(({ count, value }) => ({ count, value })) ?? [];

  const handleChange = (priority: TicketsPriorityInput) => (enabled: boolean) => {
    const filteredPriorities = selectedPriorities?.filter((p) => p !== priority);

    if (enabled) {
      onChange(selectedPriorities.concat(priority));
    } else {
      onChange(filteredPriorities);
    }
  };

  const { translate } = useTranslation();

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="subtitle2">{translate('TICKET_LIST.FILTERS.PRIORITY.TITLE', 'Priority')}</Typography>
      </Grid>
      <Grid item ml={2}>
        <FormGroup>
          {Priorities.map((priority) => (
            <TicketSearchBooleanFilterWithFacet<Facets[0]>
              key={priority}
              label={priority.substring('Priority'.length)}
              enabled={selectedPriorities?.includes(priority) ?? false}
              onChange={handleChange(priority)}
              facetValue={{ facets: facets, value: priority }}
            />
          ))}
        </FormGroup>
      </Grid>
    </Grid>
  );
};

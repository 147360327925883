import { hasValue } from '@lego/mst-error-utilities';
import { LoadingButton } from '@mui/lab';
import { TableCell, TableRow, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useTransition } from 'react';
import { useMutation, useRefetchableFragment } from 'react-relay';
import { Link } from 'react-router-dom';
import { ActivityIndicator } from '../../components/shared/ActivityIndicator';
import { AppRoutes } from '../../Router';
import { getTimestampForRelay } from '../../utility/date';
import { useTranslation } from '../../utility/i18n/translation';
import { PlannedSpringChangeJobRowMutation } from './__generated__/PlannedSpringChangeJobRowMutation.graphql';
import { PlannedSpringChangeJobRow_mould$key } from './__generated__/PlannedSpringChangeJobRow_mould.graphql';

export const PlannedSpringChangeJobRow: FC<{
  mould: PlannedSpringChangeJobRow_mould$key;
}> = ({ mould: mouldRef }) => {
  const { translate, locale } = useTranslation();
  const [mould, refetch] = useRefetchableFragment(
    graphql`
      fragment PlannedSpringChangeJobRow_mould on Mould
      @refetchable(queryName: "PlannedSpringChangeJobRowRefetchQuery") {
        id
        equipmentNumber
        description
        statusCode
        location {
          description
        }
        weight {
          value
          unitOfMeasurement
        }
        type
        boxConcept
        mouldMachine {
          equipmentNumber
        }
        actualShots
        shotsLastUpdatedDate {
          date
        }
        elementsPerShot
        lastSpringChangeShots
        springChangeInterval
        shotsSinceLastSpringChange
        dimmings {
          count
        }
        currentSpringChangeTicket {
          ticketNumber
        }
      }
    `,
    mouldRef
  );

  const [commit, isInFlight] = useMutation<PlannedSpringChangeJobRowMutation>(graphql`
    mutation PlannedSpringChangeJobRowMutation($input: MutationCreateSpringChangeTicketInput!) {
      createSpringChangeTicket(input: $input) {
        ... on MutationCreateSpringChangeTicketSuccess {
          data {
            ticketNumber
          }
        }
      }
    }
  `);

  const [isRefetching, startTransition] = useTransition();

  const handleCreate = () => {
    commit({
      variables: {
        input: {
          equipmentId: mould.id,
          createdDate: getTimestampForRelay(),
        },
      },
      onCompleted: () => {
        startTransition(() => {
          refetch({});
        });
      },
    });
  };

  if (!hasValue(mould)) {
    return (
      <TableRow>
        <TableCell colSpan={100} align="left">
          <Typography>{translate('PLANNED_JOBS.MOULD_MISSING', 'Missing mould')}</Typography>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell align="center">{mould.equipmentNumber}</TableCell>
      <TableCell align="center">{mould.description}</TableCell>
      <TableCell align="center">{mould.statusCode}</TableCell>
      <TableCell align="center">{mould.location?.description}</TableCell>
      <TableCell align="center">{`${mould.weight?.value} ${mould.weight?.unitOfMeasurement}`}</TableCell>
      <TableCell align="center">{mould.type}</TableCell>
      <TableCell align="center">{mould.boxConcept}</TableCell>
      <TableCell align="center">{mould.mouldMachine?.equipmentNumber}</TableCell>
      <TableCell align="center">{mould.lastSpringChangeShots?.toLocaleString(locale)}</TableCell>
      <TableCell align="center">{mould.actualShots?.toLocaleString(locale)}</TableCell>
      <TableCell align="center">
        {hasValue(mould.shotsLastUpdatedDate) && hasValue(mould.shotsLastUpdatedDate?.date)
          ? new Date(mould.shotsLastUpdatedDate?.date).toLocaleDateString()
          : undefined}
      </TableCell>
      <TableCell align="center">{mould.springChangeInterval?.toLocaleString(locale)}</TableCell>
      <TableCell align="center">{mould.shotsSinceLastSpringChange?.toLocaleString(locale)}</TableCell>
      <TableCell align="center">
        {hasValue(mould.springChangeInterval) && hasValue(mould.shotsSinceLastSpringChange)
          ? (mould.shotsSinceLastSpringChange - mould.springChangeInterval).toLocaleString(locale)
          : undefined}
      </TableCell>
      <TableCell align="center">
        {mould.elementsPerShot}/{mould.dimmings?.count}
      </TableCell>
      <TableCell align="center">
        {hasValue(mould.currentSpringChangeTicket) && hasValue(mould.currentSpringChangeTicket.ticketNumber) ? (
          <Link
            to={AppRoutes.tickets.byTicketNumber(mould.currentSpringChangeTicket.ticketNumber, 'details')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {mould.currentSpringChangeTicket?.ticketNumber}
          </Link>
        ) : (
          <LoadingButton
            onClick={handleCreate}
            loading={isInFlight || isRefetching}
            sx={{ p: 0 }}
            loadingIndicator={<ActivityIndicator size={40} />}
          >
            {translate('PLANNED_JOBS.CREATE_TICKET', 'Create')}
          </LoadingButton>
        )}
      </TableCell>
    </TableRow>
  );
};

import { Chip, useTheme } from '@mui/material';
import { FC } from 'react';
import { useFormatDateWithLocale } from '../../../utility/date';
import { Icons } from '../../../utility/icons';

export const TicketDateWithCalendarIcon: FC<{
  date: string;
  borderColor?: string;
}> = ({ date, borderColor }) => {
  const {
    palette: { background },
  } = useTheme();

  const borderStyling = borderColor ? { borderColor, borderWidth: '1px', borderStyle: 'solid' } : null;
  const dateLabel = useFormatDateWithLocale(date, 'dd/MM/yyyy, H:mm');

  return (
    <Chip
      label={dateLabel}
      style={{ backgroundColor: background.default, ...borderStyling }}
      icon={<Icons.Calendar color="primary" />}
    />
  );
};

import { hasValue } from '@lego/mst-error-utilities';
import { MachineHoursDeletion } from '../../../contexts/add-machine-hours/add-machine-hours-context';
import { AddTimeDeletion } from '../../../contexts/add-time/add-time-context';

export const isRegistrationMarkedForDeletion = (
  manHourId: string,
  timeRegNumber: number,
  deletions: AddTimeDeletion[]
): boolean => {
  return hasValue(deletions.find((val) => val.manHourId === manHourId && val.timeRegistrationNumber === timeRegNumber));
};

export const isMachineTimeRegistrationMarkedForDeletion = (
  machineHourId: string,
  timeRegNumber: number,
  deletions: MachineHoursDeletion[]
): boolean => {
  return deletions.some((val) => val.machineHourId === machineHourId && val.timeRegistrationNumber === timeRegNumber);
};

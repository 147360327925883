import { Grid, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback } from 'react';
import { useFragment, useMutation } from 'react-relay';
import { SimpleDialog } from '../../../components/shared/SimpleDialog';
import { useTranslation } from '../../../utility/i18n/translation';
import { DeleteDimmingDialogMutation } from './__generated__/DeleteDimmingDialogMutation.graphql';
import { DeleteDimmingDialog_dimmingDetails$key } from './__generated__/DeleteDimmingDialog_dimmingDetails.graphql';

export const DeleteDimmingDialog: FC<{
  onDismiss: () => void;
  dimmingId: string;
  dimmingDetails: DeleteDimmingDialog_dimmingDetails$key;
}> = ({ onDismiss, dimmingId, dimmingDetails }) => {
  const { translate } = useTranslation();
  const [commitMutation, loading] = useMutation<DeleteDimmingDialogMutation>(graphql`
    mutation DeleteDimmingDialogMutation($input: MutationMouldDeleteDimmingInput!) {
      mouldDeleteDimming(input: $input) {
        ... on MutationMouldDeleteDimmingSuccess {
          data {
            dimmings {
              details {
                id
              }
            }
          }
        }
      }
    }
  `);

  const onSavePressed = useCallback(() => {
    commitMutation({
      variables: { input: { dimmingId } },
      onCompleted: () => {
        onDismiss();
      },
    });
  }, [commitMutation, dimmingId, onDismiss]);

  return (
    <SimpleDialog
      onDismiss={onDismiss}
      open={true}
      title={translate('TICKET_DETAILS.CARDS.DIMMINGS.DELETE.TITLE', 'Delete dimming')}
      primaryAction={{
        primaryActionLabel: translate('TICKET_DETAILS.CARDS.DIMMINGS.DELETE.DELETE', 'Delete'),
        primaryActionPressed: onSavePressed,
        primaryActionLoading: loading,
      }}
      secondaryAction={{
        secondaryActionLabel: translate('TICKET_DETAILS.CARDS.DIMMINGS.DELETE.CANCEL', 'Cancel'),
        secondaryActionPressed: onDismiss,
        secondaryActionDisabled: loading,
      }}
      content={{
        type: 'node',
        node: <DialogContent dimmingDetails={dimmingDetails} />,
      }}
      maxWidth={'md'}
    />
  );
};

const DialogContent: FC<{
  dimmingDetails: DeleteDimmingDialog_dimmingDetails$key;
}> = (props) => {
  const { translate } = useTranslation();
  const { dimmingDetails: dimmingDetailRef } = props;
  const data = useFragment(
    graphql`
      fragment DeleteDimmingDialog_dimmingDetails on DimmingDetails {
        cavityNumber
        isPermanent
      }
    `,
    dimmingDetailRef
  );

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography>
          {translate(
            'TICKET_DETAILS.CARDS.DIMMINGS.DELETE.DIALOG_BODY',
            'Are you sure you want to delete this dimming?'
          )}
        </Typography>
      </Grid>
      <Grid item container flexDirection={'row'}>
        <Grid item xs={2}>
          {translate('TICKET_DETAILS.CARDS.DIMMINGS.DELETE.CAVITY', 'Cavity number')}
        </Grid>
        <Grid item>{data.cavityNumber}</Grid>
      </Grid>
      <Grid item container flexDirection={'row'} spacing={2}>
        <Grid item xs={2}>
          {translate('TICKET_DETAILS.CARDS.DIMMINGS.DELETE.TYPE', 'Type')}
        </Grid>
        <Grid item>
          {data.isPermanent
            ? translate('TICKET_DETAILS.CARDS.DIMMINGS.PERMANENT', 'PERMANENT')
            : translate('TICKET_DETAILS.CARDS.DIMMINGS.TEMPORARY', 'TEMPORARY')}
        </Grid>
      </Grid>
    </Grid>
  );
};

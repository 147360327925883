import { gql } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useCloseTicketContext } from '../../contexts/close-ticket/close-ticket-context';
import { AppRoutes } from '../../Router';
import { useTranslation } from '../../utility/i18n/translation';
import { repairDamageListLength } from '../ticket-details/TDRepairDescription';
import { TD_REPAIR_DOCUMENTATION_WIDGET } from '../ticket-details/TDRepairFragments';
import { CTNoManhoursOrRepairDocs } from './CTNoManhoursOrRepairDocs';
import { CTRepairDocumentationRow } from './CTRepairDocumentationRow';
import { CTRepairDocumentationRowPacking } from './CTRepairDocumentationRowPacking';
import {
  RepairDocumentationAccordion,
  RepairDocumentationAccordion_repairDocumentation_damageList,
} from './__apollo__/RepairDocumentationAccordion';

export const REPAIR_DOCUMENTATION_ACCORDION = gql`
  fragment RepairDocumentationAccordion on Ticket {
    id
    repairDocumentation {
      id
      ...RepairDocumentationWidget
    }
  }
  ${TD_REPAIR_DOCUMENTATION_WIDGET}
`;

export const CTRepairDocumentation: FC<RepairDocumentationAccordion> = ({
  id,
  repairDocumentation: { damageList },
}) => {
  const { translate } = useTranslation();

  const length = repairDamageListLength(damageList);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="subtitle2">
          {translate('CLOSE_TICKET.REPAIR_STEP.CARD_HEADER', 'Repair documentation (mandatory)')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          {translate(
            'CLOSE_TICKET.REPAIR_STEP.CARD_DESCRIPTION',
            'Before closing the ticket, please check that it has been documented correctly'
          )}
        </Typography>
      </Grid>
      {length === 0 && (
        <Grid item>
          <NoDocumentation />
        </Grid>
      )}
      <Grid item>
        <RepairDocumentationAccordionComponent damageList={damageList} />
      </Grid>
      <Grid item>
        <Button
          data-cy="CTRepairDocumentation-addRepairButton"
          variant="text"
          color="primary"
          style={{ textTransform: 'none' }}
          component={NavLink}
          to={AppRoutes.tickets.repair(id)}
          startIcon={<AddIcon />}
        >
          {translate('CLOSE_TICKET.REPAIR_STEP.DOCUMENTATION_BUTTON', 'Documentation')}
        </Button>
      </Grid>
    </Grid>
  );
};

const NoDocumentation: FC = () => {
  const { translate } = useTranslation();
  const {
    state: { showRepairDocsRequiredWarning },
  } = useCloseTicketContext();

  const title = translate('CLOSE_TICKET.REPAIR_STEP.NO_DOCUMENTATION_HEADER', 'No documentation registered');

  const message = translate(
    'CLOSE_TICKET.REPAIR_STEP.NO_DOCUMENTATION_ERROR',
    'Repair documentation is mandatory for this ticket type'
  );

  return <CTNoManhoursOrRepairDocs title={title} message={message} showWarning={showRepairDocsRequiredWarning} />;
};

export const RepairDocumentationAccordionComponent: FC<{
  damageList: RepairDocumentationAccordion_repairDocumentation_damageList;
  padding?: number;
}> = ({ damageList, padding }) => {
  if (damageList.__typename === 'RepairDocumentationDamageList') {
    return (
      <Grid container>
        {damageList.damages.map((damage, index) => {
          return (
            <Grid item key={index} xs={12}>
              <CTRepairDocumentationRow {...damage} padding={padding} />
              {index < damageList.damages.length - 1 && <Divider />}
            </Grid>
          );
        })}
      </Grid>
    );
  } else {
    return (
      <Grid container>
        {damageList.subEquipments.map((subEquipment, index) => {
          return (
            <Grid item key={index} xs={12}>
              <CTRepairDocumentationRowPacking {...subEquipment} padding={padding} />
              {index < damageList.subEquipments.length - 1 && <Divider />}
            </Grid>
          );
        })}
      </Grid>
    );
  }
};

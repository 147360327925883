import { Container } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useEffect, useMemo } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { useParams } from 'react-router-dom';
import { FillWidthLoading } from '../../components/shared/FillWidthLoading';
import { PageErrorBoundary } from '../PageErrorBoundary';
import { skeletonify } from '../skeleton';
import { MtsLogTabList } from './MtsLogTabList';
import EquipmentMtsLogTabQuery, {
  EquipmentMtsLogTabQuery as EquipmentMtsLogTabQueryType,
  EquipmentMtsLogTabQuery$variables,
} from './__generated__/EquipmentMtsLogTabQuery.graphql';

const ActualComponent: FC<{
  query: PreloadedQuery<EquipmentMtsLogTabQueryType>;
}> = (props) => {
  const { query: queryRef } = props;
  const data = usePreloadedQuery(
    graphql`
      query EquipmentMtsLogTabQuery($input: QueryEquipmentInput!) {
        equipment(input: $input) {
          ... on QueryEquipmentSuccess {
            data {
              ...MtsLogTabList_mould
            }
          }
        }
      }
    `,
    queryRef
  );

  const equipment = data.equipment?.data;
  return <MtsLogTabList.Suspense mould={equipment} />;
};

const EquipmentMtsLogTabPage = skeletonify('EquipmentMtsLogTabPage', ActualComponent, FillWidthLoading);

export const EquipmentMtsLogTab: FC = () => {
  const { id } = useParams() as { id: string };
  const [queryRef, loadQuery] = useQueryLoader<EquipmentMtsLogTabQueryType>(EquipmentMtsLogTabQuery);

  const queryInput: EquipmentMtsLogTabQuery$variables = useMemo(() => {
    return {
      input: { equipmentNumber: Number.parseInt(id) },
    };
  }, [id]);

  useEffect(() => loadQuery(queryInput), [id, loadQuery, queryInput]);

  return (
    <Container maxWidth="lg" sx={{ height: '100%' }}>
      <PageErrorBoundary onReset={() => loadQuery(queryInput)}>
        {queryRef ? <EquipmentMtsLogTabPage.Suspense query={queryRef} /> : <EquipmentMtsLogTabPage.Skeleton />}
      </PageErrorBoundary>
    </Container>
  );
};

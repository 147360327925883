import { hasValue } from '@lego/mst-error-utilities';
import { Grid } from '@mui/material';
import { format } from 'date-fns';
import { FC, useCallback, useState } from 'react';
import { SparePartSearchProvider } from '../dashboard/spare-part-bom/spare-part-search-context';
import { CmsTicketFormProps } from './CmsCreateTicket';
import { CmsEquipmentSearch } from './CmsEquipmentSearch';
import { useCmsCreateSparepartManufacturingTicketMutation } from './CmsMutations';
import { CmsSparePartSearch } from './CmsSparePartSearch';
import { CmsTicketDescription } from './CmsTicketDescription';
import { CmsTicketEndDate } from './CmsTicketEndDate';
import { CmsTicketQuantity } from './CmsTicketQuantity';
import { CmsTicketSubmitButtons } from './CmsTicketSubmitButtons';

export const CmsSparePartManufacturingForm: FC<CmsTicketFormProps> = ({ onComplete }) => {
  const [mutation, loading] = useCmsCreateSparepartManufacturingTicketMutation();

  const [equipmentId, setEquipmentId] = useState<string | null>();
  const [sparePartId, setSparePartId] = useState<string | null>();
  const [quantity, setQuantity] = useState<number | null>();
  const [requiredEndDate, setRequiredEndDate] = useState<Date | null>();
  const [description, setDescription] = useState<string | null>();

  const formCompleted =
    hasValue(sparePartId) && hasValue(quantity) && hasValue(description) && hasValue(requiredEndDate);

  const onClick = useCallback(
    (assign: boolean) => {
      if (!formCompleted) {
        return;
      }
      mutation({
        variables: {
          input: {
            assignToSelf: assign,
            equipmentId: equipmentId ?? undefined,
            sparePartId: sparePartId,
            quantity: quantity,
            requiredEndDate: format(requiredEndDate, 'yyyy-MM-dd'),
            description,
          },
        },
        onCompleted: ({ result }) => {
          const ticketNumber = result?.data?.ticketNumber;
          if (hasValue(ticketNumber) && hasValue(onComplete)) {
            onComplete(ticketNumber, assign);
          }
        },
      });
    },
    [formCompleted, mutation, equipmentId, sparePartId, quantity, requiredEndDate, description, onComplete]
  );

  return (
    <Grid container item direction="column" spacing={3}>
      <Grid item>
        <CmsEquipmentSearch onChange={setEquipmentId} />
      </Grid>
      <Grid item>
        <SparePartSearchProvider>
          <CmsSparePartSearch onChange={setSparePartId} />
        </SparePartSearchProvider>
      </Grid>
      <Grid item>
        <CmsTicketQuantity value={quantity ?? null} onChange={setQuantity} />
      </Grid>
      <Grid item>
        <CmsTicketEndDate value={requiredEndDate ?? null} onChange={setRequiredEndDate} />
      </Grid>
      <Grid item>
        <CmsTicketDescription onChange={setDescription} />
      </Grid>
      <Grid item>
        <CmsTicketSubmitButtons loading={loading} submit={(assign) => onClick(assign)} disabled={!formCompleted} />
      </Grid>
    </Grid>
  );
};

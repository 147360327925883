import { useTranslation } from '../../../utility/i18n/translation';

/**
 * Returns a translated status string that matches the given status code
 * @param code the mould status code
 */
// eslint-disable-next-line complexity, max-lines-per-function
export const useMouldStatusCode = (code: number | null): string => {
  const { translate } = useTranslation();

  switch (code) {
    case 103:
      return translate('MOULD_STATUS_CODES.103', 'Production mould - Return to TO');
    case 140:
      return translate('MOULD_STATUS_CODES.140', 'Ready for production');
    case 141:
      return translate('MOULD_STATUS_CODES.141', 'Mould cleaning');
    case 142:
      return translate('MOULD_STATUS_CODES.142', 'Mould repair');
    case 143:
      return translate('MOULD_STATUS_CODES.143', 'Quality approval');
    case 144:
      return translate('MOULD_STATUS_CODES.144', 'Inspection approval');
    case 145:
      return translate('MOULD_STATUS_CODES.145', 'External');
    case 148:
      return translate('MOULD_STATUS_CODES.148', 'Waiting for spare parts');
    case 210:
      return translate('MOULD_STATUS_CODES.210', 'Approved for destruction');
    case 225:
      return translate('MOULD_STATUS_CODES.225', 'Moulds used for training (China)');
    case 226:
      return translate('MOULD_STATUS_CODES.226', 'Moulds used for training (PA)');
    case 227:
      return translate('MOULD_STATUS_CODES.227', 'Moulds used for training (TWI)');
    case 228:
      return translate('MOULD_STATUS_CODES.228', 'Moulds used for training (COF)');
    case 229:
      return translate('MOULD_STATUS_CODES.229', 'Moulds used for training');
    case 230:
      return translate('MOULD_STATUS_CODES.230', 'Test mould. (NOT FOR PRODUCTION)');
    case 231:
      return translate('MOULD_STATUS_CODES.231', 'PROTOTYPE MOULDS. (NOT FOR PRODUCTION)');
    case 250:
      return translate('MOULD_STATUS_CODES.250', 'ON HOLD');
    case 303:
      return translate('MOULD_STATUS_CODES.303', 'WO in MQ');
    case 340:
      return translate('MOULD_STATUS_CODES.340', 'Ready for production');
    case 341:
      return translate('MOULD_STATUS_CODES.341', 'Mould cleaning');
    case 342:
      return translate('MOULD_STATUS_CODES.342', 'Mould repair');
    case 343:
      return translate('MOULD_STATUS_CODES.343', 'Quality approval');
    case 344:
      return translate('MOULD_STATUS_CODES.344', 'Inspection approval');
    case 345:
      return translate('MOULD_STATUS_CODES.345', 'External');
    case 348:
      return translate('MOULD_STATUS_CODES.348', 'Waiting for spare parts');
    case 403:
      return translate('MOULD_STATUS_CODES.403', 'Transit mould - Return to TO');
    case 440:
      return translate('MOULD_STATUS_CODES.440', 'Ready for production');
    case 441:
      return translate('MOULD_STATUS_CODES.441', 'Mould cleaning');
    case 442:
      return translate('MOULD_STATUS_CODES.442', 'Mould repair');
    case 443:
      return translate('MOULD_STATUS_CODES.443', 'Quality approval');
    case 444:
      return translate('MOULD_STATUS_CODES.444', 'Inspection approval');
    case 445:
      return translate('MOULD_STATUS_CODES.445', 'External');
    case 447:
      return translate('MOULD_STATUS_CODES.447', 'In Transit');
    case 448:
      return translate('MOULD_STATUS_CODES.448', 'Waiting for spare parts');
    case 501:
      return translate('MOULD_STATUS_CODES.501', 'New mould under construction');
    case 503:
      return translate('MOULD_STATUS_CODES.503', 'New mould in MQ');
    case 540:
      return translate('MOULD_STATUS_CODES.540', 'Ready for production');
    case 541:
      return translate('MOULD_STATUS_CODES.541', 'Mould cleaning');
    case 542:
      return translate('MOULD_STATUS_CODES.542', 'Mould repair');
    case 543:
      return translate('MOULD_STATUS_CODES.543', 'Quality approval');
    case 544:
      return translate('MOULD_STATUS_CODES.544', 'Inspection approval');
    case 545:
      return translate('MOULD_STATUS_CODES.545', 'External');
    case 548:
      return translate('MOULD_STATUS_CODES.548', 'Waiting for spare parts');
    case 603:
      return translate('MOULD_STATUS_CODES.603', 'Qualification Mould - Return to TO');
    case 640:
      return translate('MOULD_STATUS_CODES.640', 'Ready for production');
    case 641:
      return translate('MOULD_STATUS_CODES.641', 'Mould cleaning');
    case 642:
      return translate('MOULD_STATUS_CODES.642', 'Mould repair');
    case 643:
      return translate('MOULD_STATUS_CODES.643', 'Quality approval');
    case 644:
      return translate('MOULD_STATUS_CODES.644', 'Inspection approval');
    case 645:
      return translate('MOULD_STATUS_CODES.645', 'External');
    case 648:
      return translate('MOULD_STATUS_CODES.648', 'Waiting for spare parts');
    case 803:
      return translate('MOULD_STATUS_CODES.803', 'MQ');
    case 840:
      return translate('MOULD_STATUS_CODES.840', 'Ready for production');
    case 841:
      return translate('MOULD_STATUS_CODES.841', 'Mould cleaning');
    case 842:
      return translate('MOULD_STATUS_CODES.842', 'Mould repair');
    case 843:
      return translate('MOULD_STATUS_CODES.843', 'Quality approval');
    case 844:
      return translate('MOULD_STATUS_CODES.844', 'Inspection approval');
    case 845:
      return translate('MOULD_STATUS_CODES.845', 'External');
    case 848:
      return translate('MOULD_STATUS_CODES.848', 'Waiting for spare parts');
    case 849:
      return translate('MOULD_STATUS_CODES.849', 'GAT Test');
    case 900:
      return translate('MOULD_STATUS_CODES.900', 'Lent externally');
    case 901:
      return translate('MOULD_STATUS_CODES.901', 'Lent internally');
    case 902:
      return translate('MOULD_STATUS_CODES.902', 'Available MF(?)');
    case 999:
      return translate('MOULD_STATUS_CODES.999', 'Mini Order');
    case null:
      return translate('MOULD_STATUS_CODES.MISSING', 'Missing status code');
    default:
      return translate('MOULD_STATUS_CODES.UNKNOWN', 'Unknown status code');
  }
};

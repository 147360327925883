import { Box, Button, Container, Grid, Typography, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Figures } from '../utility/figures';
import { useTranslation } from '../utility/i18n/translation';

export const ErrorPage: FC<Partial<FallbackProps>> = (props) => {
  const { resetErrorBoundary } = props;
  const { palette } = useTheme();
  const { translate } = useTranslation();

  return (
    <Container sx={{ height: '100%' }}>
      <Grid container direction="row" sx={{ height: '100%' }} spacing={1}>
        <Grid item container xs direction="column" justifyContent="center" spacing={4}>
          <Grid item>
            <Typography variant="h3">{translate('ERROR_PAGE.TITLE', 'Something went wrong')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {translate(
                'ERROR_PAGE.BODY',
                'An unexpected error occurred. Try again or contact your specialist for help.'
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={resetErrorBoundary}>
              {translate('ERROR_PAGE.BUTTON', 'Try again')}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs>
          <Box>
            <Figures.Scaphander fill={palette.primary.main} fillOpacity={0.4} transform="scale(-1,1)" />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const InternalErrorPage: FC<FallbackProps> = (props) => <ErrorPage {...props} />;

export const PageErrorBoundary: FC<{
  children: ReactNode;
  onReset?(): void;
}> = (props) => {
  const { children, onReset } = props;

  return (
    <ErrorBoundary onReset={onReset} FallbackComponent={InternalErrorPage}>
      {children}
    </ErrorBoundary>
  );
};

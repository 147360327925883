import { hasValue } from '@lego/mst-error-utilities';

type ExtractTypename<Type, TypeName extends string> = Extract<Type, { __typename: TypeName }>;

type GraphQLType<T extends string = string> = { __typename: T };

const isGraphQLType = <T extends GraphQLType, Typename extends string>(
  t: Typename,
  entity: T | null | undefined
): entity is ExtractTypename<T, Typename> => {
  return hasValue(entity) && entity.__typename === t;
};

const isEquipmentMould = <T extends GraphQLType>(
  equipmentValue?: T | null
): equipmentValue is ExtractTypename<T, 'Mould'> => {
  return isGraphQLType('Mould', equipmentValue);
};

export const isEquipmentValue = <T extends GraphQLType>(
  equipment?: T | null
): equipment is ExtractTypename<T, 'EquipmentValue'> => {
  return isGraphQLType('EquipmentValue', equipment);
};

export const isEquipmentOfTypeMould = <
  T extends GraphQLType | (GraphQLType<'EquipmentValue'> & { value: GraphQLType })
>(
  equipment?: T | null
): equipment is ExtractTypename<T, 'EquipmentValue'> & {
  value: ExtractTypename<ExtractTypename<T, 'EquipmentValue'>['value'], 'Mould'>;
} => {
  return isEquipmentValue(equipment) && isEquipmentMould(equipment.value);
};

import { gql } from '@apollo/client';
import { FC } from 'react';
import { useGMQuery } from '../../../apollo/customApolloHooks';
import { TDAssignedToOld, TD_ASSIGNED_TO_FRAGMENT } from '../../../components/ticket-details/TDAssignedTo';
import { TDAssignedToOrCreatedBySkeleton } from './TDCreatedBy';
import { TDAssignedToQuery, TDAssignedToQueryVariables } from './__apollo__/TDAssignedToQuery';

const QUERY = gql`
  query TDAssignedToQuery($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        ...AssignedTo
      }
    }
  }
  ${TD_ASSIGNED_TO_FRAGMENT}
`;

export const TDAssignedTo: FC<{ ticketNumber: string }> = ({ ticketNumber }) => {
  const { data, loading } = useGMQuery<TDAssignedToQuery, TDAssignedToQueryVariables>(QUERY, {
    variables: { input: { id: ticketNumber } },
  });

  if (loading && !data) {
    return <TDAssignedToOrCreatedBySkeleton />;
  }

  if (!data || data.ticket.__typename !== 'Ticket') {
    return null;
  }

  return <TDAssignedToOld {...data.ticket} />;
};

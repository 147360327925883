/**
 * @generated SignedSource<<823fe2028a9e282df33ffc320e815ec0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddErrorDescriptionDialog_ticket$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"AddErrorDescriptionDialog_Content_ticket">;
  readonly " $fragmentType": "AddErrorDescriptionDialog_ticket";
};
export type AddErrorDescriptionDialog_ticket$key = {
  readonly " $data"?: AddErrorDescriptionDialog_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddErrorDescriptionDialog_ticket">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddErrorDescriptionDialog_ticket",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddErrorDescriptionDialog_Content_ticket"
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "7ae47a81e1027050b39da3e5cb504e33";

export default node;

import { hasValue } from '@lego/mst-error-utilities';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useFragment } from 'react-relay';
import { useTranslation } from '../../../utility/i18n/translation';
import { Icons } from '../../../utility/icons';
import { PrintSparePartLabelDialog } from '../../equipment-details/PrintSparePartsLabelDialog';
import { skeletonify } from '../../skeleton';
import { SparePartDetailsDialogStockLevels_MroPlantAvailable_mroPlant$key } from './__generated__/SparePartDetailsDialogStockLevels_MroPlantAvailable_mroPlant.graphql';
import { SparePartDetailsDialogStockLevels_PlantRow_mroPlant$key } from './__generated__/SparePartDetailsDialogStockLevels_PlantRow_mroPlant.graphql';
import { SparePartDetailsDialogStockLevels_StockRow_storageLocation$key } from './__generated__/SparePartDetailsDialogStockLevels_StockRow_storageLocation.graphql';
import { SparePartDetailsDialogStockLevels_query$key } from './__generated__/SparePartDetailsDialogStockLevels_query.graphql';

const ActualComponent: FC<{
  query: SparePartDetailsDialogStockLevels_query$key | null;
  sparePartId: string;
}> = (props) => {
  const { query: queryRef, sparePartId } = props;
  const { translate } = useTranslation();
  const data = useFragment(
    graphql`
      fragment SparePartDetailsDialogStockLevels_query on Query @argumentDefinitions(sparePartId: { type: "ID!" }) {
        plants {
          mroPlants {
            id
            mroNumber
            ...SparePartDetailsDialogStockLevels_PlantRow_mroPlant @arguments(sparePartId: $sparePartId)
          }
        }
      }
    `,
    queryRef
  );

  // TODO: Sort our plant first?
  const mroPlants = data?.plants?.flatMap(({ mroPlants }) => mroPlants ?? []).sort((a, b) => a.mroNumber - b.mroNumber);

  return (
    <Grid container flexDirection={'column'} spacing={1}>
      <Grid item>
        <Typography variant="h3">
          {translate('SPAREPART_DETAILS.STOCK_OVERVIEW.HEADERS.STOCK_LEVELS', 'Stock levels')}
        </Typography>
      </Grid>
      <Grid item>
        {mroPlants?.map((plant) => (
          <PlantRow mroPlant={plant} key={plant.id} sparePartId={sparePartId} />
        ))}
      </Grid>
    </Grid>
  );
};

const SkeletonComponent: FC = () => {
  return <Skeleton variant="text" width={100} />;
};

export const SparePartDetailsDialogStockLevels = skeletonify(
  'SparePartDetailsDialogStockLevels',
  ActualComponent,
  SkeletonComponent
);

const MroPlantAvailable: FC<{
  mroPlant: SparePartDetailsDialogStockLevels_MroPlantAvailable_mroPlant$key;
}> = ({ mroPlant: mroPlantRef }) => {
  const plant = useFragment(
    graphql`
      fragment SparePartDetailsDialogStockLevels_MroPlantAvailable_mroPlant on MroPlant
      @argumentDefinitions(sparePartId: { type: "ID!" }) {
        storageLocations @required(action: THROW) {
          stock(input: { sparePartId: $sparePartId }) {
            amount
            reservedAmount @required(action: THROW)
          }
        }
      }
    `,
    mroPlantRef
  );

  return (
    <Typography>
      {plant.storageLocations
        .map(({ stock }) => stock)
        .filter(hasValue)
        .map((stock) => stock.amount - stock.reservedAmount)
        .reduce((acc, cur) => acc + cur, 0)}
    </Typography>
  );
};

const PlantRow: FC<{
  mroPlant: SparePartDetailsDialogStockLevels_PlantRow_mroPlant$key;
  sparePartId: string;
}> = ({ mroPlant: mroPlantRef, sparePartId }) => {
  const plant = useFragment(
    graphql`
      fragment SparePartDetailsDialogStockLevels_PlantRow_mroPlant on MroPlant
      @argumentDefinitions(sparePartId: { type: "ID!" }) {
        id
        mroNumber
        description
        storageLocations {
          id
          ...SparePartDetailsDialogStockLevels_StockRow_storageLocation @arguments(sparePartId: $sparePartId)
        }
        ...SparePartDetailsDialogStockLevels_MroPlantAvailable_mroPlant @arguments(sparePartId: $sparePartId)
      }
    `,
    mroPlantRef
  );
  const { translate } = useTranslation();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container flexDirection="row" justifyContent={'space-between'}>
          <Typography>
            {plant.mroNumber} {plant.description}
          </Typography>
          <ErrorBoundary fallback={<Typography>?</Typography>}>
            <MroPlantAvailable mroPlant={plant} />
          </ErrorBoundary>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <GMTableCell
                  label={translate(
                    'SPAREPART_DETAILS.STOCK_OVERVIEW.TABLE_HEADERS.STORAGE_LOCATION',
                    'Storage location'
                  )}
                  bold
                  alignLeft
                />
                <GMTableCell label={translate('SPAREPART_DETAILS.STOCK_OVERVIEW.TABLE_HEADERS.BIN', 'Bin')} bold />
                <GMTableCell label={translate('SPAREPART_DETAILS.STOCK_OVERVIEW.TABLE_HEADERS.MINIMUM', 'Min')} bold />
                <GMTableCell label={translate('SPAREPART_DETAILS.STOCK_OVERVIEW.TABLE_HEADERS.MAXIMUM', 'Max')} bold />
                <GMTableCell
                  label={translate('SPAREPART_DETAILS.STOCK_OVERVIEW.TABLE_HEADERS.IN_STOCK', 'In stock')}
                  bold
                />
                <GMTableCell
                  label={translate('SPAREPART_DETAILS.STOCK_OVERVIEW.TABLE_HEADERS.RESERVED', 'Reserved')}
                  bold
                />
                <GMTableCell
                  label={translate('SPAREPART_DETAILS.STOCK_OVERVIEW.TABLE_HEADERS.AVAILABLE', 'Available')}
                  bold
                />
                <GMTableCell
                  label={translate('SPAREPART_DETAILS.STOCK_OVERVIEW.TABLE_HEADERS.ACTIONS', 'Actions')}
                  bold
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {plant.storageLocations?.map((location) => (
                <StockRow storageLocation={location} key={location.id} sparePartId={sparePartId} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

const GMTableCell: FC<{
  label: string;
  bold?: boolean;
  alignLeft?: boolean;
}> = ({ label, bold, alignLeft }) => {
  return (
    <TableCell align={alignLeft ? undefined : 'right'} sx={{ fontWeight: bold ? 700 : undefined }}>
      {label}
    </TableCell>
  );
};

const StockRow: FC<{
  storageLocation: SparePartDetailsDialogStockLevels_StockRow_storageLocation$key;
  sparePartId: string;
}> = ({ storageLocation: storageLocationRef, sparePartId }) => {
  const [open, setOpen] = useState(false);
  const onPrintButtonClicked = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onDismiss = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const location = useFragment(
    graphql`
      fragment SparePartDetailsDialogStockLevels_StockRow_storageLocation on StorageLocation
      @argumentDefinitions(sparePartId: { type: "ID!" }) {
        __typename
        description
        id
        stock(input: { sparePartId: $sparePartId }) {
          amount
          maxStock
          storageBin
          minimumStockBeforeReorder
          reservedAmount
        }
      }
    `,
    storageLocationRef
  );

  if (location.stock === null) {
    return null;
  }

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <PrintSparePartLabelDialog
        sparePartId={sparePartId}
        open={open}
        onDismiss={onDismiss}
        storageLocationId={location.id}
      />
      <GMTableCell label={location.description} alignLeft />
      <GMTableCell label={location.stock.storageBin ?? ''} />
      <GMTableCell label={`${location.stock.minimumStockBeforeReorder}`} />
      <GMTableCell label={`${location.stock.maxStock}`} />
      <GMTableCell label={`${location.stock.amount}`} />
      <GMTableCell label={`${location.stock.reservedAmount}`} />
      <GMTableCell
        label={
          hasValue(location.stock.reservedAmount) ? `${location.stock.amount - location.stock.reservedAmount}` : '?'
        }
      />
      <TableCell align={'right'}>
        <IconButton onClick={onPrintButtonClicked}>
          <Icons.Print />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

import { hasValue } from '@lego/mst-error-utilities';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useState } from 'react';
import { useFragment } from 'react-relay';
import { GMDropdown } from '../../../components/shared/GMDropdown';
import {
  LocationSelect_equipment$data,
  LocationSelect_equipment$key,
} from './__generated__/LocationSelect_equipment.graphql';

interface Props {
  equipment: LocationSelect_equipment$key;
  loading: boolean;
  onChange: (locationId: string) => void;
}

export const LocationSelect: FC<Props> = ({ equipment: equipmentRef, loading, onChange }) => {
  const equipment = useFragment(
    graphql`
      fragment LocationSelect_equipment on Equipment {
        id
        location {
          id
          description
          locationNumber
        }
        plant {
          locations {
            id
            description
            locationNumber
          }
        }
      }
    `,
    equipmentRef
  );

  const { plant, location } = equipment;

  // used for optimistic UI while the mutation is running
  const [selectedLocation, setSelectedLocation] = useState<LocationSelect_equipment$data['location']>();

  const locations = plant?.locations?.filter(hasValue) ?? [];

  const handleOnChange = (event: SelectChangeEvent<string>) => {
    if (!loading) {
      setSelectedLocation(locations.find((x) => x.id === event.target.value + ''));

      onChange(event.target.value);
    }
  };

  return (
    <GMDropdown
      onChange={handleOnChange}
      disabled={loading}
      value={selectedLocation ? selectedLocation.id : location?.id}
    >
      {locations.map((location) => (
        <MenuItem key={`${location.id}`} value={location.id}>
          {location.locationNumber}, {location.description}
        </MenuItem>
      ))}
    </GMDropdown>
  );
};

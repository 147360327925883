import { hasValue } from '@lego/mst-error-utilities';
import graphql from 'babel-plugin-relay/macro';
import { useContext } from 'react';
import { useFragment } from 'react-relay';
import { PREVENTIVE_PRIORITIES, YELLOW_FLOW_TYPES } from '../constants/ticketConstants';
import { Permissions } from './__apollo__/Permissions';
import {
  TicketFlowType,
  TicketPriority,
  TicketStatus,
  useTicketPermissions_canAddErrorDescription_ticket$key,
} from './__generated__/useTicketPermissions_canAddErrorDescription_ticket.graphql';
import { useTicketPermissions_isTicketEditable_equipment$key } from './__generated__/useTicketPermissions_isTicketEditable_equipment.graphql';
import { useTicketPermissions_ticket$key } from './__generated__/useTicketPermissions_ticket.graphql';
import { PermissionContext } from './permission-context';

export const useTicketPermissions = (ticket: useTicketPermissions_ticket$key) => {
  const permissionContext = useContext(PermissionContext);
  const data = useFragment(
    graphql`
      fragment useTicketPermissions_ticket on Ticket {
        ...useTicketPermissions_canAddErrorDescription_ticket
      }
    `,
    ticket ?? null
  );

  return {
    canAddErrorDescription: canAddErrorDescription(data, permissionContext),
  };
};

const canAddErrorDescription = (
  ticket: useTicketPermissions_canAddErrorDescription_ticket$key | null,
  permissionContext: Permissions
): boolean => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useFragment(
    graphql`
      fragment useTicketPermissions_canAddErrorDescription_ticket on Ticket {
        status
        priority
        equipment {
          ...useTicketPermissions_isTicketEditable_equipment
        }
        ... on MouldTicket {
          flowType
        }
      }
    `,
    ticket ?? null
  );

  // This must be called unconditionally as it contains a hook
  const isEditable = isTicketEditable(data?.equipment);

  return (
    permissionContext.isAllowedAddErrorDescription &&
    isTicketOpen(data?.status) &&
    isEditable &&
    !isTicketPreventive(data?.priority) &&
    !isYellowFlowType(data?.flowType)
  );
};

const isTicketOpen = (ticketStatus: TicketStatus | null | undefined): boolean => {
  if (!hasValue(ticketStatus)) {
    return false;
  }
  return ticketStatus === 'NotStarted' || ticketStatus === 'InProgress' || ticketStatus === 'Released';
};

const isTicketEditable = (
  equipment: useTicketPermissions_isTicketEditable_equipment$key | null | undefined
): boolean => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useFragment(
    graphql`
      fragment useTicketPermissions_isTicketEditable_equipment on Equipment {
        isActive
      }
    `,
    equipment ?? null
  );
  if (!hasValue(data) || !data.isActive) {
    return false;
  }

  return true;
};

const isTicketPreventive = (ticketPriority: TicketPriority | null | undefined): boolean => {
  if (!hasValue(ticketPriority)) {
    return false;
  }
  return PREVENTIVE_PRIORITIES.includes(ticketPriority);
};

const isYellowFlowType = (flowType: TicketFlowType | null | undefined): boolean => {
  if (!hasValue(flowType)) {
    return false;
  }
  return YELLOW_FLOW_TYPES.includes(flowType);
};

import { Skeleton } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { TextWithLabel } from '../../../components/shared/TextWithLabel';
import { useTranslation } from '../../../utility/i18n/translation';
import { skeletonify } from '../../skeleton';
import { MouldStandardPartsPerHour_mould$key } from './__generated__/MouldStandardPartsPerHour_mould.graphql';

const ActualComponent: FC<{
  mould: MouldStandardPartsPerHour_mould$key;
}> = ({ mould: mouldRef }) => {
  const { translate } = useTranslation();
  const data = useFragment(
    graphql`
      fragment MouldStandardPartsPerHour_mould on Mould {
        standardPartsPerHour
      }
    `,
    mouldRef
  );

  return (
    <TextWithLabel
      text={data.standardPartsPerHour?.toString() || '---'}
      label={translate('TICKET_DETAILS.SISTER_MOULDS_TAB.SPPH', 'SPPH')}
    />
  );
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

export const MouldStandardPartsPerHour = skeletonify('MouldStandardPartsPerHour', ActualComponent, SkeletonComponent);

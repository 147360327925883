import { ApolloError, gql } from '@apollo/client';
import { Grid, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import { useGMQuery } from '../../apollo/customApolloHooks';
import { useAreaAndProcessContext } from '../../contexts/area';
import {
  FILTER_COUNTS,
  useTicketListSearchContext,
} from '../../contexts/ticket-list-search/ticket-list-search-context';
import { useDefaultSnackbarErrorHandler } from '../../utility/errorSnackbar/errorCodeTranslations';
import { Figures } from '../../utility/figures';
import { useTranslation } from '../../utility/i18n/translation';
import { FillWidthLoading } from '../shared/FillWidthLoading';
import { TicketListEmptyView } from '../shared/TicketListEmptyView';
import { TicketCard, TICKET_CARD } from '../ticket-card/compositions/TicketCard';
import { ALL_PRIORITIES, mapStringPriorityToTicketPriorityEnum } from './TicketListSearchPriorities';
import { TicketListQuery, TicketListQueryVariables } from './__apollo__/TicketListQuery';

const TOO_BROAD_MESSAGE = 'maximum clause limit of';

const TICKET_LIST_QUERY = gql`
  query TicketListQuery($after: String, $input: TicketListConnectionQueryInput!) {
    ticketList(first: 20, input: $input, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      ...FilterCountsFragment
      edges {
        cursor
        node {
          id
          ...TicketCardFragment
        }
      }
    }
  }
  ${TICKET_CARD}
  ${FILTER_COUNTS}
`;

const useTicketListQuery = () => {
  const {
    state: { priorities, ticketStatuses, searchTerm, locationFilter, subLocationFilter },
  } = useTicketListSearchContext();
  const { showErrorSnackForStatusCode } = useDefaultSnackbarErrorHandler();

  const { selectedProcessId } = useAreaAndProcessContext();
  const variables: TicketListQueryVariables = {
    input: {
      processId: selectedProcessId,
      status: ticketStatuses.size > 0 ? [...ticketStatuses] : null,
      priorities: priorities.length > 0 ? mapStringPriorityToTicketPriorityEnum(priorities) : ALL_PRIORITIES,
      searchTerm,
      locationId: locationFilter?.id ?? undefined,
      sublocationId: subLocationFilter?.id ?? undefined,
    },
  };

  return useGMQuery<TicketListQuery, TicketListQueryVariables>(TICKET_LIST_QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
    onError: (e) => {
      if (!searchOverloadError(e)) {
        showErrorSnackForStatusCode(e);
      }
    },
  });
};

export const TicketList: FC = () => {
  const { data, loading, fetchMore, error } = useTicketListQuery();
  const { dispatch } = useTicketListSearchContext();
  const { translate } = useTranslation();
  const {
    palette: {
      error: { main },
    },
  } = useTheme();

  const handleOnEndReached = useCallback(() => {
    if (data && data.ticketList.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          after: data.ticketList.pageInfo.endCursor,
        },
      });
    }
  }, [data, fetchMore]);

  useEffect(() => {
    if (!loading) {
      dispatch({
        type: 'setFilterCounts',
        filterCounts: {
          priority: data?.ticketList.priorityCounts ?? [],
          status: data?.ticketList.statusCounts ?? [],
        },
      });
    }
  }, [data, dispatch, loading]);

  useEffect(() => {
    dispatch({
      type: 'setTotalSearchResults',
      results: data?.ticketList.totalCount ?? undefined,
    });
  }, [data?.ticketList.totalCount, dispatch]);

  if (!loading && data?.ticketList.edges.length === 0) {
    return <TicketListEmptyView />;
  }

  if (!loading && data?.ticketList.edges.length === 0) {
    return <TicketListEmptyView />;
  }

  if (!loading && error && searchOverloadError(error)) {
    return (
      <Grid container direction="column" alignItems="center">
        <Typography color={main}>
          {translate('TICKET_LIST.TOO_BROAD_SEARCH', 'Search term is too broad, please narrow your search further')}
        </Typography>
        <Figures.SpaceMan style={{ fontSize: 400 }} />
      </Grid>
    );
  }

  return (
    <Grid container flexDirection="column" spacing={1}>
      {data?.ticketList.edges.map((ticket) => {
        return (
          <Grid item key={ticket.cursor} sx={{ width: '100%' }}>
            <TicketCard data={ticket.node} showAssignedIconIfAssigned={true} />
            {data.ticketList.pageInfo?.hasNextPage && ticket.cursor === data.ticketList.pageInfo?.endCursor && (
              <Waypoint key={ticket.cursor} onEnter={handleOnEndReached} />
            )}
          </Grid>
        );
      })}
      <ListLoadingFooter loading={loading} />
    </Grid>
  );
};

export const ListLoadingFooter: FC<{ loading: boolean }> = ({ loading }) => {
  return loading ? (
    <Grid item xs={12}>
      <FillWidthLoading />
    </Grid>
  ) : (
    <Grid item xs={12} sx={{ minHeight: 40 }} />
  );
};

const searchOverloadError = (e: ApolloError): boolean => {
  return e.graphQLErrors.some((x) => x.message.includes(TOO_BROAD_MESSAGE));
};

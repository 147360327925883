import { Box } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useEffect, useMemo } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { useParams } from 'react-router';
import { RouteTypes } from '../../Router';
import { TDCostsTab } from '../../components/ticket-details/TDCostsTab';
import { TDDocumentsTab } from '../../components/ticket-details/TDDocumentsTab';
import { NotFoundPage } from '../NotFoundPage';
import { ErrorPage, PageErrorBoundary } from '../PageErrorBoundary';
import { EquipmentHistoryTab } from '../history-tab/EquipmentHistoryTab';
import { TicketMtsLogTab } from '../mts-log-tab/TicketMtsLogTab';
import { OpenTicketsTabContainer } from '../open-tickets-tab/OpenTicketsTabContainer';
import { QADetailsPane } from '../qa-tab/QADetailsPane';
import { SisterMouldsTabList } from '../sister-moulds-tab/SisterMouldsTabList';
import { skeletonify } from '../skeleton';
import { SparePartsTabContainer } from '../spareparts-tab/SparePartsTabContainer';
import { SparePartsTabWithBom } from '../spareparts-tab/SparePartsTabWithBom';
import { TicketDetailsHeader } from './TicketDetailsHeader';
import { TicketDetailsPane, useApolloTicketDetailsQuery } from './TicketDetailsPane';
import TicketDetailsQuery, {
  TicketDetailsQuery$data,
  TicketDetailsQuery as TicketDetailsQueryType,
} from './__generated__/TicketDetailsQuery.graphql';

export const TicketDetails: FC = () => {
  const { id } = useParams() as RouteTypes['ticket'];

  const [queryRef, loadQuery] = useQueryLoader<TicketDetailsQueryType>(TicketDetailsQuery);

  const input = useMemo(() => {
    return { input: { ticketNumber: Number.parseInt(id) } };
  }, [id]);

  useEffect(() => loadQuery(input, { fetchPolicy: 'store-and-network' }), [loadQuery, input]);

  // Preload Apollo query as well until fully migrated
  useApolloTicketDetailsQuery(id);

  return (
    <PageErrorBoundary onReset={() => loadQuery(input)}>
      {queryRef ? (
        <TicketDetailsContainer.Suspense
          query={queryRef}
          onUpdate={() => {
            loadQuery(input, { fetchPolicy: 'store-and-network' });
          }}
        />
      ) : (
        <TicketDetailsContainer.Skeleton />
      )}
    </PageErrorBoundary>
  );
};

const ActivePane: FC<{
  tab: RouteTypes['ticket']['tab'];
  data: TicketDetailsQuery$data;
  equipmentNumber?: number;
}> = ({ tab, data, equipmentNumber }) => {
  if (data.ticket.__typename !== 'QueryTicketSuccess') {
    return null;
  }
  const mould = data.ticket.data?.equipment;
  switch (tab) {
    case 'details':
      return <TicketDetailsPane.Suspense ticket={data.ticket.data} />;
    case 'history':
      return <EquipmentHistoryTab />;
    case 'costs':
      return <TDCostsTab />;
    case 'documents':
      return <TDDocumentsTab />;
    case 'open-tickets':
      return <OpenTicketsTabContainer />;
    case 'mts-log':
      return <TicketMtsLogTab />;
    case 'sister-moulds':
      return mould?.__typename === 'Mould' ? <SisterMouldsTabList mouldId={mould.id} /> : <ErrorPage />;
    case 'spareparts':
      if (data.ticket.data?.__typename === 'CMSTicket') {
        return <SparePartsTabContainer marginTop={0} />;
      }
      return <SparePartsTabWithBom equipmentNumber={equipmentNumber} />;
    case 'qa':
      return <QADetailsPane.Suspense equipment={mould ?? undefined} />;
  }
};

const ActualComponent = (props: { query: PreloadedQuery<TicketDetailsQueryType>; onUpdate: () => void }) => {
  const { query: queryRef, onUpdate } = props;
  const data = usePreloadedQuery(
    graphql`
      query TicketDetailsQuery($input: QueryTicketInput!) {
        ticket(input: $input) {
          __typename
          ... on Error {
            message
          }
          ... on NotFoundError {
            id
          }
          ... on QueryTicketSuccess {
            data {
              status
              __typename
              equipment {
                __typename
                id
                equipmentNumber
                ... on Mould {
                  ...QADetailsPane_equipment #@defer
                }
              }
              ...TicketDetailsHeaderFragment
              ...TicketDetailsPane_ticket #@defer
            }
          }
        }
      }
    `,
    queryRef
  );

  const { tab } = useParams<RouteTypes['ticket']>() as RouteTypes['ticket'];
  if (data.ticket.__typename === 'NotFoundError') {
    return {
      header: <div />,
      pane: <NotFoundPage containerStyleOverrides={{ height: '105%', mt: -2 }} />,
    };
  }

  if (data.ticket.__typename !== 'QueryTicketSuccess') {
    // Gets caught with PageErrorBoundary
    throw new Error(data.ticket.message ?? 'Unknown ticket return type');
  }

  return {
    header: (
      <TicketDetailsHeader
        onUpdate={onUpdate}
        ticket={data.ticket.data}
        showFab={data.ticket.data?.status !== 'Closed'}
      />
    ),
    pane: <ActivePane tab={tab} data={data} equipmentNumber={data.ticket.data?.equipment?.equipmentNumber} />,
  };
};

const SkeletonComponent = () => ({
  header: <TicketDetailsHeader.Skeleton />,
  pane: <TicketDetailsPane.Skeleton />,
});

const StructureComponent = ({ header, pane }: { header: JSX.Element; pane: JSX.Element }) => {
  return (
    <Box display="flex" height="100%" flexDirection="column">
      {header}
      <Box flex={1} overflow="scroll" pt={2}>
        {pane}
      </Box>
    </Box>
  );
};

const TicketDetailsContainer = skeletonify('TicketDetailsPage', ActualComponent, SkeletonComponent, StructureComponent);

import { hasValue } from '@lego/mst-error-utilities';
import { Button, Card, CardContent, Collapse, Grid, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback, useMemo } from 'react';
import { useFragment } from 'react-relay';
import { TransitionGroup } from 'react-transition-group';
import { getTimestampForRelay } from '../../utility/date';
import { useTranslation } from '../../utility/i18n/translation';
import { TicketFilterChip, TicketFilterChipProps } from '../ticket-search/TicketSearchFilters';
import { EquipmentHistoryDateFilter } from './EquipmentHistoryDateFilter';
import { EquipmentHistoryPriorityFilter } from './EquipmentHistoryPriorityFilter';
import { EquipmentHistoryDamageFilter } from './EquipmentHistoryDamageFilter';
import { EquipmentHistoryFilters_ticketsConnection$key } from './__generated__/EquipmentHistoryFilters_ticketsConnection.graphql';
import {
  EquipmentTicketsInput,
  LocalTimestampInput,
  TicketsPriorityInput,
} from './__generated__/EquipmentHistoryTabQuery.graphql';

export interface EquipmentHistoryFilters {
  priority?: Array<TicketsPriorityInput>;
  completedBeforeDate?: LocalTimestampInput;
  completedAfterDate?: LocalTimestampInput;
}

export const EquipmentHistoryFilters: FC<{
  ticketsConnection: EquipmentHistoryFilters_ticketsConnection$key | null;
  hideDamageFilter?: boolean;
  filters: EquipmentTicketsInput;
  onChange: (filters: EquipmentTicketsInput) => void;
}> = ({ ticketsConnection: connectionRef, hideDamageFilter, filters, onChange }) => {
  const connection = useFragment(
    graphql`
      fragment EquipmentHistoryFilters_ticketsConnection on TicketsConnection {
        facets {
          ...EquipmentHistoryPriorityFilter_ticketsConnectionFacets
          ...EquipmentHistoryDamageFilter_ticketsConnectionFacets
        }
      }
    `,
    connectionRef
  );

  const { translate } = useTranslation();

  const handleClearFilters = useCallback(() => {
    onChange({});
  }, [onChange]);

  const handlePriorityChanged = useCallback(
    (priorities: TicketsPriorityInput[]) => {
      onChange({ ...filters, priority: priorities });
    },
    [filters, onChange]
  );

  const handleDamageChanged = useCallback(
    (damageCatalogId: string | undefined) => {
      onChange({ ...filters, damage: damageCatalogId });
    },
    [filters, onChange]
  );

  const handleCompletedBeforeDateChanged = useCallback(
    (completedBefore: Date | null) => {
      onChange({
        ...filters,
        completedDate: {
          ...filters.completedDate,
          le: completedBefore ? getTimestampForRelay(completedBefore?.getTime()) : undefined,
        },
      });
    },
    [filters, onChange]
  );
  const handleCompletedAfterDateChanged = useCallback(
    (completedAfter: Date | null) => {
      onChange({
        ...filters,
        completedDate: {
          ...filters.completedDate,
          ge: completedAfter ? getTimestampForRelay(completedAfter?.getTime()) : undefined,
        },
      });
    },
    [filters, onChange]
  );

  const filtersList = useMemo<TicketFilterChipProps[]>(() => {
    const priorities =
      filters.priority?.map((priority) => ({
        type: 'priority' as const,
        value: priority,
      })) ?? [];
    const completedAfterDate = filters.completedDate?.ge
      ? [
          {
            type: 'completedAfterDate' as const,
            value: filters.completedDate?.ge,
          },
        ]
      : [];
    const completedBeforeDate = filters.completedDate?.le
      ? [
          {
            type: 'completedBeforeDate' as const,
            value: filters.completedDate?.le,
          },
        ]
      : [];

    return [...priorities, ...completedAfterDate, ...completedBeforeDate];
  }, [filters.completedDate, filters.priority]);

  const handleFilterDelete = (filter: TicketFilterChipProps) => () => {
    const updatedFilters = { ...filters };
    switch (filter.type) {
      case 'priority':
        updatedFilters.priority = updatedFilters.priority?.filter((value) => value !== filter.value);
        break;
      case 'completedAfterDate':
        if (hasValue(updatedFilters.completedDate)) {
          updatedFilters.completedDate = {
            ...updatedFilters.completedDate,
            ge: undefined,
          };
        }
        break;
      case 'completedBeforeDate':
        if (hasValue(updatedFilters.completedDate)) {
          updatedFilters.completedDate = {
            ...updatedFilters.completedDate,
            le: undefined,
          };
        }
        break;
    }

    onChange(updatedFilters);
  };

  return (
    <Card sx={{ p: 0 }}>
      <CardContent>
        <Grid container spacing={1} flexDirection="column">
          <Grid item container flexDirection="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="subtitle1">{translate('TICKET_LIST.FILTERS.CHIP_TITLE', 'FILTERS')}</Typography>
            </Grid>
            <Grid item>
              <Button onClick={handleClearFilters} variant="text" disabled={filtersList.length === 0}>
                {translate('TICKET_LIST.FILTERS.CLEAR_ALL', 'Clear all')}
              </Button>
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <TransitionGroup component={null}>
              {filtersList.map((filter, index) => (
                <Grid item component={Collapse} key={`${filter.type}:${filter.value}:${index}`}>
                  <TicketFilterChip {...filter} onDelete={handleFilterDelete(filter)} />
                </Grid>
              ))}
            </TransitionGroup>
          </Grid>

          <Grid item>
            <EquipmentHistoryDateFilter
              completedAfterDate={filters.completedDate?.ge ?? null}
              completedBeforeDate={filters.completedDate?.le ?? null}
              onCompletedAfterDateChanged={handleCompletedAfterDateChanged}
              onCompletedBeforeDateChanged={handleCompletedBeforeDateChanged}
            />
          </Grid>
          <Grid item>
            <EquipmentHistoryPriorityFilter
              ticketsConnectionFacets={connection?.facets ?? null}
              selectedPriorities={filters.priority?.slice() ?? []}
              onChange={handlePriorityChanged}
            />
          </Grid>
          {hideDamageFilter ? null : (
            <Grid item>
              <EquipmentHistoryDamageFilter
                ticketsConnectionFacets={connection?.facets ?? null}
                selectedDamageCatalogId={filters.damage ?? undefined}
                onChange={handleDamageChanged}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

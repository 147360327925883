import { hasValue } from '@lego/mst-error-utilities';
import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback } from 'react';
import { useFragment, useMutation } from 'react-relay';
import { SimpleDialog } from '../../../components/shared/SimpleDialog';
import { getTimestampForRelay } from '../../../utility/date';
import { useTranslation } from '../../../utility/i18n/translation';
import { useGMSnackbar } from '../../../utility/snackbar';
import { MouldMiniOrderDialogMutation } from './__generated__/MouldMiniOrderDialogMutation.graphql';
import { MouldMiniOrderDialog_mould$key } from './__generated__/MouldMiniOrderDialog_mould.graphql';

export const MouldMiniOrderDialog: FC<{
  open: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  equipment: MouldMiniOrderDialog_mould$key;
}> = ({ open = false, onConfirm, onDismiss, equipment: equipmentRef }) => {
  const { translate } = useTranslation();
  const { showSnack } = useGMSnackbar();

  const { id, previousStatusCode } = useFragment(
    graphql`
      fragment MouldMiniOrderDialog_mould on Mould {
        id
        previousStatusCode
      }
    `,
    equipmentRef
  );

  const [commit, isInFlight] = useMutation<MouldMiniOrderDialogMutation>(
    graphql`
      mutation MouldMiniOrderDialogMutation($input: MutationMouldUpdateStatusCodeInput!) {
        mouldUpdateStatusCode(input: $input) {
          ... on MutationMouldUpdateStatusCodeSuccess {
            data {
              ...MouldMiniOrderDialog_mould
            }
          }
        }
      }
    `
  );

  const handleConfirm = useCallback(() => {
    if (hasValue(previousStatusCode)) {
      commit({
        variables: {
          input: {
            mouldId: id,
            statusCode: previousStatusCode,
            updatedDate: getTimestampForRelay(),
          },
        },
        onCompleted: () => onConfirm(),
      });
    } else {
      showSnack({
        message: translate(
          'CREATE_TICKET.MINI_ORDER_DIALOG.MISSING_PREVIOUS_STATUS_SNACK',
          'Missing previous status code. Please contact a specialist.'
        ),
        variant: 'warning',
      });
      onDismiss();
    }
  }, [commit, id, onConfirm, onDismiss, previousStatusCode, showSnack, translate]);

  const handleDismiss = useCallback(() => {
    if (isInFlight) {
      return;
    }
    onDismiss();
  }, [isInFlight, onDismiss]);

  return (
    <SimpleDialog
      onDismiss={handleDismiss}
      open={open}
      title={translate('CREATE_TICKET.MINI_ORDER_DIALOG.TITLE', 'Change mini order mould')}
      content={{
        type: 'text',
        body: translate(
          'CREATE_TICKET.MINI_ORDER_DIALOG.BODY',
          'This mould is running mini orders.\nYou cannot create tickets for mini order moulds.\nDo you want to change the mould status code back to {{statusCode}}?',
          { statusCode: previousStatusCode }
        ),
      }}
      primaryAction={{
        primaryActionLabel: translate('CREATE_TICKET.MINI_ORDER_DIALOG.PRIMARY_ACTION', 'Yes'),
        primaryActionPressed: handleConfirm,
        primaryActionLoading: isInFlight,
      }}
      secondaryAction={{
        secondaryActionLabel: translate('CREATE_TICKET.MINI_ORDER_DIALOG.SECONDARY_ACTION', 'No'),
        secondaryActionPressed: handleDismiss,
      }}
    />
  );
};

import { hasValue } from '@lego/mst-error-utilities';
import graphql from 'babel-plugin-relay/macro';
import { commitMutation, useMutation } from 'react-relay';
import { useErrorSnackbar } from '../../utility/errorSnackbar/useErrorSnackbar';
import { CmsMutationsEquipmentRepairMutation } from './__generated__/CmsMutationsEquipmentRepairMutation.graphql';
import { CmsMutationsSparepartManufacturingMutation } from './__generated__/CmsMutationsSparepartManufacturingMutation.graphql';
import { CmsMutationsSparepartRepairMutation } from './__generated__/CmsMutationsSparepartRepairMutation.graphql';

export const useCmsCreateEquipmentRepairTicketMutation = () => {
  const enqueueErrorSnack = useErrorSnackbar();

  return useMutation<CmsMutationsEquipmentRepairMutation>(
    graphql`
      mutation CmsMutationsEquipmentRepairMutation($input: MutationCmsCreateEquipmentRepairTicketInput!) {
        result: cmsCreateEquipmentRepairTicket(input: $input) {
          ... on MutationCmsCreateEquipmentRepairTicketSuccess {
            data {
              ticketNumber
            }
          }
          ... on Error {
            errorMessage: message
          }
        }
      }
    `,
    (env, config) =>
      commitMutation<CmsMutationsEquipmentRepairMutation>(env, {
        ...config,
        onCompleted(response, errors) {
          config.onCompleted?.(response, errors);
          const errorMessage = response.result?.errorMessage;
          if (hasValue(errorMessage)) {
            enqueueErrorSnack([errorMessage]);
          }
        },
      })
  );
};

export const useCmsCreateSparepartRepairTicketMutation = () => {
  const enqueueErrorSnack = useErrorSnackbar();

  return useMutation<CmsMutationsSparepartRepairMutation>(
    graphql`
      mutation CmsMutationsSparepartRepairMutation($input: MutationCmsCreateSparepartRepairTicketInput!) {
        result: cmsCreateSparepartRepairTicket(input: $input) {
          ... on MutationCmsCreateSparepartRepairTicketSuccess {
            data {
              ticketNumber
            }
          }
          ... on Error {
            errorMessage: message
          }
        }
      }
    `,
    (env, config) =>
      commitMutation<CmsMutationsSparepartRepairMutation>(env, {
        ...config,
        onCompleted(response, errors) {
          config.onCompleted?.(response, errors);
          const errorMessage = response.result?.errorMessage;
          if (hasValue(errorMessage)) {
            enqueueErrorSnack([errorMessage]);
          }
        },
      })
  );
};

export const useCmsCreateSparepartManufacturingTicketMutation = () => {
  const enqueueErrorSnack = useErrorSnackbar();

  return useMutation<CmsMutationsSparepartManufacturingMutation>(
    graphql`
      mutation CmsMutationsSparepartManufacturingMutation($input: MutationCmsCreateSparepartManufacturingTicketInput!) {
        result: cmsCreateSparepartManufacturingTicket(input: $input) {
          ... on MutationCmsCreateSparepartManufacturingTicketSuccess {
            data {
              ticketNumber
            }
          }
          ... on Error {
            errorMessage: message
          }
        }
      }
    `,
    (env, config) =>
      commitMutation<CmsMutationsSparepartManufacturingMutation>(env, {
        ...config,
        onCompleted(response, errors) {
          config.onCompleted?.(response, errors);
          const errorMessage = response.result?.errorMessage;
          if (hasValue(errorMessage)) {
            enqueueErrorSnack([errorMessage]);
          }
        },
      })
  );
};

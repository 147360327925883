import { hasValue } from '@lego/mst-error-utilities';
import { Grid, TextField } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { ChangeEvent, FC, useState } from 'react';
import { useFragment, useMutation } from 'react-relay';
import { useNavigate } from 'react-router';
import { useAuthContext } from '../../contexts/AuthContext';
import { AppRoutes } from '../../Router';
import { getTimestampForRelay } from '../../utility/date';
import { useTranslation } from '../../utility/i18n/translation';
import { useGMSnackbar } from '../../utility/snackbar';
import { ErrorMessage } from '../components/ErrorMessage';
import { useCreateTicketContext } from './create-ticket-context';
import { CreateTicketDescriptionToggles } from './CreateTicketDescriptionToggles';
import { CreateTicketSubmitButtons } from './CreateTicketSubmitButtons';
import { CreateTicketDescriptionStepMutation } from './__generated__/CreateTicketDescriptionStepMutation.graphql';
import { CreateTicketDescriptionStep_equipment$key } from './__generated__/CreateTicketDescriptionStep_equipment.graphql';

interface Props {
  equipment: CreateTicketDescriptionStep_equipment$key | null | undefined;
}

export const CreateTicketDescriptionStep: FC<Props> = ({ equipment: equipmentRef }) => {
  const { state, dispatch } = useCreateTicketContext();
  const { translate } = useTranslation();
  const { currentStep } = state;
  const { globalUserId } = useAuthContext();
  const [error, setError] = useState<string | undefined>();
  const navigate = useNavigate();
  const { showSnack } = useGMSnackbar();

  const handleTextChanged = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setError(undefined);
    dispatch({ type: 'set_description', payload: event.target.value });
  };

  const equipment = useFragment(
    graphql`
      fragment CreateTicketDescriptionStep_equipment on Equipment {
        ...CreateTicketDescriptionToggles_equipment
      }
    `,
    equipmentRef ?? null
  );

  const [commit, isInFlight] = useMutation<CreateTicketDescriptionStepMutation>(graphql`
    mutation CreateTicketDescriptionStepMutation($input: MutationCreateTicketInput!) {
      createTicket(input: $input) {
        ... on MutationCreateTicketSuccess {
          data {
            id
            ticketNumber
          }
        }
      }
    }
  `);

  const handleSubmit = (assign: boolean) => {
    if (!hasValue(state.description) || state.description.trim().length === 0) {
      setError(translate('CREATE_TICKET.ERROR.DESCRIPTION_NOT_SET', 'Description is required'));
      return null;
    }

    if (hasValue(state.equipmentId) && hasValue(state.priority)) {
      commit({
        variables: {
          input: {
            assignedTo: assign ? globalUserId : null,
            createdDate: getTimestampForRelay(),
            description: state.description,
            equipmentId: state.equipmentId,
            isChangeOverIssue: state.errorDuringChangeOver,
            priority: state.priority,
            isRecurring: state.errorAfterRepair,
            requiredEndDate: state.requiredEndDate ? getTimestampForRelay(state.requiredEndDate.getTime()) : undefined,
          },
        },
        onCompleted: (response) => {
          if (response.createTicket?.data?.id) {
            const ticketNumber = response.createTicket?.data?.ticketNumber ?? '';
            showSnack({
              message: translate('CREATE_TICKET.SUCCESS_SNACK', 'New ticket {{ticketNumber}} created', {
                ticketNumber,
              }),
              variant: 'success',
            });

            if (assign && hasValue(response.createTicket?.data?.ticketNumber)) {
              navigate(AppRoutes.tickets.byTicketNumber(response.createTicket.data.ticketNumber, 'details'), {
                replace: true,
              });
            } else {
              navigate(-1);
            }
          }
        },
      });
    }
  };

  return (
    <Grid container spacing={1} direction="column">
      {currentStep === 'description' && (
        <>
          <Grid item>
            <TextField
              data-cy="CreateTicketDescriptionInput"
              multiline={true}
              style={{ width: '100%' }}
              minRows={3}
              onChange={handleTextChanged}
              placeholder={translate('CREATE_TICKET.DESCRIPTION.PLACEHOLDER', 'Problem description')}
            />
          </Grid>
          <Grid item mt={1}>
            <CreateTicketDescriptionToggles equipment={equipment} />
          </Grid>
          {error && (
            <Grid item xs={12} mb={1}>
              <ErrorMessage message={error} />
            </Grid>
          )}
          <Grid item mt={2} mb={2}>
            <CreateTicketSubmitButtons submit={handleSubmit} loading={isInFlight} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

import { hasValue } from '@lego/mst-error-utilities';
import { Grid, Skeleton } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, ReactElement } from 'react';
import { useFragment } from 'react-relay';
import { CardWithTitle, CardWithTitleSkeleton } from '../../../components/shared/CardWithTitle';
import { TextWithLabel } from '../../../components/shared/TextWithLabel';
import { useTranslation } from '../../../utility/i18n/translation';
import { Icons } from '../../../utility/icons';
import { skeletonify } from '../../skeleton';
import { TDMouldDetails_equipment$key } from './__generated__/TDMouldDetails_equipment.graphql';

const iconSize = 32;

const ActualComponent: FC<{
  equipment: TDMouldDetails_equipment$key;
}> = ({ equipment: equipmentRef }) => {
  const { translate } = useTranslation();

  const { tonSize, type, weight } = useFragment(
    graphql`
      fragment TDMouldDetails_equipment on Equipment {
        ... on Mould {
          weight {
            unitOfMeasurement
            value
          }
          type
          tonSize
        }
      }
    `,
    equipmentRef
  );

  return (
    <CardWithTitle title={translate('TICKET_DETAILS.CARDS.MOULD_DETAILS.TITLE', 'Mould Details')}>
      <Grid container direction="row">
        {hasValue(weight) && (
          <Grid item xs={4}>
            <MouldDetailsItem
              icon={<Icons.WeightInKg style={{ fontSize: iconSize }} />}
              text={`${weight.value} ${weight.unitOfMeasurement}`}
              label={translate('TICKET_DETAILS.CARDS.MOULD_DETAILS.LABELS.WEIGHT', 'Weight')}
            />
          </Grid>
        )}
        {hasValue(type) && (
          <Grid item xs={4}>
            <MouldDetailsItem
              icon={<Icons.MouldType style={{ fontSize: iconSize }} />}
              text={type}
              label={translate('TICKET_DETAILS.CARDS.MOULD_DETAILS.LABELS.TYPE', 'Mould type')}
            />
          </Grid>
        )}
        {hasValue(tonSize) && (
          <Grid item xs={4}>
            <MouldDetailsItem
              icon={<Icons.WeightScales style={{ fontSize: iconSize }} />}
              text={tonSize}
              label={translate('TICKET_DETAILS.CARDS.MOULD_DETAILS.LABELS.TON_SIZE', 'Ton size')}
            />
          </Grid>
        )}
      </Grid>
    </CardWithTitle>
  );
};

const SkeletonComponent = () => {
  return <MouldDetailsSkeleton />;
};

export const TDMouldDetails = skeletonify('TDMouldDetails', ActualComponent, SkeletonComponent);

const MouldDetailsItem: FC<{
  icon: ReactElement;
  text: string;
  label: string;
}> = ({ icon, ...rest }) => {
  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid
        item
        sx={{
          mr: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {icon}
      </Grid>
      <Grid item>
        <TextWithLabel {...rest} />
      </Grid>
    </Grid>
  );
};

const MouldDetailsSkeleton: FC = () => {
  return (
    <CardWithTitleSkeleton>
      <Grid container direction="row" spacing={1}>
        <MouldDetailsItemSkeleton />
        <MouldDetailsItemSkeleton />
        <MouldDetailsItemSkeleton />
      </Grid>
    </CardWithTitleSkeleton>
  );
};

const skeletonTextWidth = 80;
const skeletonIconSize = 50;
const MouldDetailsItemSkeleton: FC = () => {
  return (
    <Grid item xs={4}>
      <Grid container direction="row" alignItems="center">
        <Grid item sx={{ mr: 1 }}>
          <Skeleton sx={{ borderRadius: 1 }} variant="rectangular" height={skeletonIconSize} width={skeletonIconSize} />
        </Grid>
        <Grid item>
          <Grid item>
            <Skeleton variant="text" width={skeletonTextWidth} />
          </Grid>
          <Grid item>
            <Skeleton variant="text" width={skeletonTextWidth} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

import { hasValue } from '@lego/mst-error-utilities';
import { Grid, Paper, Tab, Tabs } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, SyntheticEvent, useCallback } from 'react';
import { useFragment } from 'react-relay';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes, RouteTypes } from '../../Router';
import { TDHeaderSkeleton } from '../../components/ticket-details/TDHeader';
import { TDMenu } from '../../components/ticket-details/TDMenu';
import { useTranslation } from '../../utility/i18n/translation';
import { Icons } from '../../utility/icons';
import { EquipmentDescription } from '../components/equipment/EquipmentDescription';
import { EquipmentNumber } from '../components/equipment/EquipmentNumber';
import { PriorityPill } from '../components/ticket/PriorityPill';
import { RequiredDates } from '../components/ticket/RequiredDates';
import { TicketNumber } from '../components/ticket/TicketNumber';
import { skeletonify } from '../skeleton';
import { useApolloTicketDetailsQuery } from './TicketDetailsPane';
import { TicketDetailsHeaderFragment$key } from './__generated__/TicketDetailsHeaderFragment.graphql';
import { TDMouldFlowTypeBanner } from './widgets/TDMouldFlowTypeBanner';

type HeaderProps = {
  showFab?: boolean;
};

const GUTTERS = '10%';

const ActualComponent: FC<
  {
    ticket?: TicketDetailsHeaderFragment$key;
    onUpdate: () => void;
  } & HeaderProps
> = (props) => {
  const { translate } = useTranslation();

  const { tab, id } = useParams() as RouteTypes['ticket'];
  const navigate = useNavigate();

  const handleChange = useCallback(
    (_: SyntheticEvent, newValue: RouteTypes['ticket']['tab']) => {
      navigate(AppRoutes.tickets.byTicketNumber(id, newValue), {
        replace: true,
      });
    },
    [id, navigate]
  );

  const { ticket: ticketRef } = props;
  const ticket = useFragment(
    graphql`
      fragment TicketDetailsHeaderFragment on Ticket {
        equipment {
          __typename
          ...EquipmentNumber_equipment
          ...EquipmentDescription_equipment
          ... on Mould {
            qualityManagementInspection {
              validationResult
            }
            ...TDMouldFlowTypeBanner_mould
          }
        }
        ...TDMenuFragment
        ...RequiredDates_ticket
        ...PriorityPill_ticket
        ...TicketNumber_ticket
      }
    `,
    ticketRef ?? null
  );

  const apolloData = useApolloTicketDetailsQuery(id);

  if (!ticket) {
    return null;
  }

  const isMould = ticket.equipment?.__typename === 'Mould';

  return (
    <>
      <Paper style={{ borderRadius: 0, width: '100%', marginBottom: 1 }} data-cy="TDHeader">
        <Grid container direction="row" alignItems="center" style={{ paddingTop: 8, paddingBottom: 8 }}>
          <Grid container item justifyContent="flex-end" style={{ width: GUTTERS }}>
            <Grid item>
              <PriorityPill ticket={ticket} />
            </Grid>
          </Grid>
          <Grid item style={{ flex: 1, marginLeft: 36 }}>
            <Grid container spacing={2}>
              <Grid container spacing={2}>
                {ticket.equipment && (
                  <Grid item>
                    <EquipmentNumber equipment={ticket.equipment} />
                  </Grid>
                )}

                <Grid item>
                  <TicketNumber ticket={ticket} />
                </Grid>
              </Grid>
              {ticket.equipment && <EquipmentDescription equipment={ticket.equipment} />}
            </Grid>
          </Grid>
          <Grid item>
            <RequiredDates.Suspense ticket={ticket} />
          </Grid>
          <Grid
            item
            style={{
              width: GUTTERS,
              height: 0,
            }}
          >
            {props.showFab && hasValue(apolloData) && (
              <TDMenu apolloData={apolloData.data} ticket={ticket ?? undefined} onUpdate={props.onUpdate} />
            )}
          </Grid>
        </Grid>
        <Grid sx={{ ml: GUTTERS, pl: 1 }}>
          <Tabs value={tab} onChange={handleChange}>
            <Tab value={'details'} label={translate('TICKET_DETAILS.HEADER.TABS.DETAILS', 'Details')} id={'details'} />
            {ticket.equipment && (
              <Tab
                label={translate('TICKET_DETAILS.HEADER.TABS.HISTORY', 'History')}
                id={'history'}
                value={'history'}
              />
            )}
            <Tab label={translate('TICKET_DETAILS.HEADER.TABS.COSTS', 'Costs')} id={'costs'} value={'costs'} />
            {ticket.equipment && (
              <Tab
                label={translate('TICKET_DETAILS.HEADER.TABS.DOCUMENTS', 'Documents')}
                id={'documents'}
                value={'documents'}
              />
            )}
            {isMould ? (
              <Tab
                label={translate('TICKET_DETAILS.HEADER.TABS.MTS_LOG', 'MTS Log')}
                id={'mts-log'}
                value={'mts-log'}
              />
            ) : null}
            {isMould ? (
              <Tab
                label={translate('TICKET_DETAILS.HEADER.TABS.SISTER_MOULDS', 'Sister Moulds')}
                id={'sister-moulds'}
                value={'sister-moulds'}
              />
            ) : null}
            <Tab
              label={translate('TICKET_DETAILS.HEADER.TABS.SPAREPARTS', 'Spare parts')}
              id={'spareparts'}
              value={'spareparts'}
            />
            {ticket.equipment && (
              <Tab
                label={translate('TICKET_DETAILS.HEADER.TABS.OPEN_TICKETS', 'Open tickets')}
                id={'open-tickets'}
                value={'open-tickets'}
              />
            )}
            {isMould ? (
              <Tab
                label={translate('TICKET_DETAILS.HEADER.TABS.QA', 'QA')}
                id={'qa'}
                value={'qa'}
                iconPosition="end"
                icon={
                  ticket.equipment.qualityManagementInspection?.validationResult === 'R' ? (
                    <Icons.WarningTriangle color="error" />
                  ) : (
                    ''
                  )
                }
              />
            ) : null}
          </Tabs>
        </Grid>
      </Paper>
      {isMould && tab === 'details' && <TDMouldFlowTypeBanner mould={ticket.equipment} />}
    </>
  );
};

const SkeletonComponent: FC = () => {
  return <TDHeaderSkeleton />;
};

export const TicketDetailsHeader = skeletonify('TicketDetailsHeader', ActualComponent, SkeletonComponent);

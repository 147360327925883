import { Grid, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useBannerContext } from './banner-context';

const backgroundColor = '#3F3F3F';

export const InnerBanner: FC = () => {
  const { state } = useBannerContext();
  const { palette } = useTheme();

  if (!state.show || (state.show && !state.innerBanner)) {
    return null;
  }

  return (
    <Grid container sx={{ backgroundColor }} alignItems="center">
      <Grid sx={{ padding: 1.5, paddingBottom: 0.6, paddingLeft: 3 }}>
        <svg
          width="30"
          height="30"
          viewBox="0 0 32 32"
          fill={state.type === 'warning' ? palette.warning.main : palette.error.main}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M27.7585 24.677L17.8739 5.17109C17.5088 4.45111 16.7872 4 16.0005 4C15.2139 4 14.4923 4.45111 14.1271 5.17109L4.24146 24.677C3.89802 25.3545 3.92233 26.1683 4.30557 26.8227C4.6888 27.477 5.3752 27.8768 6.11538 27.8766H25.8846C26.6248 27.8768 27.3112 27.477 27.6944 26.8227C28.0777 26.1683 28.102 25.3545 27.7585 24.677Z" />
          <path
            d="M15.9776 10.9664C15.3935 10.9664 14.9201 11.4557 14.9201 12.0592V18.6158C14.9201 19.2193 15.3935 19.7086 15.9776 19.7086C16.5616 19.7086 17.0351 19.2193 17.0351 18.6158V12.0592C17.0351 11.4557 16.5616 10.9664 15.9776 10.9664ZM16.001 24.6369H16.0306C16.4505 24.6299 16.85 24.4482 17.1386 24.1329C17.4272 23.8176 17.5806 23.3954 17.564 22.9617C17.5299 22.0671 16.8187 21.3597 15.9523 21.3586H15.9227C15.5038 21.3665 15.1056 21.5481 14.8176 21.8626C14.5296 22.177 14.376 22.5979 14.3914 23.0306C14.4243 23.9254 15.1344 24.6341 16.001 24.6369Z"
            fill={backgroundColor}
          />
        </svg>
      </Grid>
      <Grid sx={{ marginLeft: 0.5 }}>
        <Typography variant="body2" color={palette.background.paper}>
          {state.text}
        </Typography>
      </Grid>
    </Grid>
  );
};

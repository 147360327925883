import { Grid, GridProps, StepIconProps, styled, Typography } from '@mui/material';
import { FC } from 'react';
import { Icons } from '../../utility/icons';
interface StyledGridProps extends GridProps {
  active?: boolean;
}

const StyledGridDynamic = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'active',
})<StyledGridProps>(({ active, theme }) => ({
  color: theme.palette.text.disabled,
  marginRight: 8,
  ...(active && {
    color: theme.palette.primary.main,
  }),
}));

const GridCircle = styled(Grid)(() => ({
  width: iconSize,
  height: iconSize,
  borderRadius: '50%',
  border: '2px solid currentColor',
}));

const iconSize = 32;

export const ARDStepIcon: FC<StepIconProps> = ({ active, completed, icon: step }) => {
  return (
    <StyledGridDynamic active={active}>
      {completed ? (
        <Grid
          container
          sx={{
            height: iconSize,
            width: iconSize,
            borderRadius: iconSize / 2,
            backgroundColor: 'primary.main',
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item style={{ height: 24 }}>
            <Icons.CheckmarkBold style={{ fontSize: 18, color: 'white' }} />
          </Grid>
        </Grid>
      ) : (
        <GridCircle container justifyContent="center" alignItems="center">
          <Grid item>
            <Typography>{step}</Typography>
          </Grid>
        </GridCircle>
      )}
    </StyledGridDynamic>
  );
};

import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback, useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { useParams } from 'react-router-dom';
import { withRelayEnvironment } from '../../../relay/RelayEnvironment';
import { useTranslation } from '../../../utility/i18n/translation';
import { useGMSnackbar } from '../../../utility/snackbar';
import { PageErrorBoundary } from '../../AutoResetPageErrorBoundary';
import { skeletonify } from '../../skeleton';
import { HallMonitorItem, HallMonitorItemContent } from './HallMonitorItem';
import HallMonitorContainerQuery, {
  HallMonitorContainerQuery as HallMonitorContainerQueryType,
} from './__generated__/HallMonitorContainerQuery.graphql';

const ActualComponent: FC<{
  query: PreloadedQuery<HallMonitorContainerQueryType>;
}> = (props) => {
  const { query: queryRef } = props;
  const { showSnack } = useGMSnackbar();
  const { translate } = useTranslation();
  const data = usePreloadedQuery(
    graphql`
      query HallMonitorContainerQuery($nodeId: ID!) {
        node(id: $nodeId) {
          __typename
          ... on HallMonitorScreen {
            ...HallMonitorItem_hallMonitorScreen
          }
        }
      }
    `,
    queryRef
  );

  if (data.node?.__typename !== 'HallMonitorScreen') {
    showSnack({
      message: translate('HALL_MONITOR.NODE_NOT_FOUND', 'Could not retrieve variant data'),
      variant: 'error',
    });
    return null;
  }

  return <HallMonitorItem hallMonitorScreen={data.node} useStaleData={false} />;
};

const SkeletonComponent: FC = () => <HallMonitorItem.Skeleton />;

const HallMonitorContainerPage = skeletonify('HallMonitorContainerPage', ActualComponent, SkeletonComponent);

const InternalErrorPage: FC<FallbackProps> = () => <HallMonitorItemContent useStaleData={true} />;

export const HallMonitorContainer: FC = withRelayEnvironment('none', () => {
  const { id } = useParams() as { id: string };

  const [queryRef, loadQuery, dispose] = useQueryLoader<HallMonitorContainerQueryType>(HallMonitorContainerQuery);

  const load = useCallback(() => {
    loadQuery({ nodeId: id }, { fetchPolicy: 'store-and-network' });
  }, [id, loadQuery]);

  const reload = useCallback(() => {
    loadQuery({ nodeId: id }, { fetchPolicy: 'network-only' });
  }, [id, loadQuery]);

  useEffect(() => {
    load();
  }, [dispose, load]);

  return (
    <main>
      <PageErrorBoundary onReset={() => reload()} fallbackComponent={InternalErrorPage}>
        {queryRef ? <HallMonitorContainerPage.Suspense query={queryRef} /> : <HallMonitorContainerPage.Skeleton />}
      </PageErrorBoundary>
    </main>
  );
});

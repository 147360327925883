import { hasValue } from '@lego/mst-error-utilities';
import { Grid, Skeleton, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useEffect, useRef, useState } from 'react';
import { Disposable, useFragment, useMutation } from 'react-relay';
import { getTimestampForRelay } from '../../utility/date';
import { useTranslation } from '../../utility/i18n/translation';
import { LocationSelect } from '../components/equipment/LocationSelect';
import { ErrorMessage } from '../components/ErrorMessage';
import { skeletonify } from '../skeleton';
import { useCreateTicketContext } from './create-ticket-context';
import { CreateTicketStepButtons } from './CreateTicketStepButtons';
import { SublocationComponent } from './CreateTicketSublocationComponent';
import { CreateTicketLocationStepMutation } from './__generated__/CreateTicketLocationStepMutation.graphql';
import { CreateTicketLocationStep_equipment$key } from './__generated__/CreateTicketLocationStep_equipment.graphql';

export type SublocationInput = { sublocationId: string } | { sublocationText?: string };

type CreateTicketLocationLabelProps = {
  equipment: CreateTicketLocationStep_equipment$key;
};

const ActualComponent = (props: CreateTicketLocationLabelProps) => {
  const { equipment: equipmentRef } = props;
  const { state, dispatch } = useCreateTicketContext();
  const { translate } = useTranslation();
  const [error, setError] = useState<string | undefined>();
  const ref = useRef<Disposable | undefined>();

  const equipment = useFragment(
    graphql`
      fragment CreateTicketLocationStep_equipment on Equipment {
        __typename
        id
        location {
          id
          description
        }
        ...LocationSelect_equipment
        ...CreateTicketSublocationComponent_equipment
      }
    `,
    equipmentRef
  );

  useEffect(() => {
    if (equipment.location?.id) {
      dispatch({ type: 'set_location', payload: equipment.location?.id });
    }
  }, [dispatch, equipment]);

  const [commit, isInFlight] = useMutation<CreateTicketLocationStepMutation>(graphql`
    mutation CreateTicketLocationStepMutation($input: MutationUpdateEquipmentLocationInput!) {
      updateEquipmentLocation(input: $input) {
        ... on MutationUpdateEquipmentLocationSuccess {
          data {
            ...CreateTicketLocationStep_equipment
          }
        }
      }
    }
  `);

  const handleOnChange = (locationId: string, sublocation?: SublocationInput | null) => {
    ref.current?.dispose();

    ref.current = commit({
      variables: {
        input: {
          equipmentId: equipment.id,
          locationId,
          ...sublocation,
          updatedDate: getTimestampForRelay(),
        },
      },
    });
  };

  const handleNewLocation = (locationId: string) => {
    dispatch({ type: 'set_location', payload: locationId });
    handleOnChange(locationId, null);
  };

  const handleNewSublocation = (sublocation: SublocationInput) => {
    if (state.locationId) {
      handleOnChange(state.locationId, sublocation);
    }
  };

  const moveForward = () => {
    if (!hasValue(state.locationId)) {
      setError(translate('CREATE_TICKET.ERROR.LOCATION_NOT_SET', 'No location selected'));
    } else if (
      state.sublocation.isMandatory &&
      !(hasValue(state.sublocation.text) && state.sublocation.text.length !== 0)
    ) {
      setError(translate('CREATE_TICKET.ERROR.SUBLOCATION_NOT_SET', 'No sublocation selected'));
    } else {
      dispatch({ type: 'step_forward' });
    }
  };

  const Location =
    state.currentStep === 'location' ? (
      <LocationSelect equipment={equipment} loading={isInFlight} onChange={handleNewLocation} />
    ) : (
      <Typography>{equipment?.location?.description}</Typography>
    );

  return {
    location: Location,
    sublocation: <SublocationComponent equipment={equipment} loading={isInFlight} onChange={handleNewSublocation} />,
    stepper: (
      <>
        {error && <ErrorMessage message={error} />}
        <CreateTicketStepButtons moveForward={moveForward} loading={isInFlight} />
      </>
    ),
  };
};

const SkeletonComponent = () => ({
  location: <Skeleton width={190} height={48} variant="text" />,
  sublocation: <Skeleton width={190} height={48} variant="text" />,
  stepper: <Skeleton width={190} height={48} variant="rectangular" />,
});

const StructureComponent = ({
  location,
  sublocation,
  stepper,
}: {
  location: JSX.Element | null;
  sublocation: JSX.Element | null;
  stepper: JSX.Element | null;
}) => {
  const { translate } = useTranslation();
  const { state } = useCreateTicketContext();

  const { currentStep } = state;

  return (
    <Grid container spacing={1} direction="column">
      {currentStep === 'location' && (
        <Grid item mb={2}>
          <Typography>{translate('CREATE_TICKET.LOCATION_STEP.TEXT', 'Is the location correct?')}</Typography>
        </Grid>
      )}
      {state.currentStep === 'location' && (
        <Grid item>
          <LocationLabel />
        </Grid>
      )}
      <Grid item>{location}</Grid>
      {state.currentStep === 'location' && (
        <Grid item>
          <SublocationLabel />
        </Grid>
      )}
      <Grid item>{sublocation}</Grid>
      {state.currentStep === 'location' && <Grid item>{stepper}</Grid>}
    </Grid>
  );
};

export const CreateTicketLocationStep = skeletonify(
  'CreateTicketLocationLabel',
  ActualComponent,
  SkeletonComponent,
  StructureComponent
);

const LocationLabel: FC = () => {
  const { translate } = useTranslation();
  return <Typography>{translate('CREATE_TICKET.LOCATION.LABEL', 'Hall / Section')}</Typography>;
};

const SublocationLabel: FC = () => {
  const { translate } = useTranslation();
  return <Typography>{translate('CREATE_TICKET.SUB_LOCATION.LABEL', 'Room (Row / Place)')}</Typography>;
};

import { IconButton, Tooltip } from '@mui/material';
import { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { getEquipmentIdFromUrl } from '../../migration/utils';
import { AppRoutes } from '../../Router';
import { useTranslation } from '../../utility/i18n/translation';
import { Icons } from '../../utility/icons';

export const NewTicketLink: FC = () => {
  const location = useLocation();
  const { translate } = useTranslation();
  const equipmentId = getEquipmentIdFromUrl(location);

  return (
    <Tooltip arrow title={translate('NAVBAR.CREATE_TICKET_TOOLTIP', 'Create ticket')}>
      <IconButton
        data-cy="NewTicketLink"
        component={NavLink}
        to={AppRoutes.createTicket(equipmentId)}
        sx={{
          color: 'white',
          height: '100%',
          textTransform: 'none',
          borderRadius: 0,
          padding: 0,
          '&.active': {
            borderBottom: '2px solid white',
            mt: '4px',
          },
        }}
        size="large"
      >
        <Icons.CreateTicket fill={'white'} fontSize={'large'} sx={{ mt: '4px' }} />
      </IconButton>
    </Tooltip>
  );
};

import { Card, CardContent, Chip, Grid, Skeleton, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { CardWithTitleSkeleton } from '../../components/shared/CardWithTitle';
import { useTranslation } from '../../utility/i18n/translation';
import { skeletonify } from '../skeleton';
import { FullHeightGrid } from '../ticket-search/TicketSearch';
import { QAOperationsData } from './QAOperationsData';
import { QAOperations_equipment$key } from './__generated__/QAOperations_equipment.graphql';

const skeletonTextWidth = 200;

const ActualComponent = ({ equipment: equipmentRef }: { equipment: QAOperations_equipment$key }) => {
  const { translate } = useTranslation();
  const query = useFragment(
    graphql`
      fragment QAOperations_equipment on Equipment {
        ... on Mould {
          ...QAOperationsData_equipment
        }
      }
    `,
    equipmentRef
  );

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item>
            <FullHeightGrid>
              <Grid container display="flex" flexDirection="column">
                <Grid item>
                  <Typography variant="subtitle1">
                    {translate('QA_DETAILS.CARDS.MOULD_DETAILS.LABELS.QA', 'QA')}
                  </Typography>
                </Grid>
              </Grid>
            </FullHeightGrid>
          </Grid>
          <QAOperationsData queryRef={query} />
        </Grid>
      </CardContent>
    </Card>
  );
};
const SkeletonComponent = () => {
  return <QAOperationsSkeleton />;
};

const QAOperationsSkeleton: FC = () => {
  return (
    <CardWithTitleSkeleton>
      <Grid container direction="column">
        {[...Array(5)].map((item) => (
          <Grid display="flex" key={item}>
            <Skeleton variant="text" width={skeletonTextWidth} />
            <Chip sx={{ width: 100, height: 20, alignSelf: 'center', marginLeft: 7 }} />
          </Grid>
        ))}
      </Grid>
    </CardWithTitleSkeleton>
  );
};
export const QAOperations = skeletonify('QAOperations', ActualComponent, SkeletonComponent);

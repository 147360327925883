import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { createContext, useState, useContext, ReactNode, Dispatch, SetStateAction } from 'react';
import { useTranslation } from '../../utility/i18n/translation';

type ConfirmDialogProps = {
  title: string;
  onClose: () => unknown;
  onConfirm: () => unknown;
  cancelMessage?: string;
  confirmMessage?: string;
  message: JSX.Element;
};

const ConfirmDialogContext = createContext<Dispatch<SetStateAction<ConfirmDialogProps | undefined>> | undefined>(
  undefined
);

export const useConfirmDialog = () => {
  const setState = useContext(ConfirmDialogContext);
  return (props: Omit<ConfirmDialogProps, 'onClose' | 'onConfirm'>) =>
    new Promise<void>((resolve, reject) => {
      setState?.((prevState) => {
        prevState?.onClose();
        return {
          ...props,
          onClose: () => {
            setState(undefined);
            reject();
          },
          onConfirm: () => {
            setState(undefined);
            resolve();
          },
        };
      });
    });
};

export const ConfirmDialogProvider = ({ children }: { children: ReactNode }) => {
  const [dialogProps, setDialogProps] = useState<ConfirmDialogProps>();
  return (
    <ConfirmDialogContext.Provider value={setDialogProps}>
      {children}
      {dialogProps && <ConfirmDialog {...dialogProps} />}
    </ConfirmDialogContext.Provider>
  );
};

const ConfirmDialog = ({ onConfirm, onClose, confirmMessage, cancelMessage, title, message }: ConfirmDialogProps) => {
  const { translate } = useTranslation();

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          {cancelMessage ?? translate('MISC.CANCEL', 'Cancel')}
        </Button>
        <Button onClick={onConfirm} variant="contained">
          {confirmMessage ?? translate('MISC.CONFIRM', 'Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

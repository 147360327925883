/**
 * @generated SignedSource<<09ad4996f2a18895d55a7cc6ee16711f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TicketPriority = "Priority1" | "Priority2" | "Priority3" | "Priority4" | "Priority5" | "Priority6" | "Priority7" | "Priority8" | "Priority9" | "PriorityA" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PriorityPill_ticket$data = {
  readonly __typename: string;
  readonly equipment: {
    readonly __typename: string;
  } | null;
  readonly priority: TicketPriority;
  readonly " $fragmentType": "PriorityPill_ticket";
};
export type PriorityPill_ticket$key = {
  readonly " $data"?: PriorityPill_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"PriorityPill_ticket">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PriorityPill_ticket",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priority",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "equipment",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};
})();

(node as any).hash = "ca9bc9e8dedfae1d32dd856393f89903";

export default node;

import { hasValue } from '@lego/mst-error-utilities';
import graphql from 'babel-plugin-relay/macro';
import { FC, useEffect, useMemo } from 'react';
import { useFragment } from 'react-relay';
import { InnerBanner } from '../../../components/shared/banner/InnerBanner';
import { useBannerContext } from '../../../components/shared/banner/banner-context';
import { useTranslation } from '../../../utility/i18n/translation';
import { TDMouldFlowTypeBanner_mould$key } from './__generated__/TDMouldFlowTypeBanner_mould.graphql';

const STATUS_TO_SHOW = ['YellowToQARejected', 'YellowToProductionRejected', 'YellowToMPEERejected'];

export const TDMouldFlowTypeBanner: FC<{
  mould: TDMouldFlowTypeBanner_mould$key;
}> = ({ mould: mouldRef }) => {
  const { translate } = useTranslation();
  const { dispatch: bannerDispatch } = useBannerContext();

  const { closeFlowStatus } = useFragment(
    graphql`
      fragment TDMouldFlowTypeBanner_mould on Mould {
        closeFlowStatus {
          status
        }
      }
    `,
    mouldRef
  );

  const bannerText = useMemo(() => {
    switch (closeFlowStatus?.status) {
      case 'YellowToQARejected':
        return translate('TICKET_DETAILS.BANNER.MOULD_REJECTED_IN_QA', 'Mould has been rejected in QA.');
      case 'YellowToProductionRejected':
        return translate(
          'TICKET_DETAILS.BANNER.MOULD_REJECTED_IN_PRODUCTION',
          'Mould has been rejected in production.'
        );
      case 'YellowToMPEERejected':
        return translate('TICKET_DETAILS.BANNER.MOULD_REJECTED_IN_MPEE', 'Mould has been rejected in MPE&E.');
      default:
        return null;
    }
  }, [closeFlowStatus, translate]);

  useEffect(() => {
    if (hasValue(closeFlowStatus) && hasValue(bannerText) && STATUS_TO_SHOW.includes(closeFlowStatus.status)) {
      bannerDispatch({
        type: 'show',
        payload: {
          text: bannerText,
          type: 'warning',
          innerBanner: true,
        },
      });
    }
    return () => bannerDispatch({ type: 'hide' });
  }, [bannerDispatch, bannerText, closeFlowStatus]);

  return <InnerBanner />;
};

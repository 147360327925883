import { FC } from 'react';
import { useTranslation } from '../../../utility/i18n/translation';
import { TDErrorDescriptionContent } from '../../ticket-details/TDErrorDescription';
import { TicketDetailsErrorDescription } from '../../ticket-details/__apollo__/TicketDetailsErrorDescription';
import { ARDCollapsibleSidebarWidget } from './ARDCollapsibleSidebarWidget';

export const ARDErrorDescription: FC<TicketDetailsErrorDescription> = (props) => {
  const { translate } = useTranslation();
  return (
    <div data-cy="ARDErrorDescription">
      <ARDCollapsibleSidebarWidget
        title={translate('ADD_REPAIR_DOCUMENTATION.SIDEBAR_WIDGETS.ERROR_DESC.TITLE', 'Error description')}
      >
        <TDErrorDescriptionContent {...props} />
      </ARDCollapsibleSidebarWidget>
    </div>
  );
};

/**
 * @generated SignedSource<<f234769172fadcc227e056858d407684>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentHistoryKnowledgeCenter_ticketsConnection$data = {
  readonly count: number;
  readonly facets: {
    readonly activity: ReadonlyArray<{
      readonly catalogId: string;
      readonly count: number;
      readonly shortText: string;
    }>;
    readonly damage: ReadonlyArray<{
      readonly catalogId: string;
      readonly count: number;
      readonly shortText: string;
    }>;
  };
  readonly " $fragmentType": "EquipmentHistoryKnowledgeCenter_ticketsConnection";
};
export type EquipmentHistoryKnowledgeCenter_ticketsConnection$key = {
  readonly " $data"?: EquipmentHistoryKnowledgeCenter_ticketsConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryKnowledgeCenter_ticketsConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "shortText",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "catalogId",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentHistoryKnowledgeCenter_ticketsConnection",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketsConnectionFacets",
      "kind": "LinkedField",
      "name": "facets",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CatalogFacet",
          "kind": "LinkedField",
          "name": "damage",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CatalogFacet",
          "kind": "LinkedField",
          "name": "activity",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TicketsConnection",
  "abstractKey": null
};
})();

(node as any).hash = "bdce32bf10fce0adb50a891fdc336f19";

export default node;

import { hasValue } from '@lego/mst-error-utilities';
import { Grid, TextField, Typography } from '@mui/material';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGMQuery } from '../../../apollo/customApolloHooks';
import {
  MAX_LENGTH_FOR_CUSTOM_FIELD_DESCRIPTION,
  useAddRepairDescriptionContext,
} from '../../../contexts/add-repair-description/add-repair-description-context';
import { REPAIR_DESCRIPTION_COMPONENTS } from '../../../contexts/add-repair-description/add-repair-description-gql-queries';
import { ComponentOption } from '../../../contexts/add-repair-description/__apollo__/ComponentOption';
import {
  RepairComponentOptions,
  RepairComponentOptionsVariables,
} from '../../../contexts/add-repair-description/__apollo__/RepairComponentOptions';
import { useTranslation } from '../../../utility/i18n/translation';
import { RepairDocumentationFlowVariant } from '../../../__apollo__/types';
import { FillWidthLoading } from '../../shared/FillWidthLoading';
import { ARDAutocomplete } from '../ARDAutocomplete';
import { ARDNavigationButtons } from '../ARDNavigationButtons';

export const ARDChooseComponent: FC = () => {
  const { translate } = useTranslation();
  const { id: ticketId } = useParams() as { id: string };
  const {
    state: { chosenSubEquipment, customComponentString, variant, chosenComponent, currentStep },
    dispatch,
  } = useAddRepairDescriptionContext();

  const { data, loading, error } = useGMQuery<RepairComponentOptions, RepairComponentOptionsVariables>(
    REPAIR_DESCRIPTION_COMPONENTS,
    {
      skip: !hasValue(chosenSubEquipment) || variant === RepairDocumentationFlowVariant.Baseline,
      variables: {
        input: {
          ticketId,
          subEquipmentId: chosenSubEquipment?.id ?? '',
        },
      },
    }
  );

  if (loading) {
    return <FillWidthLoading />;
  }

  if (!data || error) {
    return (
      <Typography>
        {translate('ADD_REPAIR_DOCUMENTATION.CHOOSE_COMPONENT.NO_COMPONENTS', 'No components to show, please refresh')}
      </Typography>
    );
  }

  const onValueSelected = (value: ComponentOption | null) => {
    if (value) {
      dispatch({ type: 'set_component', chosenComponent: value });
      if (!value.requiresAdditionalInput) {
        dispatch({ type: 'step_forward' });
      }
    }
  };

  if (!(currentStep === 'component')) {
    return (
      <Typography>
        {chosenComponent?.description}
        {chosenComponent?.requiresAdditionalInput && customComponentString ? ` - ${customComponentString}` : ''}
      </Typography>
    );
  }

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography>
          {translate('ADD_REPAIR_DOCUMENTATION.CHOOSE_COMPONENT.CHOOSE', 'Choose the appropriate component')}
        </Typography>
      </Grid>
      <Grid item>
        <ARDAutocomplete<ComponentOption>
          onChange={onValueSelected}
          options={data.repairDescriptionComponentOptions ?? []}
          chosenValue={chosenComponent ?? null}
          cyIdentifier="component"
        />
        {chosenComponent?.requiresAdditionalInput && <CustomComponentInputComponent />}
      </Grid>
    </Grid>
  );
};

const CustomComponentInputComponent: FC = () => {
  const { translate } = useTranslation();
  const [text, setText] = useState('');

  const {
    state: { customComponentString },
    dispatch,
  } = useAddRepairDescriptionContext();

  useEffect(() => {
    if (customComponentString && customComponentString.length > 0 && text.length === 0) {
      setText(customComponentString);
    }
  }, [customComponentString, text.length]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setText(newValue);
    dispatch({ type: 'set_custom_component', customInput: newValue });
  };

  return (
    <Grid container direction="column" spacing={3} style={{ marginTop: 20 }}>
      <Grid item>
        <TextField
          defaultValue={customComponentString}
          variant="outlined"
          onChange={onChange}
          fullWidth
          inputProps={{ maxLength: MAX_LENGTH_FOR_CUSTOM_FIELD_DESCRIPTION }}
          label={translate(
            'ADD_REPAIR_DOCUMENTATION.CHOOSE_COMPONENT.CUSTOM_INPUT_PLACEHOLDER',
            'Component description'
          )}
        />
      </Grid>
      <Grid item>
        <ARDNavigationButtons />
      </Grid>
    </Grid>
  );
};

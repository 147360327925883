import { Button, Card, Divider, Grid } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetDataForAddTimeFlow_ticket_Ticket } from '../../containers/__apollo__/GetDataForAddTimeFlow';
import { useAddTimeContext } from '../../contexts/add-time/add-time-context';
import { useAuthContext } from '../../contexts/AuthContext';
import { useTranslation } from '../../utility/i18n/translation';
import { ActivityIndicator } from '../shared/ActivityIndicator';
import { AddTimeHeader } from './AddTimeHeader';
import { useAddTimeMutation } from './addTimeMutation';
import { AddTimeRow } from './AddTimeRow';
import { AddTimeOverview } from './overview/AddTimeOverview';

export const AddTimeRoot: FC<GetDataForAddTimeFlow_ticket_Ticket> = (props) => {
  const { translate } = useTranslation();
  const { saveChanges, loading } = useAddTimeMutation(props.id);
  const navigate = useNavigate();
  const { state, dispatch } = useAddTimeContext();
  const { currentUserId } = useAuthContext();

  const onAddPersonClicked = () => {
    dispatch({ type: 'set_show_empty_row', showEmptyRow: true });
  };

  const onSavePressed = async () => {
    const result = await saveChanges();
    if (result === 'success') {
      navigate(-1);
    }
  };

  const horizontalPadding = 60;
  const verticalPadding = 40;

  return (
    <Card
      style={{
        paddingTop: verticalPadding,
        paddingBottom: verticalPadding,
        paddingLeft: horizontalPadding,
        paddingRight: horizontalPadding,
      }}
    >
      <Grid container direction="column">
        <Grid item>
          <AddTimeHeader />
        </Grid>
        <Grid item container direction="column" spacing={1} style={{ width: '100%' }}>
          {state.additions.map((addition) => {
            return (
              <Grid key={addition.employeeId} item>
                <AddTimeRow
                  profileInfo={{
                    ...addition,
                    pictureUri: addition.pictureUri,
                  }}
                  canBeDeleted={addition.employeeId !== currentUserId}
                  employeeId={addition.employeeId}
                />
                <Divider />
              </Grid>
            );
          })}
          {state.showEmptyRow && (
            <Grid item>
              <AddTimeRow canBeDeleted={true} />
            </Grid>
          )}
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
          <Button variant="outlined" color="primary" onClick={onAddPersonClicked} disabled={state.showEmptyRow}>
            {translate('ADD_TIME.ADD_PERSON', 'Add person')}
          </Button>
        </Grid>
        <Grid item style={{ marginTop: 40 }}>
          <AddTimeOverview {...props} />
        </Grid>
        <Grid item style={{ alignSelf: 'flex-end', marginTop: 20 }}>
          <Button
            data-cy="AddTimeRoot-save"
            variant="contained"
            color="primary"
            onClick={onSavePressed}
            disabled={loading}
          >
            {loading ? <ActivityIndicator size={32} /> : translate('ADD_TIME.SAVE', 'Save')}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

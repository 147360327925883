/**
 * @generated SignedSource<<be940fdaf87b616357442241c538cab3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TicketFlowType = "GatTest" | "Green" | "Normal" | "Unknown" | "YellowFunctionalTest" | "YellowRepair" | "YellowToMPEE" | "YellowToMPEERejected" | "YellowToProduction" | "YellowToProductionRejected" | "YellowToQA" | "YellowToQAApproved" | "YellowToQARejected" | "%future added value";
export type TicketPriority = "Priority1" | "Priority2" | "Priority3" | "Priority4" | "Priority5" | "Priority6" | "Priority7" | "Priority8" | "Priority9" | "PriorityA" | "Unknown" | "%future added value";
export type TicketStatus = "Closed" | "InProgress" | "NotStarted" | "Released" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useTicketPermissions_canAddErrorDescription_ticket$data = {
  readonly equipment: {
    readonly " $fragmentSpreads": FragmentRefs<"useTicketPermissions_isTicketEditable_equipment">;
  } | null;
  readonly flowType?: TicketFlowType | null;
  readonly priority: TicketPriority;
  readonly status: TicketStatus;
  readonly " $fragmentType": "useTicketPermissions_canAddErrorDescription_ticket";
};
export type useTicketPermissions_canAddErrorDescription_ticket$key = {
  readonly " $data"?: useTicketPermissions_canAddErrorDescription_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTicketPermissions_canAddErrorDescription_ticket">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useTicketPermissions_canAddErrorDescription_ticket",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priority",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "equipment",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useTicketPermissions_isTicketEditable_equipment"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "flowType",
          "storageKey": null
        }
      ],
      "type": "MouldTicket",
      "abstractKey": null
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "d65612a2626a1f021fe9602f151320a1";

export default node;

import { hasValue } from '@lego/mst-error-utilities';
import graphql from 'babel-plugin-relay/macro';
import { FC, useEffect, useState, useTransition } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { LoadingTextField } from '../components/LoadingTextField';
import { SparePartSearchTextField } from '../components/sparepart/SparePartSearchTextField';
import { isValidSparePartId } from '../utils';
import CmsSparePartSearchQuery, {
  CmsSparePartSearchQuery as CmsSparePartSearchQueryType,
} from './__generated__/CmsSparePartSearchQuery.graphql';

const ActualComponent: FC<{
  query: PreloadedQuery<CmsSparePartSearchQueryType>;
  onChange: (value: string | null) => void;
  onSparePartNumberChange: (value: string) => void;
}> = ({ query: queryRef, onChange, onSparePartNumberChange }) => {
  const { sparePart } = usePreloadedQuery<CmsSparePartSearchQueryType>(
    graphql`
      query CmsSparePartSearchQuery($input: QuerySparePartInput!, $skip: Boolean!) {
        sparePart(input: $input) @skip(if: $skip) {
          ... on QuerySparePartSuccess {
            data {
              id
            }
          }
        }
      }
    `,
    queryRef
  );

  useEffect(() => {
    const sparePartId = sparePart?.data?.id;
    if (hasValue(sparePartId)) {
      onChange(sparePartId);
    } else {
      onChange(null);
    }
  }, [sparePart, onChange]);

  return (
    <SparePartSearchTextField
      onSparePartSelected={onSparePartNumberChange}
      showPrefixLabel
      showPlaceholder={false}
      prefixLabelStyle={{ fontSize: '18px' }}
      variant="clear"
      inputFieldSize={3}
    />
  );
};

const SkeletonComponent = () => {
  return <LoadingTextField loading />;
};

export const CmsSparePartSearch: FC<{
  onChange: (value: string | null) => void;
}> = ({ onChange }) => {
  const [sparePartNumber, setSparePartNumber] = useState<string>('');
  const [queryRef, loadQuery] = useQueryLoader<CmsSparePartSearchQueryType>(CmsSparePartSearchQuery);
  const [, startTransition] = useTransition();

  useEffect(() => {
    startTransition(() => {
      loadQuery(
        {
          input: {
            sparePartNumber: sparePartNumber,
          },
          skip: !hasValue(sparePartNumber) || !isValidSparePartId(sparePartNumber.toString()),
        },
        { fetchPolicy: 'store-and-network' }
      );
    });
  }, [sparePartNumber, loadQuery]);

  return queryRef ? (
    <ActualComponent query={queryRef} onChange={onChange} onSparePartNumberChange={setSparePartNumber} />
  ) : (
    <SkeletonComponent />
  );
};

/**
 * @generated SignedSource<<01977ddf6ad97c36ee68482931f030fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketDetailsHeaderFragment$data = {
  readonly equipment: {
    readonly __typename: string;
    readonly qualityManagementInspection?: {
      readonly validationResult: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"EquipmentDescription_equipment" | "EquipmentNumber_equipment" | "TDMouldFlowTypeBanner_mould">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"PriorityPill_ticket" | "RequiredDates_ticket" | "TDMenuFragment" | "TicketNumber_ticket">;
  readonly " $fragmentType": "TicketDetailsHeaderFragment";
};
export type TicketDetailsHeaderFragment$key = {
  readonly " $data"?: TicketDetailsHeaderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketDetailsHeaderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketDetailsHeaderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "equipment",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquipmentNumber_equipment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquipmentDescription_equipment"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QualityManagementInspection",
              "kind": "LinkedField",
              "name": "qualityManagementInspection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "validationResult",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TDMouldFlowTypeBanner_mould"
            }
          ],
          "type": "Mould",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TDMenuFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RequiredDates_ticket"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PriorityPill_ticket"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketNumber_ticket"
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "10967e1c3594a00d547dcbcb7068455e";

export default node;

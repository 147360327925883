import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useCloseTicketContext } from '../../contexts/close-ticket/close-ticket-context';
import { RouteTypes } from '../../Router';
import { useTranslation } from '../../utility/i18n/translation';
import { FlowHeaderWithDiscardDialog } from '../shared/FlowHeaderWithDiscardDialog';

export const CTHeader: FC = () => {
  const { translate } = useTranslation();
  const { state } = useCloseTicketContext();
  const { step } = useParams() as RouteTypes['close'];

  // TODO:
  const dirty = step !== 'repair' || state.showRepairDocsRequiredWarning;

  return <FlowHeaderWithDiscardDialog dirty={dirty} title={translate('CLOSE_TICKET.HEADER.TITLE', 'Close ticket')} />;
};

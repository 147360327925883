import { Grid, InputAdornment, TextField, TextFieldProps, Typography, useTheme } from '@mui/material';
import debounce from 'lodash/debounce';
import { ComponentProps, FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from '../../utility/i18n/translation';
import { Icons } from '../../utility/icons';

type CombinedProps = ComponentProps<typeof SearchField> &
  ComponentProps<typeof TotalCount> & {
    showCount?: boolean;
    autoFocus?: boolean;
  };

export const CountAndSearch: FC<CombinedProps> = (props) => {
  return (
    <Grid sx={{ pl: 1, minHeight: 54 }} display="flex" alignItems="center" container>
      <Grid item md={2} justifyContent="start" display="flex">
        {props.showCount && <TotalCount {...props} />}
      </Grid>
      <Grid item md={10} display="flex" justifyContent="flex-end">
        <SearchField {...props} />
      </Grid>
    </Grid>
  );
};

const TotalCount: FC<{ count?: number }> = ({ count }) => {
  const { translate } = useTranslation();

  const total = count ?? 0;

  return (
    <Typography>
      {translate('TICKET_LIST.RESULTS', '{{total}} results', {
        total,
      })}
    </Typography>
  );
};

const SearchField: FC<{
  onChange: (value: string) => void;
  controlledValue?: string;
  autoFocus?: boolean;
}> = ({ onChange, controlledValue, autoFocus = false }) => {
  const { translate } = useTranslation();
  const [focus, setFocus] = useState(autoFocus);
  const { palette } = useTheme();

  const onChangeDebounced = useMemo(() => {
    const searchDispatch: TextFieldProps['onChange'] = (event) => {
      onChange(event.target.value);
    };

    return debounce(searchDispatch, 500, {
      maxWait: 1500,
    });
  }, [onChange]);

  const handleFocus = useCallback(() => setFocus(true), [setFocus]);
  const handleBlur = useCallback(() => setFocus(false), [setFocus]);

  const textFieldStyle = {
    backgroundColor: palette.background.paper,
    width: autoFocus || focus ? '100%' : 300,
    transition: 'width 0.5s',
  };

  return (
    <TextField
      data-testid="spare-part-search-text-field"
      data-cy="CountAndSearch-textField"
      placeholder={translate('TICKET_LIST.SEARCH_PLACEHOLDER', 'Search')}
      style={textFieldStyle}
      autoFocus={autoFocus}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={controlledValue}
      onChange={onChangeDebounced}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icons.Search fill={palette.text.disabled} />
          </InputAdornment>
        ),
      }}
    />
  );
};

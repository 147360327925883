import { Grid, Typography, useTheme } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { ActivityIndicator } from '../../components/shared/ActivityIndicator';
import { useTranslation } from '../../utility/i18n/translation';
import { Icons } from '../../utility/icons';
import { OpenTicketsList } from './OpenTicketsList';
import { RecentlyClosedTicketsList } from './RecentlyClosedTicketsList';
import { OpenAndRecentlyClosedTickets_equipment$key } from './__generated__/OpenAndRecentlyClosedTickets_equipment.graphql';

interface Props {
  equipment?: OpenAndRecentlyClosedTickets_equipment$key | null | undefined;
  loading?: boolean;
}
export const OpenAndRecentlyClosedTickets: FC<Props> = ({ equipment: equipmentRef, loading }) => {
  const { translate } = useTranslation();

  const equipment = useFragment(
    graphql`
      fragment OpenAndRecentlyClosedTickets_equipment on Equipment
      @argumentDefinitions(completedDate: { type: "LocalTimestampFilterInput" }) {
        ...RecentlyClosedTicketsList_equipment @arguments(completedDate: $completedDate) #@defer
        ...OpenTicketsList_baseEquipment #@defer
      }
    `,
    equipmentRef ?? null
  );

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item mb={0} mt={1} ml={1}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="subtitle2">{translate('CREATE_TICKET.OPEN_TICKETS', 'Open tickets')}</Typography>
          </Grid>
          {equipment && (
            <Grid item flexDirection="row" display="flex" alignItems="center">
              <Typography variant="body2">
                {translate('CREATE_TICKET.WARNING_DUPLICATE', 'Please avoid creating duplicate tickets')}
              </Typography>
              <Icons.WarningTriangle color="error" fontSize="large" style={{ marginLeft: '5px' }} />
            </Grid>
          )}
        </Grid>
      </Grid>
      {loading ? (
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <ActivityIndicator />
          </Grid>
        </Grid>
      ) : equipment ? (
        <Grid item>
          <OpenTicketsList equipment={equipment} />
        </Grid>
      ) : (
        <NoTickets />
      )}
      <Grid item mb={1} mt={1} ml={1}>
        <Typography variant="subtitle2">
          {translate('CREATE_TICKET.RECENTLY_CLOSED', 'Tickets closed the last 48 hours')}
        </Typography>
      </Grid>
      {loading ? (
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <ActivityIndicator />
          </Grid>
        </Grid>
      ) : equipment ? (
        <Grid item>
          <RecentlyClosedTicketsList equipment={equipment} />
        </Grid>
      ) : (
        <NoTickets />
      )}
    </Grid>
  );
};

const NoTickets: FC = () => {
  const theme = useTheme();
  const { translate } = useTranslation();
  const noEquipmentIdText = translate('CREATE_TICKET.ENTER_EQUIPMENT_ID', 'Enter equipment ID to see open tickets');

  return (
    <Grid item display="flex" alignItems="center" flexDirection="column">
      <Icons.NoTickets style={{ fontSize: 140, marginBottom: 10 }} color={'disabled'} />
      <Typography variant="caption" color={theme.palette.grey[500]}>
        {noEquipmentIdText}
      </Typography>
    </Grid>
  );
};

import { Container, Tab, Tabs } from '@mui/material';
import { FC, SyntheticEvent, useState } from 'react';
import { useTranslation } from '../../utility/i18n/translation';
import { GMTabPanel } from '../components/GMTabPanel';
import { PlannedCleaningJobsTab } from './PlannedCleaningJobsTab';
import { PlannedSpringChangeJobsTab } from './PlannedSpringChangeJobsTab';

export const PlannedJobsContainer: FC = () => {
  const [value, setValue] = useState(0);
  const { translate } = useTranslation();

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth={false} sx={{ mt: 2 }}>
      <Tabs centered value={value} onChange={handleChange}>
        <Tab label={translate('PLANNED_JOBS.TABS.PLANNED_CLEANING_JOBS', 'Planned Cleaning Jobs')} />
        <Tab label={translate('PLANNED_JOBS.TABS.PLANNED_SPRING_CHANGE_JOBS', 'Planned Spring Change Jobs')} />
      </Tabs>
      <GMTabPanel value={value} index={0}>
        <PlannedCleaningJobsTab.Suspense />
      </GMTabPanel>
      <GMTabPanel value={value} index={1}>
        <PlannedSpringChangeJobsTab.Suspense />
      </GMTabPanel>
    </Container>
  );
};

import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, Fragment } from 'react';
import { useFragment } from 'react-relay';
import { TicketListEmptyView } from '../../components/shared/TicketListEmptyView';
import { useTranslation } from '../../utility/i18n/translation';
import { skeletonify } from '../skeleton';
import { SparePartsTabConsumedRow } from './SparePartsTabConsumedRow';
import { SparePartsTabConsumed_ticket$key } from './__generated__/SparePartsTabConsumed_ticket.graphql';

const ActualComponent: FC<{
  ticket?: SparePartsTabConsumed_ticket$key | null;
}> = (props) => {
  const { translate } = useTranslation();
  const { ticket: ticketRef } = props;
  const data = useFragment(
    graphql`
      fragment SparePartsTabConsumed_ticket on Ticket
      @argumentDefinitions(spareParts: { type: "TicketSparePartsInput!" }) {
        spareParts(input: $spareParts) {
          id
          ...SparePartsTabConsumedRow_ISparePartMovement
        }
      }
    `,
    ticketRef ?? null
  );

  if (!data || data.spareParts.length === 0) {
    return (
      <TicketListEmptyView
        textBelowIcon={translate('TICKET_DETAILS.SPARE_PARTS_TAB.CONSUMED.NO_ITEMS', 'No parts consumed')}
        size={60}
      />
    );
  }

  return (
    <Fragment>
      {data?.spareParts.map((part, index) => {
        return (
          <Grid key={part.id} item xs>
            <SparePartsTabConsumedRow.Suspense sparePartMovement={part} />
            {index < data.spareParts.length - 1 && <Divider sx={{ my: 2 }} />}
          </Grid>
        );
      })}
    </Fragment>
  );
};

const SkeletonComponent: FC = () => {
  return <SparePartsTabConsumedRow.Skeleton />;
};

const StructureComponent: FC<{ children: any }> = ({ children }) => {
  const { translate } = useTranslation();

  return (
    <Card sx={{ mb: 4 }}>
      <Typography sx={{ p: 2 }} variant="subtitle1">
        {translate('TICKET_DETAILS.SPARE_PARTS_TAB.CONSUMED.CARD_TITLE', 'Consumed spare parts')}
      </Typography>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export const SparePartsTabConsumed = skeletonify(
  'SparePartsTabConsumed',
  ActualComponent,
  SkeletonComponent,
  StructureComponent
);

import { gql } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import { SelectChangeEvent } from '@mui/material/Select/Select';
import { FC } from 'react';
import { useTranslation } from '../../utility/i18n/translation';
import { AreaAndProcessListItem, AreaOrProcess } from './AreaAndProcess';
import { ProcessFragment } from './__apollo__/ProcessFragment';

export const PROCESS_FRAGMENT = gql`
  fragment ProcessFragment on Process {
    id
    name
  }
`;

export const PROCESSES_ALL_OPTION = 'processAllOption';

export const Process: FC<{
  processes: ProcessFragment[];
  currentlySelectedProcessId: string;
  onProcessChosen: (processId: string) => void;
  loading: boolean;
  customTitle?: string;
  disabled?: boolean;
  showOptionForAll?: boolean;
}> = ({
  processes,
  currentlySelectedProcessId,
  onProcessChosen,
  loading,
  customTitle,
  disabled = false,
  showOptionForAll = false,
}) => {
  const { translate } = useTranslation();
  const handleChange = (event: SelectChangeEvent<string>) => {
    if (event.target.value.length > 0) {
      onProcessChosen(event.target.value as string);
    }
  };

  const processList: AreaAndProcessListItem[] = processes.map(
    (process) =>
      ({
        id: process.id,
        name: `${process.name} (${process.id})`,
      } as AreaAndProcessListItem)
  );

  const helperText = translate(
    'PROFILE.AREA_AND_PROCESS.PROCESS_HELPER_TEXT',
    'Choose the process you are working with. Your choice will determine which tickets and equipment you will be able to view and change in the app.'
  );

  const allOption: AreaAndProcessListItem = {
    id: PROCESSES_ALL_OPTION,
    name: translate('PROFILE.AREA_AND_PROCESS.PROCESS_ALL_OPTION', 'All'),
  };

  const items = showOptionForAll ? [allOption, ...processList] : processList;

  const title = hasValue(customTitle) ? customTitle : translate('PROFILE.AREA_AND_PROCESS.PROCESS_TITLE', 'Process');

  return (
    <AreaOrProcess
      helperText={helperText}
      items={items}
      title={title}
      onChange={handleChange}
      loading={loading}
      currentlySelectedId={currentlySelectedProcessId}
      showHelperText={!hasValue(customTitle)}
      disabled={disabled}
      showPlaceholder={false}
    />
  );
};

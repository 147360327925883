import { hasValue } from '@lego/mst-error-utilities';
import { Grid, TextField, Typography } from '@mui/material';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGMLazyQuery } from '../../../apollo/customApolloHooks';
import {
  MAX_LENGTH_FOR_CUSTOM_FIELD_DESCRIPTION,
  useAddRepairDescriptionContext,
} from '../../../contexts/add-repair-description/add-repair-description-context';
import { REPAIR_DESCRIPTION_CAUSES } from '../../../contexts/add-repair-description/add-repair-description-gql-queries';
import { CauseOption } from '../../../contexts/add-repair-description/__apollo__/CauseOption';
import {
  RepairDescriptionCauses,
  RepairDescriptionCausesVariables,
} from '../../../contexts/add-repair-description/__apollo__/RepairDescriptionCauses';
import { useTranslation } from '../../../utility/i18n/translation';
import { FillWidthLoading } from '../../shared/FillWidthLoading';
import { ARDAutocomplete } from '../ARDAutocomplete';
import { ARDNavigationButtons } from '../ARDNavigationButtons';

export const ARDChooseCause: FC = () => {
  const { translate } = useTranslation();
  const { id: ticketId } = useParams() as { id: string };
  const {
    state: { chosenCause, chosenDamage, currentStep, customCauseString },
    dispatch,
  } = useAddRepairDescriptionContext();

  const [getCauses, { data, loading, error }] = useGMLazyQuery<
    RepairDescriptionCauses,
    RepairDescriptionCausesVariables
  >(REPAIR_DESCRIPTION_CAUSES);

  if (hasValue(chosenDamage) && !loading && !data) {
    getCauses({
      variables: {
        input: { ticketId, chosenDamage: chosenDamage.id },
      },
    });
  }

  if (loading && currentStep === 'cause') {
    return <FillWidthLoading />;
  }

  if (error || (!data && !loading)) {
    return (
      <Typography>
        {translate('ADD_REPAIR_DOCUMENTATION.CHOOSE_DAMAGE.NO_CAUSES', 'No causes to show, please refresh')}
      </Typography>
    );
  }

  if (!(currentStep === 'cause')) {
    return (
      <Typography>
        {chosenCause?.description}
        {chosenCause?.requiresAdditionalInput && customCauseString ? ` - ${customCauseString}` : ''}
      </Typography>
    );
  }

  const onValueSelected = (value: CauseOption | null) => {
    if (value) {
      dispatch({ type: 'set_cause', chosenCause: value });
      if (!value.requiresAdditionalInput) {
        dispatch({ type: 'step_forward' });
      }
    }
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography>
          {translate('ADD_REPAIR_DOCUMENTATION.CHOOSE_CAUSE.CHOOSE_CAUSE', 'Choose the appropriate cause code')}
        </Typography>
      </Grid>
      <Grid item>
        <ARDAutocomplete<CauseOption>
          onChange={onValueSelected}
          options={data?.repairDescriptionCauseOptions ?? []}
          chosenValue={chosenCause ?? null}
          cyIdentifier="cause"
        />
        {chosenCause?.requiresAdditionalInput && <CustomCauseInputComponent />}
      </Grid>
    </Grid>
  );
};

const CustomCauseInputComponent: FC = () => {
  const { translate } = useTranslation();
  const [text, setText] = useState('');

  const {
    state: { customCauseString },
    dispatch,
  } = useAddRepairDescriptionContext();

  useEffect(() => {
    if (customCauseString && customCauseString.length > 0 && text.length === 0) {
      setText(customCauseString);
    }
  }, [customCauseString, text.length]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setText(newValue);
    dispatch({ type: 'set_custom_cause', customInput: newValue });
  };

  return (
    <Grid container direction="column" spacing={3} style={{ marginTop: 20 }}>
      <Grid item>
        <TextField
          defaultValue={customCauseString}
          variant="outlined"
          onChange={onChange}
          fullWidth
          inputProps={{ maxLength: MAX_LENGTH_FOR_CUSTOM_FIELD_DESCRIPTION }}
          label={translate('ADD_REPAIR_DOCUMENTATION.CHOOSE_CAUSE.CUSTOM_INPUT_PLACEHOLDER', 'Cause description')}
        />
      </Grid>
      <Grid item>
        <ARDNavigationButtons />
      </Grid>
    </Grid>
  );
};

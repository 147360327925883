import { Box, Divider, Grid, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useMachineHoursContext } from '../../../../contexts/add-machine-hours/add-machine-hours-context';
import { CollapsibleWidget } from '../../../shared/CollapsibleWidget';
import { DurationLabelWithChange, TimeOverviewRow } from '../AddTimeOverviewRow';
import { isMachineTimeRegistrationMarkedForDeletion } from '../deletionUtils';
import { TimeRegistrationMachineHours } from '../__apollo__/TimeRegistrationMachineHours';

export const MachineHoursOverviewRow: FC<TimeRegistrationMachineHours & { interactive?: boolean }> = ({
  id,
  timeRegistrations,
  totalTimeSpentInMinutes,
  machine: { description },
  interactive = true,
}) => {
  const {
    dispatch,
    state: { deletions },
  } = useMachineHoursContext();

  return (
    <CollapsibleWidget
      componentWhenClosed={
        <RowHeader
          machineHourId={id}
          totalTimeSpentInMinutes={totalTimeSpentInMinutes}
          machineDescription={description}
        />
      }
    >
      <Grid container>
        {timeRegistrations.map((reg, index) => {
          const onMarkForDeletionClicked = () => {
            dispatch({
              type: 'mark_registration_for_deletion',
              machineHourId: id,
              timeRegistrationNumber: reg.timeRegistrationNumber,
              minutes: reg.durationInMinutes,
            });
          };

          const markedForDeletion = isMachineTimeRegistrationMarkedForDeletion(
            id,
            reg.timeRegistrationNumber,
            deletions
          );

          return (
            <Box key={reg.timeRegistrationNumber} style={{ width: '100%' }}>
              <TimeOverviewRow
                interactive={interactive}
                markedForDeletion={markedForDeletion}
                timeRegistration={reg}
                onMarkForDeletionClicked={onMarkForDeletionClicked}
              />
              {index < timeRegistrations.length - 1 && <Divider style={{ marginRight: 30 }} />}
            </Box>
          );
        })}
      </Grid>
    </CollapsibleWidget>
  );
};

const RowHeader: FC<{
  machineHourId: string;
  totalTimeSpentInMinutes: number;
  machineDescription: string;
}> = ({ totalTimeSpentInMinutes, machineHourId, machineDescription }) => {
  const {
    state: { deletions },
  } = useMachineHoursContext();

  const totalTimeAfterDeletions = useMemo(() => {
    const removedMinutes = deletions
      .filter((deletion) => deletion.machineHourId === machineHourId)
      .map((deletion) => deletion.minutes)
      .reduce((prev, curr) => prev + curr, 0);

    return totalTimeSpentInMinutes - removedMinutes;
  }, [deletions, machineHourId, totalTimeSpentInMinutes]);

  return (
    <Grid container direction="row" style={{ flex: 1 }} justifyContent="space-between">
      <Grid item>
        <Typography>{machineDescription}</Typography>
      </Grid>
      <Grid item>
        <DurationLabelWithChange
          totalTimeInMinutesBeforeChanges={totalTimeSpentInMinutes}
          totalTimeInMinutesAfterChanges={totalTimeAfterDeletions}
        />
      </Grid>
    </Grid>
  );
};

import { hasValue } from '@lego/mst-error-utilities';
import { Grid } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { CardWithTitle } from '../../../components/shared/CardWithTitle';
import { TextWithLabel } from '../../../components/shared/TextWithLabel';
import { useTranslation } from '../../../utility/i18n/translation';
import { TDSysCleaning_mould$key } from './__generated__/TDSysCleaning_mould.graphql';

export const TDSysCleaning: FC<{
  equipment: TDSysCleaning_mould$key;
}> = ({ equipment: equipmentRef }) => {
  const { translate, locale } = useTranslation();
  const { cleaningInterval, cleaningPercentage } = useFragment(
    graphql`
      fragment TDSysCleaning_mould on Mould {
        cleaningInterval
        cleaningPercentage
      }
    `,
    equipmentRef
  );
  if (!hasValue(cleaningInterval)) {
    return null;
  }
  return (
    <CardWithTitle title={translate('TICKET_DETAILS.CARDS.SYS_CLEANING.TITLE', 'Sys.Cleaning')}>
      <Grid container direction="row">
        <Grid item xs={2}>
          <SysCleaningItem
            text={cleaningInterval?.toLocaleString(locale)}
            label={translate('TICKET_DETAILS.CARDS.SYS_CLEANING.LABELS.INTERVAL', 'Interval')}
          />
        </Grid>
        <Grid item xs={10}>
          <SysCleaningItem
            text={`${cleaningPercentage}%`}
            label={translate('TICKET_DETAILS.CARDS.SYS_CLEANING.LABELS.CURRENT_STATUS', 'Current status')}
          />
        </Grid>
      </Grid>
    </CardWithTitle>
  );
};

const SysCleaningItem: FC<{
  label: string;
  text: string | undefined | null;
}> = ({ label, text }) => {
  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid item>
        <TextWithLabel text={text ?? '---'} label={label} />
      </Grid>
    </Grid>
  );
};

/**
 * @generated SignedSource<<2174e71d7f3e3e60c87123abc3e35876>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTicketPermissions_isTicketEditable_equipment$data = {
  readonly isActive: boolean;
  readonly " $fragmentType": "useTicketPermissions_isTicketEditable_equipment";
};
export type useTicketPermissions_isTicketEditable_equipment$key = {
  readonly " $data"?: useTicketPermissions_isTicketEditable_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTicketPermissions_isTicketEditable_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useTicketPermissions_isTicketEditable_equipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "525873fcf3b29edf433171fceb118872";

export default node;

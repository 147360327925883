import { hasValue } from '@lego/mst-error-utilities';
import { Collapse, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Skeleton, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { ChangeEvent, FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useFragment } from 'react-relay';
import { useTranslation } from '../../utility/i18n/translation';
import { ErrorMessage } from '../components/ErrorMessage';
import { skeletonify } from '../skeleton';
import { useCreateTicketContext } from './create-ticket-context';
import { CreateTicketStepButtons } from './CreateTicketStepButtons';
import {
  CreateTicketPriorityStep_equipment$data,
  CreateTicketPriorityStep_equipment$key,
} from './__generated__/CreateTicketPriorityStep_equipment.graphql';
import { AllowedTicketPriority } from './__generated__/CreateTicketDescriptionStepMutation.graphql';
import { GMDatePicker } from '../../components/shared/GMDatePicker';
import { addDays } from '../../utility/date';
import { format } from 'date-fns';
import { localeToDateFnsLocale } from '../../utility/i18n/i18n';

interface CreateTicketPriorityStepProps {
  equipment: CreateTicketPriorityStep_equipment$key | null | undefined;
}

export const CreateTicketPriorityStep: FC<CreateTicketPriorityStepProps> = ({ equipment: equipmentRef }) => {
  const { state, dispatch } = useCreateTicketContext();
  const { translate, locale } = useTranslation();
  const [error, setError] = useState<string | undefined>();
  const { currentStep } = state;

  const equipment = useFragment(
    graphql`
      fragment CreateTicketPriorityStep_equipment on Equipment {
        __typename
        allowedPriorities
        ... on Mould {
          currentProductionOrder {
            __typename
          }
        }
      }
    `,
    equipmentRef ?? null
  );

  const moveForward = () => {
    if (hasValue(state.priority)) {
      setError(undefined);
      dispatch({ type: 'step_forward' });
    } else {
      setError(translate('CREATE_TICKET.ERROR.PRIORITY_NOT_SET', 'No priority selected'));
    }
  };

  const getPriorityText = useCallback(
    (priority: AllowedTicketPriority) => {
      if (equipment?.__typename === 'Mould' && priority === 'Priority3') {
        return translate(`PRIORITIES.MouldCall`, 'Mould call');
      }

      if (priority === 'Priority5') {
        return translate(`PRIORITIES.Observation`, 'Observation');
      }

      return translate(`PRIORITIES.${priority}`, priority);
    },
    [equipment, translate]
  );

  const dateLabel = useMemo(() => {
    if (!state.requiredEndDate) {
      return null;
    }

    return format(state.requiredEndDate, 'P', {
      locale: localeToDateFnsLocale(locale),
    });
  }, [locale, state.requiredEndDate]);

  return (
    <Grid container spacing={1} direction="column">
      {currentStep === 'priority' && equipment && (
        <>
          <Grid item>
            <CreateTicketPriorities equipment={equipment} />
          </Grid>
          <Grid item>
            {error && <ErrorMessage message={error} />}
            <CreateTicketStepButtons moveForward={moveForward} />
          </Grid>
        </>
      )}
      {currentStep === 'description' && state.priority && (
        <Grid item>
          <Typography>{getPriorityText(state.priority)}</Typography>
          {dateLabel && <Typography>{dateLabel}</Typography>}
        </Grid>
      )}
    </Grid>
  );
};

type CreateTicketLocationLabelProps = {
  equipment: CreateTicketPriorityStep_equipment$data;
};

const ActualComponent = (props: CreateTicketLocationLabelProps) => {
  const { equipment } = props;
  const { dispatch, state } = useCreateTicketContext();
  const { translate } = useTranslation();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const payload = equipment.allowedPriorities?.find((x) => x === event.target.value);

    if (hasValue(payload)) {
      dispatch({ type: 'set_priority', payload });
    }
  };

  const handleCreatedFromChanged = (newValue: Date | null) => {
    dispatch({ type: 'set_required_end_date', date: newValue });
  };

  const getPriorityText = useCallback(
    (priority: AllowedTicketPriority) => {
      if (equipment.__typename === 'Mould' && priority === 'Priority3') {
        return translate(`PRIORITIES.MouldCall`, 'Mould call');
      }

      if (priority === 'Priority5') {
        return translate(`PRIORITIES.Observation`, 'Observation');
      }

      return translate(`PRIORITIES.${priority}`, priority);
    },
    [equipment.__typename, translate]
  );

  const showDatePicker = useMemo(() => {
    if (state.priority === 'OrderEndCleaning' || state.priority === 'OrderEndRepair') {
      return true;
    }
    if (
      (state.priority === 'LightCleaning' ||
        state.priority === 'Wornout' ||
        state.priority === 'GAT' ||
        state.priority === 'PlannedRepair' ||
        state.priority === 'PlannedCleaning') &&
      equipment.__typename === 'Mould' &&
      equipment.currentProductionOrder == null
    ) {
      return true;
    }

    return false;
  }, [equipment, state.priority]);

  useEffect(() => {
    // reset date if datepicker is being hidden
    if (!showDatePicker) {
      dispatch({ type: 'set_required_end_date', date: null });
    }
    // set default date if picker is being shown
    if (showDatePicker) {
      dispatch({
        type: 'set_required_end_date',
        date: addDays(new Date(), 14),
      });
    }
  }, [dispatch, showDatePicker]);

  return {
    radioButtons: (
      <Fragment>
        <FormControl>
          <RadioGroup onChange={onChange}>
            {equipment.allowedPriorities?.map((priority) => (
              <FormControlLabel
                data-cy={`CreateTicket${priority}Button`}
                key={priority}
                value={priority}
                control={<Radio />}
                label={getPriorityText(priority)}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Collapse in={showDatePicker} sx={{ marginTop: 2, maxWidth: 240 }}>
          <GMDatePicker
            title={translate('CREATE_TICKET.PRIORITY.DATEPICKER_LABEL', 'Required end date')}
            showOptional={false}
            value={state.requiredEndDate ?? addDays(new Date(), 14)}
            onChange={handleCreatedFromChanged}
            minDate={new Date()}
          />
        </Collapse>
      </Fragment>
    ),
  };
};

const SkeletonComponent = () => ({
  radioButtons: <Skeleton width={190} height={48} variant="text" />,
});

const StructureComponent = ({ radioButtons }: { radioButtons: JSX.Element }) => {
  const { translate } = useTranslation();
  const { state } = useCreateTicketContext();

  return (
    <Grid container spacing={1} direction="column">
      {state.currentStep === 'priority' && (
        <Grid item>
          <Typography>{translate('CREATE_TICKET.PRIORITY.LABEL', 'Choose priority')}</Typography>
        </Grid>
      )}
      <Grid item ml={1}>
        {radioButtons}
      </Grid>
    </Grid>
  );
};

const CreateTicketPriorities = skeletonify(
  'CreateTicketLocationLabel',
  ActualComponent,
  SkeletonComponent,
  StructureComponent
);

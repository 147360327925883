import { Button, Card, Divider, Grid } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetDataForAddMachineTimeFlow_ticket_Ticket } from '../../containers/__apollo__/GetDataForAddMachineTimeFlow';
import { useMachineHoursContext } from '../../contexts/add-machine-hours/add-machine-hours-context';
import { useTranslation } from '../../utility/i18n/translation';
import { useGMSnackbar } from '../../utility/snackbar';
import { ActivityIndicator } from '../shared/ActivityIndicator';
import { AddMachineHoursHeader } from './AddMachineHoursHeader';
import { useAddMachineHoursMutation } from './addMachineHoursMutation';
import { AddMachineHoursRow } from './AddMachineHoursRow';
import { AddMachineHoursOverview } from './overview/machine-hours-overview/MachineHoursOverview';

export const AddMachineHoursRoot: FC<GetDataForAddMachineTimeFlow_ticket_Ticket> = (props) => {
  const { translate } = useTranslation();
  const { saveChanges, loading } = useAddMachineHoursMutation(props.id);
  const navigate = useNavigate();
  const { showSnack } = useGMSnackbar();
  const { state, dispatch } = useMachineHoursContext();

  const onAddMachineClicked = () => {
    dispatch({ type: 'set_show_empty_row', showEmptyRow: true });
  };

  const onSavePressed = async () => {
    const result = await saveChanges();
    if (result === 'success') {
      navigate(-1);
    }
    if (result === 'no-request-made') {
      showSnack({
        message: translate(
          'ADD_MACHINE_HOURS.NO_REQUEST_MADE',
          'No additions or deletions detected, so no changes made'
        ),
        variant: 'info',
      });
    }
  };

  return (
    <Card
      sx={{
        py: 5,
        px: 7.5,
      }}
    >
      <Grid container direction="column">
        <Grid item>
          <AddMachineHoursHeader />
        </Grid>
        <Grid item container direction="column" spacing={1} style={{ width: '100%' }}>
          {state.additions.map((addition) => {
            return (
              <Grid key={addition.machine.id} item>
                <AddMachineHoursRow ticketId={props.id} machine={addition.machine} />
                <Divider />
              </Grid>
            );
          })}
          {state.showEmptyRow && (
            <Grid item>
              <AddMachineHoursRow ticketId={props.id} />
            </Grid>
          )}
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
          <Button variant="outlined" color="primary" onClick={onAddMachineClicked} disabled={state.showEmptyRow}>
            {translate('ADD_MACHINE_HOURS.ADD_MACHINE', 'Add machine')}
          </Button>
        </Grid>
        <Grid item style={{ marginTop: 40 }}>
          <AddMachineHoursOverview {...props} />
        </Grid>
        <Grid item style={{ alignSelf: 'flex-end', marginTop: 20 }}>
          <Button variant="contained" color="primary" onClick={onSavePressed} disabled={loading}>
            {loading ? <ActivityIndicator size={32} /> : translate('ADD_MACHINE_HOURS.SAVE', 'Save')}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

import { Button, Grid } from '@mui/material';
import { FC } from 'react';
import { ActivityIndicator } from '../../components/shared/ActivityIndicator';
import { useTranslation } from '../../utility/i18n/translation';
import { useCreateTicketContext } from './create-ticket-context';

interface Props {
  moveForward: () => void;
  loading?: boolean;
  forwardDisabled?: boolean;
}
export const CreateTicketStepButtons: FC<Props> = ({ moveForward, loading = false, forwardDisabled = false }) => {
  const { translate } = useTranslation();
  const { state, dispatch } = useCreateTicketContext();

  const handleNext = () => {
    moveForward();
  };

  const handleBack = () => {
    dispatch({ type: 'step_backward' });
  };

  return (
    <Grid container mt={1} alignItems={'center'}>
      {state.currentStep !== 'equipment' && (
        <Grid item mr={2}>
          <Button size="small" onClick={handleBack} disabled={loading}>
            {translate('CREATE_TICKET.BUTTON_BACK', 'Back')}
          </Button>
        </Grid>
      )}
      <Grid item>
        <Button
          data-cy="CreateTicketNextButton"
          size="small"
          disabled={loading || forwardDisabled}
          color="primary"
          variant="contained"
          onClick={handleNext}
        >
          {translate('CREATE_TICKET.BUTTON_NEXT', 'Next')}
        </Button>
      </Grid>
      {loading && (
        <Grid item ml={3}>
          <ActivityIndicator />
        </Grid>
      )}
    </Grid>
  );
};

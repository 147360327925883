import { gql } from '@apollo/client';
import { Avatar, Grid, Typography } from '@mui/material';
import { ComponentProps, CSSProperties, FC, ReactElement, SyntheticEvent, useCallback } from 'react';
import { LightTooltip } from '../../migration/components/LightTooltip';
import { useAuthenticatedPictureSource } from '../../migration/hooks/images';
import { Icons } from '../../utility/icons';

export const AVATAR_FRAGMENT = gql`
  fragment AvatarFragment on Employee {
    id
    firstName
    lastName
    profilePicture {
      id
      small
    }
  }
`;

export type ProfilePictureProps = Omit<ComponentProps<typeof GMAvatar>, 'style' | 'children'>;

export const GMAvatarWithNameAndEmployeeNumber: FC<{
  avatar: ProfilePictureProps & { id?: string };
  height: number;
}> = ({ avatar, height }) => {
  return (
    <Grid container direction="row" data-cy={`GMAvatarWithNameAndEmployeeNumber-${avatar.id}`}>
      <Grid item style={{ height, width: height }}>
        <GMAvatar {...avatar} />
      </Grid>
      <Grid item xs container direction="column" style={{ marginLeft: 8 }}>
        <Grid item>
          <Typography>
            {avatar.firstName} {avatar.lastName}
          </Typography>
        </Grid>
        {avatar.id && (
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {avatar.id}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export const GMAvatar: FC<
  { firstName: string; lastName: string; pictureUri: string } & {
    style?: CSSProperties;
  }
> = ({ firstName, lastName, pictureUri, style }) => {
  const src = useAuthenticatedPictureSource(pictureUri);

  if (!src) {
    return (
      <Avatar
        style={{
          height: '100%',
          width: '100%',
          ...style,
          fontSize: '1.063rem',
        }}
      >
        {firstName.substr(0, 1)}
        {lastName.substr(0, 1)}
      </Avatar>
    );
  }

  return (
    <Avatar
      style={{
        height: '100%',
        width: '100%',
        ...style,
      }}
      src={src}
    />
  );
};

export const GMImageWithFallback: FC<{
  url?: string;
  fallbackComponent?: ReactElement;
  hoverEnabled?: boolean;
  style?: CSSProperties;
}> = ({ url, fallbackComponent, style, hoverEnabled }) => {
  const src = useAuthenticatedPictureSource(url);

  const imageRightClick = useCallback((event: SyntheticEvent) => {
    event.preventDefault();
    return false;
  }, []);

  const styleWithOverrides: CSSProperties = {
    height: '100%',
    width: '100%',
    ...style,
  };

  if (!src) {
    return fallbackComponent ?? <Icons.ImageNotFound fillOpacity={0.4} style={styleWithOverrides} />;
  }

  return (
    <LightTooltip
      disableFocusListener
      placement="right"
      disableTouchListener
      disableHoverListener={!hoverEnabled}
      title={
        <img
          src={src}
          style={{
            maxWidth: '37.5rem',
            maxHeight: '37.5rem',
            objectFit: 'contain',
          }}
          onContextMenu={imageRightClick}
        />
      }
    >
      <img src={src} style={styleWithOverrides} onContextMenu={imageRightClick} />
    </LightTooltip>
  );
};

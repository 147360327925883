import { createContext, Dispatch, FC, ReactNode, useContext, useReducer } from 'react';
import { BannerActions, createBannerReducer } from './banner-reducer';

export type BannerType = 'warning' | 'default';

export interface BannerState {
  show: boolean;
  text: string;
  type: BannerType;
  innerBanner: boolean;
}

export const initialBannerState: BannerState = {
  show: false,
  text: '',
  type: 'default',
  innerBanner: false,
};

export interface BannerContext {
  state: BannerState;
  dispatch: Dispatch<BannerActions>;
}

const Context = createContext<BannerContext | undefined>(undefined);

export const useBannerContext = (): BannerContext => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('Missing Banner provider');
  }

  return context;
};

export const BannerProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(createBannerReducer, initialBannerState);

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

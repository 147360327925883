import { gql, useQuery } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/Select';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { SubLocationTypeEnum } from '../../__apollo__/types';
import { useGMMutation } from '../../apollo/customApolloHooks';
import { CloseTicketState } from '../../contexts/close-ticket/close-ticket-context';
import { getCurrentTimestampForMiddleware } from '../../utility/date';
import { useTranslation } from '../../utility/i18n/translation';
import { LocationUpdate } from '../ticket-details/TDUpdateLocationDialog';
import { FillWidthLoading } from './FillWidthLoading';
import { GMDropdown } from './GMDropdown';
import { EquipmentLocationFragment } from './__apollo__/EquipmentLocationFragment';
import { EquipmentUpdateLocation, EquipmentUpdateLocationVariables } from './__apollo__/EquipmentUpdateLocation';
import {
  EquipmentUpdateSublocation,
  EquipmentUpdateSublocationVariables,
} from './__apollo__/EquipmentUpdateSublocation';
import { GetEquipmentLocations, GetEquipmentLocationsVariables } from './__apollo__/GetEquipmentLocations';
import { GetEquipmentSublocations, GetEquipmentSublocationsVariables } from './__apollo__/GetEquipmentSublocations';

export const LOCATIONS_QUERY = gql`
  fragment EquipmentLocation on Location {
    id
    description
  }
  query GetEquipmentLocations($input: EquipmentPossibleLocationsQueryInput!) {
    equipmentPossibleLocations(input: $input) {
      id
      ...EquipmentLocation
    }
  }
`;

export const SUBLOCATION_QUERY = gql`
  fragment SublocationFragment on SubLocation {
    id
    description
  }

  query GetEquipmentSublocations($input: EquipmentPossibleSublocationsQueryInput!) {
    equipmentPossibleSublocations(input: $input) {
      ... on EquipmentPossibleSublocationsType {
        possibleSublocations {
          id
          ...SublocationFragment
        }
      }
    }
  }
`;

export const UPDATE_EQUIPMENT_LOCATION = gql`
  mutation EquipmentUpdateLocation($input: EquipmentUpdateLocationInput!) {
    equipmentUpdateLocation(input: $input) {
      id
      ... on IEquipment {
        id
        location {
          ... on Location {
            id
            description
          }
        }
      }
    }
  }
`;

export const UPDATE_EQUIPMENT_SUBLOCATION = gql`
  mutation EquipmentUpdateSublocation($input: EquipmentUpdateSublocationInput!) {
    equipmentUpdateSublocation(input: $input) {
      id
      subLocation {
        ... on SubLocationText {
          id
          value
        }
        ... on SubLocation {
          id
          description
        }
      }
    }
  }
`;

export const EQUIPMENT_LOCATION_FRAGMENT = gql`
  fragment EquipmentLocationFragment on IEquipment {
    id
    location {
      ... on Location {
        id
        description
      }
    }
    subLocation {
      ... on SubLocation {
        id
        description
      }
      ... on SubLocationText {
        id
        value
      }
    }
    sublocationMetaInfo {
      isSublocationMandatory
      sublocationType
    }
  }
`;

type UpdateLocationHookReturn = {
  updateEquipmentLocationData: (locationData: CloseTicketState['location']) => Promise<void>;
  loading: boolean;
};
export const useUpdateEquipmentLocationMutations = (equipmentId?: string): UpdateLocationHookReturn => {
  const [updateLocation, { loading: locationUpdateLoading }] = useGMMutation<
    EquipmentUpdateLocation,
    EquipmentUpdateLocationVariables
  >(UPDATE_EQUIPMENT_LOCATION);

  const [updateSublocation, { loading: sublocationUpdateLoading }] = useGMMutation<
    EquipmentUpdateSublocation,
    EquipmentUpdateSublocationVariables
  >(UPDATE_EQUIPMENT_SUBLOCATION);

  const updateEquipmentLocationData = async ({
    sublocationInputValid,
    newLocationId,
    newSublocationId,
    newSublocationText,
    sublocationDirty,
  }: Parameters<UpdateLocationHookReturn['updateEquipmentLocationData']>['0']) => {
    if (!hasValue(equipmentId)) {
      return;
    }

    if (newLocationId) {
      await updateLocation({
        variables: {
          input: {
            equipmentId,
            newLocationId,
            updatedDate: getCurrentTimestampForMiddleware(),
          },
        },
      });
    }

    if (sublocationInputValid && sublocationDirty) {
      const updateString = newSublocationId ?? newSublocationText;
      await updateSublocation({
        variables: {
          input: {
            equipmentId,
            updatedDate: getCurrentTimestampForMiddleware(),
            sublocationString: updateString ?? '',
          },
        },
      });
    }
  };

  return {
    updateEquipmentLocationData,
    loading: locationUpdateLoading || sublocationUpdateLoading,
  };
};

type EquipmentLocationSectionProps = EquipmentLocationFragment & {
  onLocationSelected: (newLocationId: string) => void;
  initialLocationId?: string;
  onSublocationSelected?: (newLocationId: string) => void;
  initialSublocationId?: string;
  onSublocationTextUpdated?: (newText: string) => void;
  initialSublocationText?: string;
  currentLocationUpdateState?: LocationUpdate;
};

export const EquipmentLocationSection: FC<EquipmentLocationSectionProps> = (props) => {
  const { translate } = useTranslation();
  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Typography>{translate('EQUIPMENT_LOCATION.LOCATION_HEADER', 'Hall/Section')}</Typography>
      </Grid>
      <Grid item data-cy="EquipmentLocationSection-LocationInput">
        <LocationInput {...props} />
      </Grid>
      <Grid item>
        <Typography>{translate('EQUIPMENT_LOCATION.SUBLOCATION_HEADER', 'Room (Row / Place)')}</Typography>
      </Grid>
      <Grid item data-cy="EquipmentLocationSection-SubLocationInput">
        <SubLocationInput {...props} />
      </Grid>
    </Grid>
  );
};

const LocationInput: FC<EquipmentLocationSectionProps> = (props) => {
  const { id, onLocationSelected, initialLocationId } = props;
  const initialId = useMemo(() => {
    if (initialLocationId) {
      return initialLocationId;
    }
    // Changing location to '0' is not allowed, so the default should be empty in that case
    return props.location?.__typename === 'Location' && props.location.id !== '0' ? props.location.id : '';
  }, [initialLocationId, props.location]);

  const [chosenLocationId, setChosenLocationId] = useState(initialId);

  const { data: locations, loading } = useQuery<GetEquipmentLocations, GetEquipmentLocationsVariables>(
    LOCATIONS_QUERY,
    {
      variables: { input: { equipmentId: id } },
    }
  );

  const handleChange = (event: SelectChangeEvent<string>) => {
    setChosenLocationId(event.target.value + '');
    onLocationSelected(event.target.value + '');
  };

  if (loading) {
    return <FillWidthLoading />;
  }

  return (
    <GMDropdown onChange={handleChange} value={chosenLocationId}>
      {locations?.equipmentPossibleLocations
        .filter((location) => location.id !== '0') // Changing location to '0' is not allowed, so it should be removed from the list of options.
        .map((location) => {
          return (
            <MenuItem key={location.id} value={location.id}>
              {location.id}, {location.description}
            </MenuItem>
          );
        })}
    </GMDropdown>
  );
};

const SubLocationInput: FC<EquipmentLocationSectionProps> = (props) => {
  const {
    sublocationMetaInfo: { sublocationType },
  } = props;

  if (sublocationType === SubLocationTypeEnum.SubLocation) {
    return <SubLocationSelect {...props} />;
  } else {
    return <SubLocationFreeTextInput {...props} />;
  }
};

const MAX_FREE_TEXT_CHARACTERS = 8;
const SubLocationFreeTextInput: FC<EquipmentLocationSectionProps> = ({
  sublocationMetaInfo,
  subLocation,
  onSublocationTextUpdated,
  currentLocationUpdateState,
  location,
}) => {
  const { translate } = useTranslation();
  const { isSublocationMandatory } = sublocationMetaInfo;

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onSublocationTextUpdated && onSublocationTextUpdated(e.target.value);
  };

  const textFieldValue = useMemo(() => {
    // Clear sublocation selection when equipment is in location 0 (New Incoming Asset)
    if (location?.__typename === 'Location' && location.id === '0') {
      return undefined;
    }
    if (currentLocationUpdateState && hasValue(currentLocationUpdateState?.newSublocationText)) {
      return currentLocationUpdateState.newSublocationText;
    }

    return subLocation?.__typename === 'SubLocationText' ? subLocation.value : '';
  }, [currentLocationUpdateState, subLocation, location]);

  const showError = useMemo(() => {
    if (!isSublocationMandatory) {
      return false;
    }

    if (currentLocationUpdateState) {
      return !currentLocationUpdateState.sublocationInputValid;
    }

    const originalSublocationValid =
      subLocation?.__typename === 'SubLocationText' && subLocation.value.trim().length > 0;

    return !originalSublocationValid;
  }, [currentLocationUpdateState, isSublocationMandatory, subLocation]);

  return (
    <TextField
      style={{ width: '100%' }}
      variant="outlined"
      value={textFieldValue}
      onChange={handleOnChange}
      inputProps={{ maxLength: MAX_FREE_TEXT_CHARACTERS }}
      error={showError}
      helperText={showError && translate('EQUIPMENT_LOCATION.MISSING_FREE_TEXT', 'You need to provide a sublocation')}
    />
  );
};

const SubLocationSelect: FC<EquipmentLocationSectionProps> = (props) => {
  const { id, onSublocationSelected, initialSublocationId, currentLocationUpdateState } = props;
  const { translate } = useTranslation();

  const initialId = useMemo(() => {
    // Clear sublocation selection when equipment is in location 0 (New Incoming Asset)
    if (props.location?.__typename === 'Location' && props.location.id === '0') {
      return undefined;
    }

    if (initialSublocationId) {
      return initialSublocationId;
    }
    return props.subLocation?.__typename === 'SubLocation' ? props.subLocation.id : '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSublocationId, props.subLocation]);

  const [subLocationId, setSubLocationId] = useState<string | undefined>(initialId);

  const { data: subLocations, loading } = useQuery<GetEquipmentSublocations, GetEquipmentSublocationsVariables>(
    SUBLOCATION_QUERY,
    {
      variables: { input: { equipmentId: id } },
    }
  );

  useEffect(() => {
    if (hasValue(currentLocationUpdateState) && hasValue(currentLocationUpdateState?.sublocationDirty)) {
      setSubLocationId(currentLocationUpdateState.newSublocationId);
    }
  }, [currentLocationUpdateState]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSubLocationId(event.target.value + '');
    onSublocationSelected && onSublocationSelected(event.target.value + '');
  };

  if (loading) {
    return <FillWidthLoading />;
  }

  if (!subLocations || subLocations.equipmentPossibleSublocations.__typename !== 'EquipmentPossibleSublocationsType') {
    return (
      <Typography color="error">
        {translate(
          'EQUIPMENT_LOCATION.SUBLOCATION_ERROR',
          'Could not get sublocations for the equipment, please refresh to try again'
        )}
      </Typography>
    );
  }

  return (
    <GMDropdown onChange={handleChange} value={subLocationId} placeholderText="">
      {subLocations.equipmentPossibleSublocations.possibleSublocations.map((subLocation) => {
        return (
          <MenuItem key={subLocation.id} value={subLocation.id}>
            {subLocation.id}, {subLocation.description}
          </MenuItem>
        );
      })}
    </GMDropdown>
  );
};

/**
 * @generated SignedSource<<4882c2598670eaa97ff7875d92e05c7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BomSparePartRow_sparePart$data = {
  readonly description: string;
  readonly ean: number | null;
  readonly id: string;
  readonly image: {
    readonly uri: string;
  };
  readonly model: string | null;
  readonly procurementInfo: {
    readonly inStock: boolean | null;
  } | null;
  readonly sparePartNumber: string;
  readonly " $fragmentType": "BomSparePartRow_sparePart";
};
export type BomSparePartRow_sparePart$key = {
  readonly " $data"?: BomSparePartRow_sparePart$data;
  readonly " $fragmentSpreads": FragmentRefs<"BomSparePartRow_sparePart">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "processId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BomSparePartRow_sparePart",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "model",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uri",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sparePartNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "processId",
              "variableName": "processId"
            }
          ],
          "kind": "ObjectValue",
          "name": "input"
        }
      ],
      "concreteType": "SparePartProcurementInfo",
      "kind": "LinkedField",
      "name": "procurementInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inStock",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ean",
      "storageKey": null
    }
  ],
  "type": "SparePart",
  "abstractKey": null
};

(node as any).hash = "d8adbf52fdf3ce4af9f82401d667f927";

export default node;

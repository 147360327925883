import { hasValue } from '@lego/mst-error-utilities';
import { Grid } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback } from 'react';
import { usePaginationFragment } from 'react-relay';
import { ActivityIndicator } from '../../components/shared/ActivityIndicator';
import { ExtractNodeFromConnection } from '../../utility-types';
import { useTranslation } from '../../utility/i18n/translation';
import { InfiniteList } from '../components/InfiniteList';
import { skeletonify } from '../skeleton';
import { TicketCard } from '../ticket-list/TicketCard';
import {
  OpenTicketsTabList_equipment$data,
  OpenTicketsTabList_equipment$key,
} from './__generated__/OpenTicketsTabList_equipment.graphql';

type Item = ExtractNodeFromConnection<OpenTicketsTabList_equipment$data['tickets']>;

const ActualComponent: FC<{
  equipment?: OpenTicketsTabList_equipment$key | null;
}> = (props) => {
  const { translate } = useTranslation();
  const { equipment: equipmentRef } = props;
  const { data, ...pagination } = usePaginationFragment(
    graphql`
      fragment OpenTicketsTabList_equipment on Equipment
      @refetchable(queryName: "OpenTicketsTabListRefetchQuery")
      @argumentDefinitions(first: { type: "Int", defaultValue: 20 }, after: { type: "ID" }) {
        tickets(first: $first, after: $after, input: { status: [InProgress, NotStarted, Released] })
          @connection(key: "OpenTicketsTabList_tickets") {
          edges {
            node {
              id
              ...TicketCard_ticket
            }
          }
        }
      }
    `,
    equipmentRef ?? null
  );

  const emptyLabel = translate('OPEN_TICKETS_TAB.LIST.EMPTY', 'No open tickets');

  const errorLabel = translate('OPEN_TICKETS_TAB.LIST.ERROR', 'Error fetching open tickets');

  const itemKeyExtractor = useCallback((item: Item) => item.id, []);

  const itemRender = useCallback((item: Item) => <TicketCard.Suspense ticket={item} />, []);

  const filtered = data?.tickets?.edges.filter(hasValue).map(({ node }) => node);

  return (
    <InfiniteList<Item>
      {...pagination}
      emptyLabel={emptyLabel}
      errorLabel={errorLabel}
      items={filtered}
      itemKeyExtractor={itemKeyExtractor}
      itemRender={itemRender}
      itemSpacing={2}
    />
  );
};

const SkeletonComponent: FC = () => (
  <Grid container>
    <Grid item xs alignSelf="center">
      <ActivityIndicator />
    </Grid>
  </Grid>
);

export const OpenTicketsTabList = skeletonify('OpenTicketsTabList', ActualComponent, SkeletonComponent);

import { hasValue } from '@lego/mst-error-utilities';
import { Skeleton } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { TextWithLabel } from '../../../components/shared/TextWithLabel';
import { useFormatDateWithLocale } from '../../../utility/date';
import { useTranslation } from '../../../utility/i18n/translation';
import { skeletonify } from '../../skeleton';
import { MouldNextProductionStart_mould$key } from './__generated__/MouldNextProductionStart_mould.graphql';

const ActualComponent: FC<{
  mould: MouldNextProductionStart_mould$key;
}> = ({ mould: mouldRef }) => {
  const { translate } = useTranslation();
  const { nextProductionOrder } = useFragment(
    graphql`
      fragment MouldNextProductionStart_mould on Mould {
        nextProductionOrder {
          priority
          plannedStartAt {
            date
            time
          }
        }
      }
    `,
    mouldRef
  );

  const date = nextProductionOrder?.plannedStartAt?.date;
  const time = nextProductionOrder?.plannedStartAt?.time;
  const priority = nextProductionOrder?.priority;
  const formatedDateTime = useFormatDateWithLocale(
    hasValue(nextProductionOrder?.plannedStartAt) ? `${date}T${time}` : null,
    'dd/MM/yyyy, H.mm'
  );

  return (
    <TextWithLabel
      text={formatedDateTime ? `${priority}: ${formatedDateTime} ` : '---'}
      label={translate('TICKET_DETAILS.SISTER_MOULDS_TAB.NEXT_PRODUCTION_START', 'Next production start')}
    />
  );
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

export const MouldNextProductionStart = skeletonify('MouldNextProductionStart', ActualComponent, SkeletonComponent);

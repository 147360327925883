import { Grid, Skeleton, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { Icons } from '../../../utility/icons';
import { skeletonify } from '../../skeleton';
import { EquipmentNumber_equipment$key } from './__generated__/EquipmentNumber_equipment.graphql';

const ActualComponent: FC<{
  equipment: EquipmentNumber_equipment$key | null;
}> = (props) => {
  const { equipment: equipmentRef } = props;

  const data = useFragment(
    graphql`
      fragment EquipmentNumber_equipment on Equipment {
        equipmentNumber
      }
    `,
    equipmentRef
  );

  if (!data) {
    return null;
  }

  return <Typography variant="body1">{data.equipmentNumber.toString()}</Typography>;
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

const StructureComponent: FC<{ children: any }> = ({ children }) => (
  <Grid container direction="row" spacing={1}>
    <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
      <Icons.EquipmentId />
    </Grid>
    <Grid item>{children}</Grid>
  </Grid>
);

export const EquipmentNumber = skeletonify('EquipmentNumber', ActualComponent, SkeletonComponent, StructureComponent);

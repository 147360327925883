import { FormGroup, Grid, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { useTranslation } from '../../utility/i18n/translation';
import { TicketSearchBooleanFilterWithFacet } from './TicketSearchBooleanFilterComponents';
import { useTicketSearchFilterContext } from './TicketSearchFilters';
import {
  TicketSearchMouldStatusFilter_ticketsConnectionFacets$data,
  TicketSearchMouldStatusFilter_ticketsConnectionFacets$key,
} from './__generated__/TicketSearchMouldStatusFilter_ticketsConnectionFacets.graphql';
import { MouldStatusInput } from './__generated__/TicketSearchQuery.graphql';

type Facets = NonNullable<TicketSearchMouldStatusFilter_ticketsConnectionFacets$data['mouldStatus']>;

const MouldStatusCode: MouldStatusInput[] = [
  'New',
  'Operational',
  'Other',
  'Qualification',
  'RampUp',
  'Transit',
  'WornOut',
];

export interface TicketSearchMouldStatusFilterProps {
  ticketsConnectionFacets: TicketSearchMouldStatusFilter_ticketsConnectionFacets$key | null;
}

export const TicketSearchMouldStatusFilter: FC<TicketSearchMouldStatusFilterProps> = (props) => {
  const { ticketsConnectionFacets: facetsRef } = props;
  const data = useFragment(
    graphql`
      fragment TicketSearchMouldStatusFilter_ticketsConnectionFacets on TicketsConnectionFacets {
        mouldStatus {
          count
          value
        }
      }
    `,
    facetsRef
  );

  const {
    filters: { mouldStatus: selectedStatuses = [] },
    onChange,
  } = useTicketSearchFilterContext();
  const facets = data?.mouldStatus?.map(({ count, value }) => ({ count, value })) ?? [];

  const handleChange = (mouldStatus: MouldStatusInput) => (enabled: boolean) => {
    const filteredStatuses = selectedStatuses?.filter((p) => p !== mouldStatus) ?? [];
    onChange((prev) => ({
      ...prev,
      mouldStatus: enabled ? filteredStatuses.concat(mouldStatus) : filteredStatuses,
    }));
  };

  const { translate } = useTranslation();
  const { getMouldStatusLabelFromEnum } = useMouldStatusCodeLabel();

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="subtitle2">
          {translate('TICKET_LIST.FILTERS.MOULD_STATUS.TITLE', 'Mould status')}
        </Typography>
      </Grid>
      <Grid item ml={2}>
        <FormGroup>
          {MouldStatusCode.map((mouldStatus) => (
            <TicketSearchBooleanFilterWithFacet<Facets[0]>
              key={mouldStatus}
              label={getMouldStatusLabelFromEnum(mouldStatus)}
              enabled={selectedStatuses.includes(mouldStatus)}
              onChange={handleChange(mouldStatus)}
              facetValue={{ facets: facets, value: mouldStatus }}
            />
          ))}
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export const useMouldStatusCodeLabel = () => {
  const { translate } = useTranslation();

  const getMouldStatusLabelFromEnum = (status: MouldStatusInput) => {
    switch (status) {
      case 'New':
        return translate('TICKET_LIST.FILTERS.MOULD_STATUS.ENUM_VALUES.NEW', 'New');
      case 'Operational':
        return translate('TICKET_LIST.FILTERS.MOULD_STATUS.ENUM_VALUES.OPERATIONAL', 'Operational');
      case 'Other':
        return translate('TICKET_LIST.FILTERS.MOULD_STATUS.ENUM_VALUES.OTHER', 'Other');
      case 'Qualification':
        return translate('TICKET_LIST.FILTERS.MOULD_STATUS.ENUM_VALUES.QUALIFICATION', 'Qualification');
      case 'RampUp':
        return translate('TICKET_LIST.FILTERS.MOULD_STATUS.ENUM_VALUES.RAMP_UP', 'Ramp up');
      case 'Transit':
        return translate('TICKET_LIST.FILTERS.MOULD_STATUS.ENUM_VALUES.TRANSIT', 'Transit');
      case 'WornOut':
        return translate('TICKET_LIST.FILTERS.MOULD_STATUS.ENUM_VALUES.WORN_OUT', 'Worn Out');
      case 'Unknown':
      default:
        return translate('TICKET_LIST.FILTERS.MOULD_STATUS.ENUM_VALUES.UNKNOWN', 'Unknown');
    }
  };
  return { getMouldStatusLabelFromEnum };
};

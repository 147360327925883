import { hasValue } from '@lego/mst-error-utilities';
import { Box, Container, Divider, Grid, styled } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { Dispatch, FC, useEffect, useRef, useState, useTransition } from 'react';
import {
  Disposable,
  PreloadedQuery,
  RefetchFnDynamic,
  useFragment,
  usePaginationFragment,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay';
import { FillWidthLoading } from '../../components/shared/FillWidthLoading';
import { useAreaAndProcessContext } from '../../contexts/area';
import { PageErrorBoundary } from '../PageErrorBoundary';
import { skeletonify } from '../skeleton';
import { TicketList } from '../ticket-list/TicketList';
import { TicketSearchFilterProvider, TicketSearchFilters, useTicketSearchFilterContext } from './TicketSearchFilters';
import { TicketSearchHeader } from './TicketSearchHeader';
import TicketSearchQuery, {
  TicketSearchQuery as TicketSearchQueryType,
} from './__generated__/TicketSearchQuery.graphql';
import { TicketSearchRefetchQuery } from './__generated__/TicketSearchRefetchQuery.graphql';
import { TicketSearch_Extras_ticketsConnection$key } from './__generated__/TicketSearch_Extras_ticketsConnection.graphql';
import { TicketSearch_Filters_plant$key } from './__generated__/TicketSearch_Filters_plant.graphql';
import { TicketSearch_Filters_process$key } from './__generated__/TicketSearch_Filters_process.graphql';
import { TicketSearch_Filters_query$key } from './__generated__/TicketSearch_Filters_query.graphql';
import { TicketSearch_process$key } from './__generated__/TicketSearch_process.graphql';

export const FullHeightGrid = styled(Grid)(() => {
  return {
    height: '100%',
    width: '100%',
    flexWrap: 'nowrap',
  };
});

const TicketSearchLoaderActual: FC<{
  query: PreloadedQuery<TicketSearchQueryType>;
}> = (props) => {
  const { query: queryRef, ...rest } = props;
  const query = usePreloadedQuery(
    graphql`
      query TicketSearchQuery($plantId: ID!, $processId: ID!, $input: ProcessTicketsInput!) {
        process: node(id: $processId) @required(action: THROW) {
          ... on Process {
            ...TicketSearch_process @arguments(tickets: $input) #@defer
            ...TicketSearch_Filters_process #@defer
          }
        }
        plant: node(id: $plantId) {
          ... on Plant {
            ...TicketSearch_Filters_plant #@defer
          }
        }
        ...TicketSearch_Filters_query #@defer
      }
    `,
    queryRef
  );

  return <TicketSearchPage.Suspense {...rest} process={query.process} plant={query.plant} query={query} />;
};

const TicketSearchLoaderSkeleton = () => <TicketSearchPage.Skeleton />;

const Loader = skeletonify('TicketSearchLoader', TicketSearchLoaderActual, TicketSearchLoaderSkeleton);

const useTicketSearch = (
  refetch: RefetchFnDynamic<TicketSearchRefetchQuery, TicketSearch_process$key>,
  searchTerm: string
): boolean => {
  const fetchRef = useRef<Disposable>();
  const { input } = useTicketSearchFilterContext();

  const [isInFlight, startTransition] = useTransition();

  useEffect(() => {
    startTransition(() => {
      fetchRef.current?.dispose();
      fetchRef.current = refetch({ tickets: { ...input, searchTerm }, skip: false }, { fetchPolicy: 'network-only' });
    });
  }, [refetch, input, searchTerm]);

  return isInFlight;
};

const TicketSearchPageActual = (props: {
  process: TicketSearch_process$key & TicketSearch_Filters_process$key;
  plant: TicketSearch_Filters_plant$key | null;
  query: TicketSearch_Filters_query$key | null;
}) => {
  const { process: processRef, plant, query } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const { data, refetch, ...pagination } = usePaginationFragment<TicketSearchRefetchQuery, TicketSearch_process$key>(
    graphql`
      fragment TicketSearch_process on Process
      @refetchable(queryName: "TicketSearchRefetchQuery")
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        after: { type: "ID" }
        tickets: { type: "ProcessTicketsInput!" }
        skip: { type: "Boolean", defaultValue: true }
      ) {
        tickets(first: $first, after: $after, input: $tickets)
          @skip(if: $skip)
          @connection(key: "TicketSearch_process_tickets") {
          edges {
            node {
              ...TicketList_ticket
            }
          }
          ...TicketSearch_Extras_ticketsConnection
        }
      }
    `,
    processRef
  );

  const isSearching = useTicketSearch(refetch, searchTerm);

  const tickets = data.tickets?.edges.filter(hasValue).map(({ node }) => node);

  return {
    searchTerm,
    plant,
    query,
    process: processRef,
    onSearchChange: setSearchTerm,
    list: data.tickets === undefined ? <FillWidthLoading /> : <TicketList tickets={tickets} {...pagination} />,
    ticketsConnection: data.tickets ?? null,
    loading: isSearching && data.tickets !== undefined,
  };
};

const TicketSearchPageSkeleton = () => ({
  list: <FillWidthLoading />,
  ticketsConnection: null,
  plant: null,
  query: null,
  process: null,
  disabled: true,
});

const TicketSearchPageStructure = (props: {
  list: JSX.Element;
  ticketsConnection: TicketSearch_Extras_ticketsConnection$key | null;
  plant: TicketSearch_Filters_plant$key | null;
  process: TicketSearch_Filters_process$key | null;
  query: TicketSearch_Filters_query$key | null;
  onSearchChange?: Dispatch<string>;
  searchTerm?: string;
  loading?: boolean;
  disabled?: boolean;
}) => {
  const {
    list,
    ticketsConnection: connectionRef,
    plant: plantRef,
    process: processRef,
    query: queryRef,
    onSearchChange,
    searchTerm,
    loading,
    disabled = false,
  } = props;

  const extras = useFragment(
    graphql`
      fragment TicketSearch_Extras_ticketsConnection on TicketsConnection {
        ...TicketSearchHeader_ticketsConnection
        ...TicketSearchFilters_ticketsConnection
      }
    `,
    connectionRef
  );

  const plant = useFragment(
    graphql`
      fragment TicketSearch_Filters_plant on Plant {
        ...TicketSearchFilters_plant
      }
    `,
    plantRef
  );

  const process = useFragment(
    graphql`
      fragment TicketSearch_Filters_process on Process {
        ...TicketSearchFilters_process
      }
    `,
    processRef
  );

  const query = useFragment(
    graphql`
      fragment TicketSearch_Filters_query on Query {
        ...TicketSearchFilters_query
      }
    `,
    queryRef
  );

  return (
    <Box flexDirection="column" display="flex" height="100%">
      <Grid container pb={2} pt={2}>
        <Grid item xs={3} />
        <Grid item xs mr={2}>
          <TicketSearchHeader
            ticketsConnection={extras}
            onChange={onSearchChange}
            initialSearchTerm={searchTerm ?? undefined}
            loading={loading}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Divider />

      <Box flex="1" minHeight={0}>
        <FullHeightGrid container direction="row" spacing={2}>
          <Grid item xs={3}>
            <Box height="100%" overflow="auto" pt={2} pl={2}>
              <TicketSearchFilters ticketsConnection={extras} plant={plant} process={process} query={query} />
              <Box height={200} />
            </Box>
          </Grid>
          <FullHeightGrid item xs>
            <Box height="100%" overflow="auto" pt={2}>
              {/* TODO: Show loading indicator when loading */}
              {list}
            </Box>
          </FullHeightGrid>
        </FullHeightGrid>
      </Box>
    </Box>
  );
};

const TicketSearchPage = skeletonify(
  'TicketSearchPage',
  TicketSearchPageActual,
  TicketSearchPageSkeleton,
  TicketSearchPageStructure
);

const TicketSearchLoader: FC = () => {
  const [queryRef, loadQuery] = useQueryLoader<TicketSearchQueryType>(TicketSearchQuery);

  const { relayProcessId: processId, relayPlantId: plantId } = useAreaAndProcessContext();
  const { input } = useTicketSearchFilterContext();

  useEffect(
    () => {
      // TODO: We need to ensure process has been selected for this route
      if (!processId) {
        return;
      }
      return loadQuery(
        { plantId, processId, input },
        {
          fetchPolicy: 'store-and-network',
        }
      );
    },
    // We do not want to run this when `input` changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadQuery, plantId, processId]
  );

  return (
    <Container maxWidth="xl" sx={{ height: '100%' }}>
      <PageErrorBoundary
        onReset={() => {
          // TODO: We need to ensure process has been selected for this route
          if (!processId) {
            return;
          }
          return loadQuery({
            plantId,
            processId,
            input,
          });
        }}
      >
        {queryRef && <Loader.Suspense query={queryRef} />}
      </PageErrorBoundary>
    </Container>
  );
};

export const TicketSearch = () => (
  <TicketSearchFilterProvider>
    <TicketSearchLoader />
  </TicketSearchFilterProvider>
);

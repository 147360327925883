import { Skeleton } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { TextWithLabel } from '../../../components/shared/TextWithLabel';
import { useTranslation } from '../../../utility/i18n/translation';
import { skeletonify } from '../../skeleton';
import { MouldType_mould$key } from './__generated__/MouldType_mould.graphql';

const ActualComponent: FC<{
  mould: MouldType_mould$key;
}> = ({ mould: mouldRef }) => {
  const { translate } = useTranslation();
  const data = useFragment(
    graphql`
      fragment MouldType_mould on Mould {
        type
      }
    `,
    mouldRef
  );

  return (
    <TextWithLabel
      text={data.type || ''}
      label={translate('TICKET_DETAILS.SISTER_MOULDS_TAB.MOULD_TYPE', 'Mould type')}
    />
  );
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

export const MouldType = skeletonify('MouldType', ActualComponent, SkeletonComponent);

import { gql } from '@apollo/client';
import { Card, Container, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useGMQuery } from '../apollo/customApolloHooks';
import { ARDHeader } from '../components/add-repair-documentation/ARDHeader';
import { ARDStepper } from '../components/add-repair-documentation/ARDStepper';
import { ARDSidebar, ARD_SIDEBAR_FRAGMENT } from '../components/add-repair-documentation/sidebar-widgets/ARDSidebar';
import { TDHeader } from '../components/ticket-details/TDHeader';
import { AddRepairDescriptionProvider } from '../contexts/add-repair-description/add-repair-description-context';
import { usePrefetchRepairDescriptionData } from '../contexts/add-repair-description/add-repair-description-gql-queries';
import { RouteRequiringPermission } from '../Router';
import { PERMISSIONS_CAN_ADD_REPAIR_DESCRIPTION } from '../utility/permissions/ticket';
import { usePermissions } from '../utility/permissions/usePermissions';
import { useTicketQueryTypeCheck } from './TicketByIdTypecheckHook';
import {
  GetDataForRepairDescriptionFlow,
  GetDataForRepairDescriptionFlowVariables,
  GetDataForRepairDescriptionFlow_ticket_Ticket,
} from './__apollo__/GetDataForRepairDescriptionFlow';

export const ADD_REPAIR_FLOW_QUERY = gql`
  query GetDataForRepairDescriptionFlow($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        ...AddRepairDocumentationSidebarFragment
        ...CanAddRepairDescription
        repairDocumentation {
          id
          repairFlowVariant
        }
      }
    }
  }
  ${ARD_SIDEBAR_FRAGMENT}
  ${PERMISSIONS_CAN_ADD_REPAIR_DESCRIPTION}
`;

export const AddRepairDocumentation: FC = () => {
  const { id } = useParams() as { id: string };

  const { data, loading: headerAndSidebarLoading } = useGMQuery<
    GetDataForRepairDescriptionFlow,
    GetDataForRepairDescriptionFlowVariables
  >(ADD_REPAIR_FLOW_QUERY, {
    variables: { input: { id } },
  });

  const {
    ticket: { canAddRepairDescription },
  } = usePermissions();

  const checked = useTicketQueryTypeCheck<GetDataForRepairDescriptionFlow_ticket_Ticket>({
    data,
    loading: headerAndSidebarLoading,
  });

  if (checked.type === 'errorOrLoadingComponent') {
    return checked.component;
  }

  const allowed = canAddRepairDescription(checked.data);

  return (
    <RouteRequiringPermission allowed={allowed}>
      <AddRepairDescriptionProvider>
        <TDHeader {...checked.data} showFab={false} />
        <InnerComponent data={checked.data} />
      </AddRepairDescriptionProvider>
    </RouteRequiringPermission>
  );
};

const InnerComponent: FC<{
  data: GetDataForRepairDescriptionFlow_ticket_Ticket;
}> = ({ data }) => {
  const theme = useTheme();
  const sideBySide = useMediaQuery(theme.breakpoints.up('lg'));
  usePrefetchRepairDescriptionData(data.id);

  return (
    <Container
      maxWidth={false}
      style={{
        overflow: 'auto',
        width: '100%',
        display: 'flex',
        marginTop: 110,
      }}
    >
      <Grid
        container
        direction={sideBySide ? 'row' : 'column'}
        spacing={2}
        style={{
          height: '100%',
          flexWrap: 'nowrap',
        }}
      >
        {/* Add repairs */}
        <Grid
          item
          style={{
            overflow: sideBySide ? 'auto' : undefined,
            flex: sideBySide ? 2 : 1,
          }}
        >
          <Card style={{ padding: 20 }}>
            <Grid container>
              <ARDHeader />
              <ARDStepper />
            </Grid>
          </Card>
        </Grid>

        {sideBySide && (
          <Divider
            orientation={'vertical'}
            style={{
              width: '3px',
            }}
          />
        )}

        {/* Sidebar with info */}
        <Grid item style={{ overflow: sideBySide ? 'auto' : undefined, flex: 1 }}>
          <ARDSidebar {...data} />
        </Grid>
      </Grid>
    </Container>
  );
};

import { Grid, Typography } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { FC } from 'react';
import { GMDatePicker } from '../../components/shared/GMDatePicker';
import { useTranslation } from '../../utility/i18n/translation';
import { LocalTimestampInput } from './__generated__/EquipmentHistoryTabQuery.graphql';

const MIN_DATE = new Date(1990, 1, 1);

export const EquipmentHistoryDateFilter: FC<{
  completedAfterDate: LocalTimestampInput | null;
  onCompletedAfterDateChanged: (date: Date | null) => void;
  completedBeforeDate: LocalTimestampInput | null;
  onCompletedBeforeDateChanged: (date: Date | null) => void;
}> = ({ completedAfterDate, completedBeforeDate, onCompletedAfterDateChanged, onCompletedBeforeDateChanged }) => {
  const { translate } = useTranslation();
  const today = new Date();

  const handleCompletedAfterDateChanged = (date: Date | null) =>
    onCompletedAfterDateChanged(date ? startOfDay(date) : null);

  const handleCompletedBeforeDateChanged = (date: Date | null) =>
    onCompletedBeforeDateChanged(date ? endOfDay(date) : null);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="subtitle2">
          {translate('TICKET_LIST.FILTERS.COMPLETED_DATE.COMPLETED_DATE', 'Completed date')}
        </Typography>
      </Grid>
      <Grid item>
        <GMDatePicker
          title={translate('TICKET_LIST.FILTERS.COMPLETED_DATE.COMPLETED_FROM', 'From')}
          showOptional={false}
          value={completedAfterDate?.date ?? null}
          onChange={handleCompletedAfterDateChanged}
          minDate={MIN_DATE}
          maxDate={completedBeforeDate?.date ? new Date(completedBeforeDate.date) : today}
        />
      </Grid>
      <Grid item>
        <GMDatePicker
          title={translate('TICKET_LIST.FILTERS.COMPLETED_DATE.COMPLETED_TO', 'To')}
          showOptional={false}
          value={completedBeforeDate?.date ?? null}
          onChange={handleCompletedBeforeDateChanged}
          minDate={completedAfterDate?.date ? new Date(completedAfterDate.date) : MIN_DATE}
          maxDate={today}
        />
      </Grid>
    </Grid>
  );
};

import { useTheme } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import { useTicketFlowColor_ticket$key } from './__generated__/useTicketFlowColor_ticket.graphql';

export const useTicketFlowColor = (ticketRef: useTicketFlowColor_ticket$key): string | undefined => {
  const { flowType } = useFragment(
    graphql`
      fragment useTicketFlowColor_ticket on Ticket {
        ... on MouldTicket {
          flowType
        }
      }
    `,
    ticketRef
  );

  const {
    palette: {
      success: { main: greenColor },
      warning: { main: yellowColor },
    },
  } = useTheme();

  if (flowType === 'Green') {
    return greenColor;
  }

  if (
    flowType === 'YellowToProduction' ||
    flowType === 'YellowToQA' ||
    flowType === 'YellowRepair' ||
    flowType === 'YellowFunctionalTest'
  ) {
    return yellowColor;
  }

  return undefined;
};

import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { ComponentProps, ReactElement } from 'react';
import { useTranslation } from '../../utility/i18n/translation';

type ARDAutocompleteProps<T> = {
  options: T[];
  onChange: (value: T | null) => void;
  chosenValue?: T | null;
  cyIdentifier?: string;
};

export const ARDAutocomplete = <T extends { description: string }>({
  onChange,
  options,
  chosenValue,
  cyIdentifier,
}: ARDAutocompleteProps<T>): ReactElement => {
  const onValueSelected: ComponentProps<typeof Autocomplete>['onChange'] = (_event, value) => {
    if (!Array.isArray(value)) {
      onChange(value as T);
    }
  };

  const { translate } = useTranslation();

  return (
    <Autocomplete
      value={chosenValue ?? null}
      options={options ?? []}
      getOptionLabel={(option) => option.description}
      fullWidth
      onChange={onValueSelected}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            data-cy={`ARDAutoComplete${cyIdentifier ? '-' + cyIdentifier : ''}`}
            variant="outlined"
            placeholder={translate('ADD_REPAIR_DOCUMENTATION.ROW.AUTOCOMPLETE_PLACEHOLDER', 'Search')}
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
};

import { useEffect } from 'react';

export const useScript = (url: string | undefined) => {
  useEffect(() => {
    if (!url || document.querySelector(`script[src="${url}"]`)) {
      return;
    }

    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [url]);
};

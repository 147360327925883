import { hasValue } from '@lego/mst-error-utilities';
import { Grid, TextField, Typography } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { useAddRepairDescriptionContext } from '../../../contexts/add-repair-description/add-repair-description-context';
import { useTranslation } from '../../../utility/i18n/translation';
import { ARDNavigationButtons } from '../ARDNavigationButtons';

export const ARDAddComment: FC = () => {
  const { translate } = useTranslation();
  const {
    state: { repairComment, currentStep },
    dispatch,
  } = useAddRepairDescriptionContext();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    dispatch({ type: 'set_repair_comment', comment: newValue });
  };

  if (!(currentStep === 'comment') && hasValue(repairComment)) {
    return (
      <Typography component={'span'} style={{ wordWrap: 'normal' }}>
        {repairComment}
      </Typography>
    );
  }

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Typography>
          {translate(
            'ADD_REPAIR_DOCUMENTATION.ADD_COMMENT.HELPER_TEXT',
            'Add a comment to your documentation (optional)'
          )}
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          defaultValue={repairComment}
          rows={4}
          variant="outlined"
          multiline
          fullWidth
          data-cy="ARDAddComment"
          onChange={onChange}
          inputProps={{ maxLength: 10000 }} // Sanity limit
          label={translate('ADD_REPAIR_DOCUMENTATION.ADD_COMMENT.INPUT_PLACEHOLDER', 'Comment')}
        />
      </Grid>
      <Grid item>
        <ARDNavigationButtons />
      </Grid>
    </Grid>
  );
};

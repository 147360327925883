import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { Icons } from '../../utility/icons';
import { CollapsibleWidget } from '../shared/CollapsibleWidget';
import { TDPackingRepairRowContent } from '../ticket-details/TDRepairDescription';
import { RepairDocumentationSubEquipment } from '../ticket-details/__apollo__/RepairDocumentationSubEquipment';

export const CTRepairDocumentationRowPacking: FC<RepairDocumentationSubEquipment & { padding?: number }> = ({
  components,
  description,
  padding,
}) => {
  return (
    <CollapsibleWidget componentWhenClosed={<RowHeader title={description} />} padding={padding ?? 8}>
      <Grid container style={{ paddingLeft: 45, paddingRight: 40, marginBottom: 20 }} spacing={2}>
        <TDPackingRepairRowContent components={components} />
      </Grid>
    </CollapsibleWidget>
  );
};

const RowHeader: FC<{ title: string }> = ({ title }) => {
  return (
    <Grid container spacing={2} style={{ flex: 1 }}>
      <Grid item>
        <Icons.SubEquipment />
      </Grid>
      <Grid item>
        <Typography>{title}</Typography>
      </Grid>
    </Grid>
  );
};

import { Button, Grid, SxProps, Typography, useTheme } from '@mui/material';
import { FC, ReactElement } from 'react';

export const CmsTicketType: FC<{
  icon: ReactElement;
  label: string;
  selected: boolean;
  onClick: () => void;
}> = ({ icon, label, selected, onClick }) => {
  const { palette } = useTheme();
  const baseColor = '#32323299';
  const baseStyle: SxProps = {
    width: '130px',
    height: '130px',
    textAlign: 'center',
    border: `2px solid ${baseColor}`,
    color: baseColor,
    boxShadow: '0px 8px 24px 0px #00000026',
    '&:hover': {
      border: `2px solid ${palette.primary.main}`,
      background: palette.background.paper,
    },
  };
  const selectedStyle: SxProps = {
    ...baseStyle,
    boxShadow: 'unset',
    borderColor: palette.primary.main,
    color: '#323232',
  };

  return (
    <Button variant="outlined" sx={selected ? selectedStyle : baseStyle} onClick={onClick} disableRipple>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Grid item style={{ marginRight: 8 }}>
            {icon}
          </Grid>
          <Grid container item direction="row" justifyContent="center">
            <Grid item>
              <Typography
                variant="caption"
                style={{
                  textTransform: 'none',
                  textAlign: 'center',
                }}
              >
                {label}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Button>
  );
};

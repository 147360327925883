/**
 * @generated SignedSource<<d2b88b0d664af794be34bab184f3ffe5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentHistoryFilters_ticketsConnection$data = {
  readonly facets: {
    readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryDamageFilter_ticketsConnectionFacets" | "EquipmentHistoryPriorityFilter_ticketsConnectionFacets">;
  };
  readonly " $fragmentType": "EquipmentHistoryFilters_ticketsConnection";
};
export type EquipmentHistoryFilters_ticketsConnection$key = {
  readonly " $data"?: EquipmentHistoryFilters_ticketsConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryFilters_ticketsConnection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentHistoryFilters_ticketsConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketsConnectionFacets",
      "kind": "LinkedField",
      "name": "facets",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquipmentHistoryPriorityFilter_ticketsConnectionFacets"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquipmentHistoryDamageFilter_ticketsConnectionFacets"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TicketsConnection",
  "abstractKey": null
};

(node as any).hash = "07cce3d189e98501df3bebc78e9d15e3";

export default node;

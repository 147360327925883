import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useDateFromMiddlewareWithLocale } from '../../utility/date';
import { useTranslation } from '../../utility/i18n/translation';
import { Icons } from '../../utility/icons';
import { ConfidentialitySearchRow } from '../image-search/__apollo__/ConfidentialitySearchRow';
import { GMImageWithFallback } from '../shared/GMImageComponents';

export const ConfidentialityDetailsCard: FC<{
  data: ConfidentialitySearchRow;
  onClosePressed: () => void;
}> = ({ data, onClosePressed }) => {
  return (
    <Grid container flexDirection="column" sx={{ px: 6 }}>
      <HeaderRowWithNav onClosePressed={onClosePressed} />
      <Grid item container flexDirection="row" spacing={9}>
        <Grid item xs md={3}>
          <Image url={data.imageInformation.image.large} />
        </Grid>
        <Grid item md={9} sx={{ mt: 6 }}>
          <Information {...data} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const Image: FC<{ url: string }> = ({ url }) => {
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const { translate } = useTranslation();
  const {
    shape: { borderRadius },
  } = useTheme();

  const onPreviewButtonPressed = useCallback(() => {
    setPreviewDialogOpen(true);
  }, []);

  const onCloseClicked = useCallback(() => {
    setPreviewDialogOpen(false);
  }, []);

  return (
    <Grid container justifyContent="end">
      <PreviewDialog open={previewDialogOpen} onCloseClicked={onCloseClicked} url={url} />
      <Grid
        item
        justifyContent="center"
        alignItems="center"
        sx={{
          borderColor: 'secondary.main',
          border: 2,
          borderRadius,
          overflow: 'hidden',
        }}
      >
        <GMImageWithFallback url={url} style={{ objectFit: 'cover' }} />
      </Grid>
      <Grid item>
        <Button onClick={onPreviewButtonPressed} variant="text" startIcon={<Icons.PopupPreview color="primary" />}>
          {translate('CONFIDENTIALITY_DETAILS.HEADER_CARD.PREVIEW_BUTTON', 'Preview')}
        </Button>
      </Grid>
    </Grid>
  );
};

const PreviewDialog: FC<{
  url: string;
  open: boolean;
  onCloseClicked: () => void;
}> = ({ url, open, onCloseClicked }) => {
  return (
    <Dialog open={open} fullScreen onClose={onCloseClicked}>
      <Grid container flexDirection="column">
        <Grid item alignSelf="end">
          <IconButton onClick={onCloseClicked}>
            <CloseIcon style={{ fontSize: 40 }} />
          </IconButton>
        </Grid>
        <Grid item>
          <GMImageWithFallback url={url} style={{ objectFit: 'contain' }} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

const Information: FC<ConfidentialitySearchRow> = ({
  id,
  imageInformation: { createdAt, createdBy },
  area,
  process,
}) => {
  const { translate } = useTranslation();
  const { format } = useDateFromMiddlewareWithLocale();
  return (
    <Grid container flexDirection="column" spacing={2} justifyContent="center" alignSelf="center">
      <Grid item>
        <RowWithLabel
          label={translate('CONFIDENTIALITY_DETAILS.HEADER_CARD.LABELS.CREATED_BY', 'Created by:')}
          value={createdBy.__typename === 'Employee' ? createdBy.id : ''}
        />
      </Grid>
      <Grid item>
        <RowWithLabel
          label={translate('CONFIDENTIALITY_DETAILS.HEADER_CARD.LABELS.CREATED_AT', 'Created time:')}
          value={format(createdAt, 'Pp')}
        />
      </Grid>
      <Grid item>
        <RowWithLabel
          label={translate('CONFIDENTIALITY_DETAILS.HEADER_CARD.LABELS.PLANT', 'Plant:')}
          value={`${area?.name} (${area?.plant.id})`}
        />
      </Grid>
      <Grid item>
        <RowWithLabel
          label={translate('CONFIDENTIALITY_DETAILS.HEADER_CARD.LABELS.PROCESS', 'Process area:')}
          value={process?.name ?? ''}
        />
      </Grid>
      <Grid item>
        <RowWithLabel
          label={translate('CONFIDENTIALITY_DETAILS.HEADER_CARD.LABELS.IMAGE_ID', 'Image ID:')}
          value={id}
        />
      </Grid>
    </Grid>
  );
};

const HeaderRowWithNav: FC<{ onClosePressed: () => void }> = ({ onClosePressed }) => {
  const { translate } = useTranslation();

  return (
    <Grid item container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3">{translate('CONFIDENTIALITY_DETAILS.HEADER_CARD.HEADER', 'Image Details')}</Typography>
      </Grid>
      <Grid item>
        <Button variant="text" onClick={onClosePressed}>
          {translate('CONFIDENTIALITY_DETAILS.HEADER_CARD.BACK_BUTTON', 'Back to search result')}
        </Button>
      </Grid>
    </Grid>
  );
};

const RowWithLabel: FC<{ label: string; value: string }> = ({ label, value }) => {
  return (
    <Grid container flexDirection="row">
      <Grid item minWidth="20%">
        <Typography variant="subtitle2">{label}</Typography>
      </Grid>
      <Grid item>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  );
};

import { Divider, Grid, Skeleton, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { useTranslation } from '../../utility/i18n/translation';
import { skeletonify } from '../skeleton';
import { TicketRepairDocumentationPackingRow } from './TicketRepairDocumentationPackingRow';
import { TicketRepairDocumentationStandardRow } from './TicketRepairDocumentationStandardRow';
import { TicketRepairDocumentationAccordion_repairDocumentation$key } from './__generated__/TicketRepairDocumentationAccordion_repairDocumentation.graphql';

export const ActualComponent: FC<{
  repairDocumentation: TicketRepairDocumentationAccordion_repairDocumentation$key;
  padding?: number;
}> = ({ repairDocumentation: repairDocumentationRef, padding }) => {
  const { translate } = useTranslation();
  const repairDocumentation = useFragment(
    graphql`
      fragment TicketRepairDocumentationAccordion_repairDocumentation on RepairDocumentation {
        __typename
        ... on StandardRepairDocumentation {
          damages {
            ...TicketRepairDocumentationStandardRow_damage
          }
        }
        ... on PackingRepairDocumentation {
          subEquipment {
            ...TicketRepairDocumentationPackingRow_subEquipmentRepairDocumentation
          }
        }
      }
    `,
    repairDocumentationRef
  );

  switch (repairDocumentation.__typename) {
    case 'StandardRepairDocumentation':
      return (
        <Grid container>
          {repairDocumentation.damages.map((damage, index) => {
            return (
              <Grid item key={index} xs={12}>
                <TicketRepairDocumentationStandardRow damage={damage} padding={padding} />
                {index < repairDocumentation.damages.length - 1 && <Divider />}
              </Grid>
            );
          })}
        </Grid>
      );

    case 'PackingRepairDocumentation':
      return (
        <Grid container>
          {repairDocumentation.subEquipment.map((subEquipment, index) => {
            return (
              <Grid item key={index} xs={12}>
                <TicketRepairDocumentationPackingRow subEquipmentRepairDocumentation={subEquipment} padding={padding} />
                {index < repairDocumentation.subEquipment.length - 1 && <Divider />}
              </Grid>
            );
          })}
        </Grid>
      );

    default:
      return (
        <Typography>
          {translate('TICKET_DETAILS.CARDS.REPAIR_DOCUMENTATION.TYPE_UNKNOWN', 'Unknown repair documentation type')}
        </Typography>
      );
  }
};

const SkeletonComponent: FC = () => {
  return (
    <Grid item>
      <Skeleton sx={{ width: '100%', height: 80 }} />
    </Grid>
  );
};

export const TicketRepairDocumentationAccordion = skeletonify(
  'TicketRepairDocumentationAccordion',
  ActualComponent,
  SkeletonComponent
);

import { Container, Grid, Paper, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../Router';
import { useTranslation } from '../../utility/i18n/translation';
import { useGMSnackbar } from '../../utility/snackbar';
import { PageErrorBoundary } from '../PageErrorBoundary';
import { CmsEquipmentRepairForm } from './CmsEquipmentRepairForm';
import { CmsSparePartManufacturingForm } from './CmsSparePartManufacturingForm';
import { CmsSparePartRepairForm } from './CmsSparePartRepairForm';
import { CmsTicketTypeSelector } from './CmsTicketTypeSelector';

export interface CmsTicketFormProps {
  onComplete?: (ticketNumber: number, navigateToTicket?: boolean) => void;
}

export const CmsCreateTicket: FC = () => {
  const { translate } = useTranslation();
  const [selectedType, setSelectedType] = useState('');
  const { showSnack } = useGMSnackbar();
  const navigate = useNavigate();

  const showSuccessSnack = useCallback(
    (ticketNumber: number) => {
      showSnack({
        message: translate('CREATE_CMS_TICKET.SUCCESS_SNACK', 'New CMS ticket {{id}} created', { id: ticketNumber }),
        variant: 'success',
      });
    },
    [showSnack, translate]
  );

  const onComplete = useCallback(
    (ticketNumber: number, navigateToTicket = false) => {
      showSuccessSnack(ticketNumber);
      if (navigateToTicket) {
        navigate(AppRoutes.tickets.byTicketNumber(ticketNumber, 'details'));
      } else {
        setSelectedType('');
      }
    },
    [navigate, showSuccessSnack]
  );

  const ticketForm = useMemo(() => {
    switch (selectedType) {
      case 'equipment_repair':
        return <CmsEquipmentRepairForm onComplete={onComplete} />;
      case 'sparepart_repair':
        return <CmsSparePartRepairForm onComplete={onComplete} />;
      case 'sparepart_manufacturing':
        return <CmsSparePartManufacturingForm onComplete={onComplete} />;
      default:
        return null;
    }
  }, [onComplete, selectedType]);

  return (
    <Container maxWidth="lg" sx={{ mt: 3, py: 3 }} component={Paper}>
      <PageErrorBoundary>
        <Grid container>
          <Grid item>
            <Typography variant="h3">{translate('CREATE_CMS_TICKET.TITLE', 'Create CMS order')}</Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid container direction="column" spacing={4}>
            <Grid container item direction="row" justifyContent="center">
              <Grid item>
                <CmsTicketTypeSelector onChange={(value) => setSelectedType(value)} selectedType={selectedType} />
              </Grid>
            </Grid>
            <Grid item>{ticketForm}</Grid>
          </Grid>
        </Grid>
      </PageErrorBoundary>
    </Container>
  );
};

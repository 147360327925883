import { Chip, Skeleton } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { skeletonify } from '../../skeleton';
import { CodingChip_ticket$key } from './__generated__/CodingChip_ticket.graphql';

const ActualComponent: FC<{ ticket: CodingChip_ticket$key }> = (props) => {
  const { ticket: ticketRef } = props;
  const { coding } = useFragment(
    graphql`
      fragment CodingChip_ticket on MouldTicket {
        coding {
          code
          description
        }
      }
    `,
    ticketRef
  );

  if (!coding) {
    return null;
  }

  return <Chip label={`${coding.code} ${coding.description}`} />;
};

const SkeletonComponent: FC = () => (
  <Skeleton>
    <Chip sx={{ width: 100 }} />
  </Skeleton>
);

export const CodingChip = skeletonify('CodingChip', ActualComponent, SkeletonComponent);

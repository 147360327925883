import { gql } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import { createContext, FC, ReactElement, useCallback, useContext, useEffect } from 'react';
import { useGMMutation, useGMQuery } from '../apollo/customApolloHooks';
import { useAnalytics } from '../migration/hooks/analytics';
import { Locale } from '../utility/i18n/i18n';
import { useTranslation } from '../utility/i18n/translation';
import { UserLocale } from '../__apollo__/types';
import { UpdateLanguagePreference, UpdateLanguagePreferenceVariables } from './__apollo__/UpdateLanguagePreference';
import { UserPreferences } from './__apollo__/UserPreferences';

const GET_LANGUAGE = gql`
  query UserPreferences {
    me {
      id
      preferredLanguage
    }
  }
`;

const UPDATE_LANGUAGE = gql`
  mutation UpdateLanguagePreference($input: SelectLanguageInput!) {
    setPreferredLanguage(input: $input) {
      id
      preferredLanguage
      selectedProcess(skipPlantCheck: true) {
        process {
          id
          name
        }
      }
    }
  }
`;

type UserPreferencesContext = {
  language: Locale;
  setLocalePreference: (newLocale: Locale) => void;
  languageLoading: boolean;
};

export const UserPreferencesContext = createContext<UserPreferencesContext>({
  language: Locale.ENGLISH,
  setLocalePreference: () => null,
  languageLoading: false,
});

export const useUserPreferencesContext = (): UserPreferencesContext => {
  return useContext(UserPreferencesContext);
};

export const UserPreferencesProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const { changeLanguage, locale } = useTranslation();
  useAnalytics({ language: locale });

  const { data, loading: languageQueryLoading } = useGMQuery<UserPreferences>(GET_LANGUAGE);
  const [updateLanguageInProfile, { loading: languageUpdateLoading }] = useGMMutation<
    UpdateLanguagePreference,
    UpdateLanguagePreferenceVariables
  >(UPDATE_LANGUAGE);

  useEffect(() => {
    const userPreferredLocale = data?.me.preferredLanguage
      ? mapGqlLocaleToi18nLocale(data.me.preferredLanguage)
      : undefined;

    if (hasValue(userPreferredLocale) && userPreferredLocale !== locale) {
      changeLanguage(userPreferredLocale);
    }
  }, [changeLanguage, data, locale]);

  const setNewLocalePreference = useCallback(
    (newLocale: Locale) => {
      changeLanguage(newLocale);
      updateLanguageInProfile({
        variables: { input: { language: mapI18nLocaleToGqlLocale(newLocale) } },
      });
    },
    [changeLanguage, updateLanguageInProfile]
  );

  return (
    <UserPreferencesContext.Provider
      value={{
        language: locale,
        setLocalePreference: setNewLocalePreference,
        languageLoading: languageQueryLoading || languageUpdateLoading,
      }}
    >
      {children}
    </UserPreferencesContext.Provider>
  );
};

const mapGqlLocaleToi18nLocale = (gqlLocale: UserLocale): Locale => {
  switch (gqlLocale) {
    case UserLocale.CHINESE:
      return Locale.CHINESE;
    case UserLocale.CZECH:
      return Locale.CZECH;
    case UserLocale.DANISH:
      return Locale.DANISH;
    case UserLocale.ENGLISH:
      return Locale.ENGLISH;
    case UserLocale.HUNGARIAN:
      return Locale.HUNGARIAN;
    case UserLocale.SPANISH:
      return Locale.SPANISH;
  }
};

const mapI18nLocaleToGqlLocale = (locale: Locale): UserLocale => {
  switch (locale) {
    case Locale.CHINESE:
      return UserLocale.CHINESE;
    case Locale.CZECH:
      return UserLocale.CZECH;
    case Locale.DANISH:
      return UserLocale.DANISH;
    case Locale.ENGLISH:
      return UserLocale.ENGLISH;
    case Locale.HUNGARIAN:
      return UserLocale.HUNGARIAN;
    case Locale.SPANISH:
      return UserLocale.SPANISH;
  }
};

import { AccordionDetails, Divider, Grid, useTheme } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { TextWithUnitValue } from '../../components/shared/TextWithLabel';
import { useTranslation } from '../../utility/i18n/translation';
import { Icons } from '../../utility/icons';
import { QAOperationCharacteristicsData_characteristicsData$key } from './__generated__/QAOperationCharacteristicsData_characteristicsData.graphql';

const VALIDATION_RESULT_APPROVED = 'A' as const;

export const QAOperationCharacteristicsData: FC<{
  queryRef: QAOperationCharacteristicsData_characteristicsData$key;
}> = ({ queryRef }) => {
  const theme = useTheme();
  const { translate } = useTranslation();

  const { lowerTolerance, upperTolerance, target, results } = useFragment(
    graphql`
      fragment QAOperationCharacteristicsData_characteristicsData on QualityManagementCharacteristic {
        lowerTolerance {
          unit
          value
        }
        upperTolerance {
          unit
          value
        }
        target {
          unit
          value
        }
        results {
          cavityNumber
          positionNumber
          status
          measurement {
            value
          }
          validationResult
        }
      }
    `,
    queryRef
  );

  return (
    <AccordionDetails sx={{ padding: '0px' }}>
      <Grid
        sx={{
          borderTop: 2,
          borderBottom: 2,
          borderColor: theme.palette.background.default,
          display: 'flex',
        }}
      >
        <TextWithUnitValue
          label={translate('QA_DETAILS.CARDS.MOULD_DETAILS.LABELS.TARGET', 'Target')}
          value={target.value}
          unit={target.unit}
        />
        <Divider orientation="vertical" flexItem sx={{ height: 'auto' }} />
        <TextWithUnitValue
          label={translate('QA_DETAILS.CARDS.MOULD_DETAILS.LABELS.UPPER_TOLERANCE', 'Upper Tolerance')}
          value={upperTolerance.value}
          unit={upperTolerance.unit}
        />
        <Divider orientation="vertical" flexItem sx={{ height: 'auto' }} />
        <TextWithUnitValue
          label={translate('QA_DETAILS.CARDS.MOULD_DETAILS.LABELS.LOWER_TOLERANCE', 'Lower Tolerance')}
          value={lowerTolerance.value}
          unit={lowerTolerance.unit}
        />
      </Grid>
      <Grid sx={{ maxHeight: '900px', overflowY: 'auto' }}>
        {results?.map((result, index) => {
          const prevResult = index > 0 && results[index - 1];
          const nextResult = index < results.length - 1 && results[index + 1];
          return (
            <Grid
              key={index}
              sx={
                result.validationResult === 'A'
                  ? undefined
                  : {
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderColor: theme.palette.error.main,
                      borderTop: prevResult && prevResult.validationResult !== 'A' ? 'none' : undefined,
                      borderBottom: nextResult && nextResult.validationResult !== 'A' ? 'none' : undefined,
                    }
              }
            >
              <Grid display="flex" alignItems="center">
                <TextWithUnitValue
                  label={translate('QA_DETAILS.CARDS.MOULD_DETAILS.LABELS.CAVITY_NO', 'Cavity No')}
                  value={result.cavityNumber}
                />
                <Divider orientation="vertical" flexItem sx={{ height: 'auto' }} />
                <TextWithUnitValue
                  label={translate('QA_DETAILS.CARDS.MOULD_DETAILS.LABELS.POS_NO', 'Pos No')}
                  value={result.positionNumber}
                />
                <Divider orientation="vertical" flexItem sx={{ height: 'auto' }} />
                <TextWithUnitValue
                  label={translate('QA_DETAILS.CARDS.MOULD_DETAILS.LABELS.STATUS', 'Status')}
                  value={Number(result.status)}
                />
                <Divider orientation="vertical" flexItem sx={{ height: 'auto' }} />
                <TextWithUnitValue
                  label={translate('QA_DETAILS.CARDS.MOULD_DETAILS.LABELS.VALUE', 'Value')}
                  value={result.measurement.value}
                />
                <Grid sx={{ padding: '0px 18px' }}>
                  {result.validationResult === VALIDATION_RESULT_APPROVED ? (
                    <Icons.CheckmarkBold color="success" />
                  ) : (
                    <Icons.WarningTriangle color="error" />
                  )}
                </Grid>
              </Grid>
              <Divider />
            </Grid>
          );
        })}
      </Grid>
    </AccordionDetails>
  );
};

import { useContext } from 'react';
import { useAreaAndProcessContext } from '../../contexts/area/area-and-process-context';
import { PermissionContext } from './permission-context';
import { canConsumeSpareParts, canReturnSpareParts } from './spare-parts';
import {
  canAddErrorDescription,
  canAddMachineTimeRegistration,
  canAddRepairDescription,
  canAddTimeRegistration,
  canAssignTicket,
  canCloseTicket,
  canCreateTicket,
  canEditEquipmentLocation,
  canEditTicketEquipment,
  canEditTicketPriority,
  canUnassignTicket,
} from './ticket';

export type PermissionResult<T> = {
  isAllowed: boolean;
  rejectionReasons: Array<T>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePermissions = () => {
  const permissionContext = useContext(PermissionContext);

  const { selectedArea } = useAreaAndProcessContext();

  return {
    ticket: {
      canAssignTicket: canAssignTicket(permissionContext.isAllowedToAssign),
      canCloseTicket: canCloseTicket(permissionContext.isAllowedToClose),
      canUnassignTicket: canUnassignTicket(permissionContext.isAllowedToUnassign),
      canAddErrorDescription: canAddErrorDescription(permissionContext.isAllowedAddErrorDescription),
      canAddRepairDescription: canAddRepairDescription(permissionContext.isAllowedToAddRepairDocumentation),
      canAddTimeRegistration: canAddTimeRegistration(permissionContext.isAllowedToAddTimeRegistration),
      canAddMachineTimeRegistration: canAddMachineTimeRegistration(permissionContext.isAllowedToAddTimeRegistration),
      canEditTicketEquipment: canEditTicketEquipment({ ...permissionContext }),
      canEditTicketPriority: canEditTicketPriority({ ...permissionContext }),
      canCreateTicket: canCreateTicket(selectedArea.plantId),
      canEditEquipmentLocation,
    },
    sparePart: {
      canViewSpareParts: permissionContext.isAllowedToViewSparePart,
      canReturnSpareParts: canReturnSpareParts(permissionContext.isAllowedToReturnSparePart),
      canConsumeSpareParts: canConsumeSpareParts(permissionContext.isAllowedToConsumeSparePart),
      canAdjustStock: permissionContext.isAllowedToAdjustStock,
    },
    canViewConfidentialityReport: permissionContext.isAllowedToViewConfidentialityReport,
  };
};

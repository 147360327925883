import { hasValue } from '@lego/mst-error-utilities';
import { RepairDocumentationFlowVariant } from '../../__apollo__/types';
import {
  AddRepairDocumentationState,
  ARDStep,
  initialRepairDescriptionState,
  isCauseInputForRepairDescriptionValid,
  isDamageInputForRepairDescriptionValid,
} from './add-repair-description-context';
import { ActivityOption } from './__apollo__/ActivityOption';
import { CauseOption } from './__apollo__/CauseOption';
import { ComponentOption } from './__apollo__/ComponentOption';
import { DamageOption } from './__apollo__/DamageOption';
import { SubEquipmentOption } from './__apollo__/SubEquipmentOption';

export type AddRepairDescriptionActions =
  | { type: 'set_component'; chosenComponent: ComponentOption }
  | { type: 'set_sub_equipment'; chosenSubEquipment: SubEquipmentOption }
  | {
      type: 'set_damage';
      chosenDamage: DamageOption;
    }
  | { type: 'set_should_show_activities'; show: boolean }
  | {
      type: 'set_custom_damage';
      customInput: string;
    }
  | { type: 'set_cause'; chosenCause: CauseOption }
  | { type: 'set_custom_cause'; customInput: string }
  | { type: 'set_custom_subequipment'; customInput: string }
  | { type: 'set_custom_component'; customInput: string }
  | { type: 'activity_selected'; activity: ActivityOption }
  | { type: 'set_repair_comment'; comment: string | undefined }
  | { type: 'step_forward' }
  | { type: 'step_backward' }
  | { type: 'set_repair_flow_variant'; variant: RepairDocumentationFlowVariant }
  | { type: 'set_current_step'; currentStep: ARDStep }
  | { type: 'reset_flow' };

export const addRepairReducer = (
  state: AddRepairDocumentationState,
  action: AddRepairDescriptionActions
): AddRepairDocumentationState => {
  switch (action.type) {
    case 'set_damage':
      if (action.chosenDamage.id === state.chosenDamage?.id) {
        return state;
      }
      return {
        ...state,
        customDamageString: undefined,
        chosenDamage: action.chosenDamage,
        chosenCause: undefined,
        chosenActivities: [],
        repairComment: undefined,
      };
    case 'set_custom_damage':
      return {
        ...state,
        customDamageString: action.customInput,
      };
    case 'set_should_show_activities':
      return {
        ...state,
        showActivities: action.show,
      };
    case 'set_cause':
      return {
        ...state,
        customCauseString: undefined,
        chosenCause: action.chosenCause,
      };
    case 'set_custom_cause':
      return {
        ...state,
        customCauseString: action.customInput,
      };
    case 'set_custom_subequipment':
      return {
        ...state,
        customSubEquipmentString: action.customInput,
      };
    case 'set_custom_component':
      return {
        ...state,
        customComponentString: action.customInput,
      };
    case 'activity_selected':
      return {
        ...state,
        chosenActivities: getUpdatedActivityArray({
          currentActivities: state.chosenActivities,
          chosen: action.activity,
        }),
      };
    case 'set_sub_equipment':
      return {
        ...state,
        chosenSubEquipment: action.chosenSubEquipment,
      };
    case 'set_component':
      return {
        ...state,
        chosenComponent: action.chosenComponent,
      };
    case 'set_repair_comment':
      return {
        ...state,
        repairComment: action.comment,
      };
    case 'step_forward':
    case 'step_backward':
      return navigateStepIfPossible(state, action);

    case 'set_current_step':
      if (shouldNavigateToStep(state, action.currentStep, state.variant)) {
        return {
          ...state,
          currentStep: action.currentStep,
        };
      }
      return { ...state };

    case 'reset_flow':
      return initialRepairDescriptionState;

    case 'set_repair_flow_variant':
      return {
        ...state,
        variant: action.variant,
        currentStep: 'subEquipment',
      };

    default:
      return state;
  }
};

export const getUpdatedActivityArray = ({
  chosen,
  currentActivities,
}: {
  currentActivities: ActivityOption[];
  chosen: ActivityOption;
}): ActivityOption[] => {
  const activityIndexInCurrentState = currentActivities.findIndex((activity) => activity.id === chosen.id);

  if (activityIndexInCurrentState !== -1) {
    const res = [...currentActivities];
    res.splice(activityIndexInCurrentState, 1);

    return res;
  } else {
    return [...currentActivities, chosen];
  }
};

/**
 * @returns Whether or not to perform the navigation action or skip it, based on current state
 */
const shouldNavigateToStep = (
  state: AddRepairDocumentationState,
  goToStep: ARDStep,
  variant: RepairDocumentationFlowVariant
): boolean => {
  if (variant === RepairDocumentationFlowVariant.Packing) {
    if (goToStep !== 'subEquipment' && !hasValue(state.chosenSubEquipment)) {
      return false;
    }
    // Do not allow continuing further than 'component' step in flow without choosing component
    if (!(['subEquipment', 'component'] as ARDStep[]).includes(goToStep) && !hasValue(state.chosenComponent)) {
      return false;
    }
  }

  if (
    !(['subEquipment', 'component', 'damage'] as ARDStep[]).includes(goToStep) &&
    !isDamageInputForRepairDescriptionValid(state)
  ) {
    // Damage input not valid yet, do not move on
    return false;
  }

  if (
    (['activities', 'comment', 'summary'] as ARDStep[]).includes(goToStep) &&
    !isCauseInputForRepairDescriptionValid(state)
  ) {
    return false;
  }

  if (goToStep === 'activities' && !state.showActivities) {
    return false;
  }

  return true;
};

const navigateStepIfPossible = (
  state: AddRepairDocumentationState,
  action:
    | Extract<AddRepairDescriptionActions, { type: 'step_forward' }>
    | Extract<AddRepairDescriptionActions, { type: 'step_backward' }>
): AddRepairDocumentationState => {
  const { currentStep, showActivities } = state;
  switch (currentStep) {
    case 'subEquipment':
      if (action.type === 'step_backward' || !hasValue(state.chosenSubEquipment)) {
        return state;
      }

      return {
        ...state,
        currentStep: 'component',
      };
    case 'component':
      if (action.type === 'step_backward') {
        return { ...state, currentStep: 'subEquipment' };
      }

      if (!hasValue(state.chosenComponent)) {
        return state;
      }

      return {
        ...state,
        currentStep: 'damage',
      };

    case 'damage':
      if (action.type === 'step_backward') {
        if (state.variant === RepairDocumentationFlowVariant.Baseline) {
          return {
            ...state,
            currentStep: 'component',
          };
        } else {
          return state;
        }
      }

      if (!isDamageInputForRepairDescriptionValid(state)) {
        return state;
      }

      return {
        ...state,
        currentStep: 'cause',
      };

    case 'cause':
      if (action.type === 'step_backward') {
        return {
          ...state,
          currentStep: 'damage',
        };
      }

      if (!isCauseInputForRepairDescriptionValid(state)) {
        return state;
      }

      return {
        ...state,
        currentStep: showActivities ? 'activities' : 'comment',
      };

    case 'activities':
      if (action.type === 'step_backward') {
        return {
          ...state,
          currentStep: 'cause',
        };
      }

      return {
        ...state,
        currentStep: 'comment',
      };

    case 'comment':
      if (action.type === 'step_backward') {
        return {
          ...state,
          currentStep: showActivities ? 'activities' : 'cause',
        };
      }

      return {
        ...state,
        currentStep: 'summary',
      };

    case 'summary':
      if (action.type === 'step_backward') {
        return {
          ...state,
          currentStep: 'comment',
        };
      }
      return state;
  }
};

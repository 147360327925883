import { Grid, Skeleton, Typography } from '@mui/material';
import { CSSProperties, FC } from 'react';

export const TextWithLabel: FC<{
  text: string;
  label: string;
  alignTextRight?: boolean;
}> = ({ text, label, alignTextRight = false }) => {
  const textAlignStyle: CSSProperties = {
    textAlign: alignTextRight ? 'right' : 'left',
  };
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography style={textAlignStyle}>{text}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="textSecondary" style={textAlignStyle}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const TextWithLabelSkeleton: FC = () => {
  return (
    <Grid container direction={'column'}>
      <Grid item>
        <Skeleton variant="text" width={160} />
      </Grid>
      <Grid item>
        <Skeleton variant="text" width={80} />
      </Grid>
    </Grid>
  );
};

export const TextWithUnitValue: FC<{
  value: number;
  label: string;
  unit?: string;
}> = ({ value, label, unit }) => {
  return (
    <Grid xs sx={{ padding: '10px 18px' }}>
      <Typography color="textSecondary" fontSize="13px">
        {label}
      </Typography>
      <Typography fontSize="16px">{[value, unit].join(' ')}</Typography>
    </Grid>
  );
};

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  IconButton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { format, isValid } from 'date-fns';
import { FC, useCallback, useState } from 'react';
import { useFragment } from 'react-relay';
import { CardWithTitle } from '../../../components/shared/CardWithTitle';
import { TDWidgetDialogButton } from '../../../components/ticket-details/TDWidgetAddButton';
import { useTranslation } from '../../../utility/i18n/translation';
import { Icons } from '../../../utility/icons';
import { skeletonify } from '../../skeleton';
import { AddDimmingDialog } from '../dimming-dialogs/AddDimmingDialog';
import { DeleteDimmingDialog } from '../dimming-dialogs/DeleteDimmingDialog';
import { EditDimmingDialog } from '../dimming-dialogs/EditDimmingDialog';
import { TDWidgetWithCollapsibleRowsSkeleton } from './TDWidgetWithCollapsibleRowsSkeleton';
import { TDDimmingDetails_dimmingDetails$key } from './__generated__/TDDimmingDetails_dimmingDetails.graphql';
import { TDDimmingDetails_mould$key } from './__generated__/TDDimmingDetails_mould.graphql';

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#FCFCFC',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ActualComponent: FC<{
  mould?: TDDimmingDetails_mould$key;
  canEditDimmings?: boolean;
}> = ({ mould: mouldRef, canEditDimmings = true }) => {
  const { translate } = useTranslation();

  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const data = useFragment(
    graphql`
      fragment TDDimmingDetails_mould on Equipment {
        ... on Mould {
          id
          isActive
          dimmings {
            details {
              id
              ...TDDimmingDetails_dimmingDetails
            }
          }
        }
      }
    `,
    mouldRef ?? null
  );

  const openAddDialog = useCallback(() => setAddDialogOpen(true), []);

  if (!data || !mouldRef) {
    return null;
  }

  const { dimmings, isActive } = data;

  const cardTitle = translate('TICKET_DETAILS.CARDS.DIMMINGS.TITLE', 'Dimmings');

  const rightItem =
    isActive && canEditDimmings ? (
      <TDWidgetDialogButton
        icon={<Icons.Plus />}
        title={translate('TICKET_DETAILS.CARDS.DIMMINGS.ADD_BUTTON', 'Dimmings')}
        onClick={openAddDialog}
      />
    ) : undefined;

  const translations = {
    cavityNr: translate('TICKET_DETAILS.CARDS.DIMMINGS.CAVITY_NO', 'Cavity no.'),
    reason: translate('TICKET_DETAILS.CARDS.DIMMINGS.REASON', 'Reason'),
    removalDate: translate('TICKET_DETAILS.CARDS.DIMMINGS.REMOVAL_DATE', 'Expected removal date'),
    addedBy: translate('TICKET_DETAILS.CARDS.DIMMINGS.ADDED_BY', 'Added by'),
    addedAt: translate('TICKET_DETAILS.CARDS.DIMMINGS.ADDED_DATE', 'Added date'),
    type: translate('TICKET_DETAILS.CARDS.DIMMINGS.TYPE', 'Type'),
    description: translate('TICKET_DETAILS.CARDS.DIMMINGS.description', 'Description'),
    actions: translate('TICKET_DETAILS.CARDS.DIMMINGS.actions', 'Actions'),
  };

  return (
    <CardWithTitle title={cardTitle} rightItem={rightItem}>
      {data.id && <AddDimmingDialog onDismiss={() => setAddDialogOpen(false)} open={addDialogOpen} mouldId={data.id} />}
      {dimmings?.details?.length === 0 ? (
        <Typography>{translate('TICKET_DETAILS.CARDS.DIMMINGS.NO_DIMMINGS', 'No dimmings')}</Typography>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead sx={{ backgroundColor: '#F8F8F8' }}>
              <TableRow>
                <TableCell>{translations.cavityNr}</TableCell>
                <TableCell align="right">{translations.reason}</TableCell>
                <TableCell align="right">{translations.removalDate}</TableCell>
                <TableCell align="right">{translations.addedAt}</TableCell>
                <TableCell align="right">{translations.addedBy}</TableCell>
                <TableCell align="right">{translations.type}</TableCell>
                {isActive && canEditDimmings && <TableCell align="right">{translations.actions}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {dimmings?.details?.map((dimming) => {
                return (
                  <Row
                    dimmingDetails={dimming}
                    key={dimming.id}
                    isActive={isActive ?? true}
                    canEditDimmings={canEditDimmings}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </CardWithTitle>
  );
};

const Row: FC<{
  dimmingDetails: TDDimmingDetails_dimmingDetails$key;
  isActive: boolean;
  canEditDimmings: boolean;
}> = (props) => {
  const { translate } = useTranslation();
  const {
    palette: {
      warning: { main: warningColor },
      error: { main: errorColor },
    },
  } = useTheme();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const { dimmingDetails: dimmingDetailsRef, isActive, canEditDimmings } = props;
  const data = useFragment(
    graphql`
      fragment TDDimmingDetails_dimmingDetails on DimmingDetails {
        id
        addedBy {
          ... on Description {
            description
          }
          ... on Employee {
            firstName
            lastName
          }
        }
        cavityNumber
        expectedRemovalDescription
        isPermanent
        addedAt
        reason
        ...DeleteDimmingDialog_dimmingDetails
        ...EditDimmingDialog_dimmingDetails
      }
    `,
    dimmingDetailsRef
  );

  const { addedAt, addedBy, cavityNumber, expectedRemovalDescription, id, isPermanent, reason } = data;

  const expectedRemoval =
    !!expectedRemovalDescription && isValid(new Date(expectedRemovalDescription))
      ? format(new Date(expectedRemovalDescription), 'P')
      : expectedRemovalDescription;

  const addedAtFormatted = !!addedAt && isValid(new Date(addedAt)) ? format(new Date(addedAt), 'P') : addedAt;

  const onEditPressed = useCallback(() => {
    setEditDialogOpen(true);
  }, []);

  const onCloseEdit = useCallback(() => {
    setEditDialogOpen(false);
  }, []);

  const onDeletePressed = useCallback(() => {
    setDeleteDialogOpen(true);
  }, []);

  const onCloseDelete = useCallback(() => {
    setDeleteDialogOpen(false);
  }, []);

  return (
    <StyledTableRow key={id}>
      {deleteDialogOpen && <DeleteDimmingDialog dimmingDetails={data} dimmingId={id} onDismiss={onCloseDelete} />}
      {editDialogOpen && <EditDimmingDialog dimmingDetailRef={data} onDismiss={onCloseEdit} />}
      <TableCell component="th" scope="row">
        #{cavityNumber}
      </TableCell>
      <TableCell align="right">{reason}</TableCell>
      <TableCell align="right">{expectedRemoval}</TableCell>
      <TableCell align="right">{addedAtFormatted}</TableCell>
      <TableCell align="right">
        {addedBy?.firstName} {addedBy?.lastName}
        {addedBy?.description}
      </TableCell>
      <TableCell align="right">
        <Stack direction={'row'} alignItems="center" justifyContent={'flex-end'}>
          <Box
            height={12}
            width={12}
            borderRadius={6}
            mr={1}
            sx={{
              backgroundColor: isPermanent ? errorColor : warningColor,
            }}
          />
          {isPermanent
            ? translate('TICKET_DETAILS.CARDS.DIMMINGS.PERMANENT', 'Permanent')
            : translate('TICKET_DETAILS.CARDS.DIMMINGS.TEMPORARY', 'Temporary')}
        </Stack>
      </TableCell>
      {isActive && canEditDimmings && (
        <TableCell align="right">
          <Stack direction={'row'} alignItems="center" justifyContent={'flex-end'}>
            <IconButton onClick={onEditPressed} size="large">
              <EditIcon />
            </IconButton>
            <IconButton onClick={onDeletePressed} size="large">
              <DeleteIcon />
            </IconButton>
          </Stack>
        </TableCell>
      )}
    </StyledTableRow>
  );
};

const SkeletonComponent: FC = () => {
  return <TDWidgetWithCollapsibleRowsSkeleton />;
};

export const TDDimmingDetails = skeletonify('TDDimmingDetails', ActualComponent, SkeletonComponent);

import { Grid } from '@mui/material';
import { FC } from 'react';
import { TextWithLabel } from '../../components/shared/TextWithLabel';
import { useTranslation } from '../../utility/i18n/translation';

export const SisterMouldPreventiveMaintenance: FC<{
  title: string;
  interval: number;
  percentage: number;
}> = ({ title, interval, percentage }) => {
  const { translate, locale } = useTranslation();

  return (
    <Grid container direction="row" paddingTop={0} spacing={2}>
      <Grid item xs={2}>
        <TextWithLabel
          text={title}
          label={translate('TICKET_DETAILS.SISTER_MOULDS_TAB.PREVENTIVE.NAME', 'Preventive name')}
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextWithLabel
          text={'Shots'}
          label={translate('TICKET_DETAILS.SISTER_MOULDS_TAB.PREVENTIVE.INTERVAL_TYPE', 'Interval type')}
        />
      </Grid>
      <Grid item xs={2}>
        <TextWithLabel
          text={interval.toLocaleString(locale)}
          label={translate('TICKET_DETAILS.SISTER_MOULDS_TAB.PREVENTIVE.INTERVAL', 'Interval')}
        />
      </Grid>
      <Grid item xs={3}>
        <TextWithLabel
          text={`${percentage}%`}
          label={translate('TICKET_DETAILS.SISTER_MOULDS_TAB.PREVENTIVE.CURRENT_STATUS', 'Current status')}
        />
      </Grid>
      {/* TODO: Add after sys. cleaning dialog has been added */}
      {/* <Grid container item justifyContent="flex-end" sm>
      <Grid
        container
        item
        direction="column"
        justifyContent="center"
        xs={2}
      >
        <Icons.Edit color="secondary" />
      </Grid>
    </Grid> */}
    </Grid>
  );
};

import { Grid } from '@mui/material';
import { ComponentProps, FC } from 'react';
import { PrinterAutocomplete } from './PrinterAutocomplete';

export const PrintDialogContent: FC<ComponentProps<typeof PrinterAutocomplete>> = ({ onPrinterSelected }) => {
  return (
    <Grid container>
      <Grid item sx={{ width: '100%', mt: 1 }}>
        <PrinterAutocomplete.Suspense onPrinterSelected={onPrinterSelected} />
      </Grid>
    </Grid>
  );
};

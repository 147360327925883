import { Grid, Skeleton, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { useTranslation } from '../../../utility/i18n/translation';
import { skeletonify } from '../../skeleton';
import { isSuccessType } from '../../utils';
import { SparePartSearchLabel_querySparePartResult$key } from './__generated__/SparePartSearchLabel_querySparePartResult.graphql';

type SparePartSearchLabelProps = {
  sparePart: SparePartSearchLabel_querySparePartResult$key;
};

const ActualComponent: FC<SparePartSearchLabelProps> = (props) => {
  const { sparePart: sparePartRef } = props;
  const { translate } = useTranslation();

  const sparePart = useFragment(
    graphql`
      fragment SparePartSearchLabel_querySparePartResult on QuerySparePartResult {
        __typename
        ... on QuerySparePartSuccess {
          data {
            description
          }
        }
      }
    `,
    sparePartRef
  );

  if (isSuccessType(sparePart)) {
    return <Typography>{sparePart.data.description}</Typography>;
  }

  return <Typography>{translate('CREATE_CMS_TICKET.SPAREPART_NOT_FOUND', 'Spare part not found')}</Typography>;
};

const SkeletonComponent = () => <Skeleton width={190} height={48} variant="text" />;

const StructureComponent: FC<{ children: any }> = ({ children }) => (
  <Grid item ml={2}>
    {children}
  </Grid>
);

export const SparePartSearchLabel = skeletonify(
  'SparePartSearchLabel',
  ActualComponent,
  SkeletonComponent,
  StructureComponent
);

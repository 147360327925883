import { Grid, TextField, Typography } from '@mui/material';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGMQuery } from '../../../apollo/customApolloHooks';
import {
  MAX_LENGTH_FOR_CUSTOM_FIELD_DESCRIPTION,
  useAddRepairDescriptionContext,
} from '../../../contexts/add-repair-description/add-repair-description-context';
import { REPAIR_DESCRIPTION_SUB_EQUIPMENTS } from '../../../contexts/add-repair-description/add-repair-description-gql-queries';
import { SubEquipmentOption } from '../../../contexts/add-repair-description/__apollo__/SubEquipmentOption';
import {
  SubEquipmentOptions,
  SubEquipmentOptionsVariables,
} from '../../../contexts/add-repair-description/__apollo__/SubEquipmentOptions';
import { useTranslation } from '../../../utility/i18n/translation';
import { RepairDocumentationFlowVariant } from '../../../__apollo__/types';
import { FillWidthLoading } from '../../shared/FillWidthLoading';
import { ARDAutocomplete } from '../ARDAutocomplete';
import { ARDNavigationButtons } from '../ARDNavigationButtons';

export const ARDChooseSubEquipment: FC = () => {
  const { translate } = useTranslation();
  const { id: ticketId } = useParams() as { id: string };

  const {
    state: { chosenSubEquipment, customSubEquipmentString, variant, currentStep },
    dispatch,
  } = useAddRepairDescriptionContext();

  const { data, loading, error } = useGMQuery<SubEquipmentOptions, SubEquipmentOptionsVariables>(
    REPAIR_DESCRIPTION_SUB_EQUIPMENTS,
    {
      skip: variant === RepairDocumentationFlowVariant.Baseline,
      variables: { input: { ticketId } },
    }
  );

  if (loading) {
    return <FillWidthLoading />;
  }

  if (!data || error) {
    return (
      <Typography>
        {translate(
          'ADD_REPAIR_DOCUMENTATION.CHOOSE_SUB_EQUIPMENT.NO_SUB_EQUIPMENT',
          'No sub equipment to show, please refresh'
        )}
      </Typography>
    );
  }

  const onValueSelected = (value: SubEquipmentOption | null) => {
    if (value) {
      dispatch({ type: 'set_sub_equipment', chosenSubEquipment: value });
      if (!value.requiresAdditionalInput) {
        dispatch({ type: 'step_forward' });
      }
    }
  };

  if (!(currentStep === 'subEquipment')) {
    return (
      <Typography>
        {chosenSubEquipment?.description}
        {chosenSubEquipment?.requiresAdditionalInput && customSubEquipmentString
          ? ` - ${customSubEquipmentString}`
          : ''}
      </Typography>
    );
  }

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography>
          {translate('ADD_REPAIR_DOCUMENTATION.CHOOSE_SUB_EQUIPMENT.CHOOSE', 'Choose the appropriate sub equipment')}
        </Typography>
      </Grid>
      <Grid item>
        <ARDAutocomplete<SubEquipmentOption>
          onChange={onValueSelected}
          options={data.repairDescriptionSubEquipmentOptions ?? []}
          chosenValue={chosenSubEquipment ?? null}
          cyIdentifier="subEquipment"
        />
        {chosenSubEquipment?.requiresAdditionalInput && <CustomSubEquipmentInputComponent />}
      </Grid>
    </Grid>
  );
};

const CustomSubEquipmentInputComponent: FC = () => {
  const { translate } = useTranslation();
  const [text, setText] = useState('');

  const {
    state: { customSubEquipmentString },
    dispatch,
  } = useAddRepairDescriptionContext();

  useEffect(() => {
    if (customSubEquipmentString && customSubEquipmentString.length > 0 && text.length === 0) {
      setText(customSubEquipmentString);
    }
  }, [customSubEquipmentString, text.length]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setText(newValue);
    dispatch({ type: 'set_custom_subequipment', customInput: newValue });
  };

  return (
    <Grid container direction="column" spacing={3} style={{ marginTop: 20 }}>
      <Grid item>
        <TextField
          defaultValue={customSubEquipmentString}
          variant="outlined"
          onChange={onChange}
          fullWidth
          inputProps={{ maxLength: MAX_LENGTH_FOR_CUSTOM_FIELD_DESCRIPTION }}
          label={translate(
            'ADD_REPAIR_DOCUMENTATION.CHOOSE_SUB_EQUIPMENT.CUSTOM_INPUT_PLACEHOLDER',
            'Sub equipment description'
          )}
        />
      </Grid>
      <Grid item>
        <ARDNavigationButtons />
      </Grid>
    </Grid>
  );
};

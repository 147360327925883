/**
 * @generated SignedSource<<53210a9372ed8d3d29d0f402a7729d1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentHistoryTab_equipment$data = {
  readonly __typename: string;
  readonly ticketConnections: {
    readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryCountsWidget_ticketsConnection" | "EquipmentHistoryFilters_ticketsConnection" | "EquipmentHistoryKnowledgeCenter_ticketsConnection">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryList_equipment">;
  readonly " $fragmentType": "EquipmentHistoryTab_equipment";
};
export type EquipmentHistoryTab_equipment$key = {
  readonly " $data"?: EquipmentHistoryTab_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryTab_equipment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "filters"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filters"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentHistoryTab_equipment",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "EquipmentHistoryList_equipment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": "ticketConnections",
      "args": (v0/*: any*/),
      "concreteType": "TicketsConnection",
      "kind": "LinkedField",
      "name": "tickets",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquipmentHistoryCountsWidget_ticketsConnection"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquipmentHistoryFilters_ticketsConnection"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquipmentHistoryKnowledgeCenter_ticketsConnection"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};
})();

(node as any).hash = "d1c58b58523e347438cfe1b87255c502";

export default node;

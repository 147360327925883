import { gql, QueryTuple } from '@apollo/client';
import { useGMLazyQuery } from '../../apollo/customApolloHooks';
import { CONFIDENTIALITY_SEARCH_ROW } from './ImageSearchListComponent';
import { ConfidentialityReportList, ConfidentialityReportListVariables } from './__apollo__/ConfidentialityReportList';

const CONFIDENTIALITY_QUERY = gql`
  query ConfidentialityReportList($input: ConfidentialityQueryInput!, $first: Int!, $after: String) {
    confidentialityQuery(input: $input, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount(input: $input)
      edges {
        cursor
        node {
          id
          ...ConfidentialitySearchRow
        }
      }
    }
  }
  ${CONFIDENTIALITY_SEARCH_ROW}
`;

export const useConfidentialityQuery = (): QueryTuple<
  ConfidentialityReportList,
  ConfidentialityReportListVariables
> => {
  return useGMLazyQuery<ConfidentialityReportList, ConfidentialityReportListVariables>(CONFIDENTIALITY_QUERY, {
    notifyOnNetworkStatusChange: true,
  });
};

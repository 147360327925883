import { gql } from '@apollo/client';
import { Grid, Skeleton } from '@mui/material';
import { FC } from 'react';
import { useGMQuery } from '../../../apollo/customApolloHooks';
import { CardWithTitleSkeleton } from '../../../components/shared/CardWithTitle';
import { TDCurrentLocationOld, TD_CURRENT_LOCATION } from '../../../components/ticket-details/TDCurrentLocation';
import { TDCurrentLocationQuery, TDCurrentLocationQueryVariables } from './__apollo__/TDCurrentLocationQuery';

const QUERY = gql`
  query TDCurrentLocationQuery($input: EquipmentByIdInput!) {
    equipment(input: $input) {
      ... on EquipmentValue {
        value {
          id
          ...TicketDetailsLocation
        }
      }
    }
  }
  ${TD_CURRENT_LOCATION}
`;

export const TDCurrentLocation: FC<{ equipmentNumber: string }> = ({ equipmentNumber }) => {
  const { data, loading } = useGMQuery<TDCurrentLocationQuery, TDCurrentLocationQueryVariables>(QUERY, {
    variables: { input: { id: equipmentNumber } },
  });

  if (loading && !data) {
    return <TDCurrentLocationSkeleton />;
  }

  if (!data || data.equipment.__typename !== 'EquipmentValue') {
    return null;
  }

  return <TDCurrentLocationOld {...data.equipment.value} />;
};

export const TDCurrentLocationSkeleton: FC = () => {
  return (
    <CardWithTitleSkeleton showTopRightButton={true}>
      <Grid container direction="column" spacing={1}>
        {[1, 2, 3, 4].map((val) => {
          const width = val % 2 !== 0 ? 200 : 100;
          return (
            <Grid item key={val}>
              <Skeleton variant="text" width={width} sx={{ borderRadius: 1 }} />
            </Grid>
          );
        })}
      </Grid>
    </CardWithTitleSkeleton>
  );
};

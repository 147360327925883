import { Grid } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import orderBy from 'lodash/orderBy';
import { FC } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { fuzzySearch } from '../utils';
import { SublocationItem } from './SublocationItem';
import { SublocationListQuery } from './__generated__/SublocationListQuery.graphql';

export const SublocationList: FC<{
  relayProcessId: string;
  searchString: string;
}> = ({ searchString, relayProcessId }) => {
  const data = useLazyLoadQuery<SublocationListQuery>(
    graphql`
      query SublocationListQuery($processId: ID!) {
        node(id: $processId) {
          ... on Process {
            sublocations {
              id
              # eslint-disable-next-line relay/unused-fields
              code
              # eslint-disable-next-line relay/unused-fields
              description
              ...SublocationItem_sublocation
            }
          }
        }
      }
    `,
    { processId: relayProcessId ?? '' }
  );

  const searchResult = fuzzySearch(searchString, data.node?.sublocations?.slice() ?? [], ['code', 'description']);

  return (
    <Grid container direction="column">
      {orderBy(searchResult, 'code').map((sublocation) => (
        <Grid item key={sublocation.id} sx={{ mb: 2, width: '100%' }}>
          <SublocationItem sublocation={sublocation} />
        </Grid>
      ))}
    </Grid>
  );
};

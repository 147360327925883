import { hasValue } from '@lego/mst-error-utilities';
import { Grid } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useMemo } from 'react';
import { useFragment } from 'react-relay';
import { CardWithTitle } from '../../../components/shared/CardWithTitle';
import { TextWithLabel } from '../../../components/shared/TextWithLabel';
import { useTranslation } from '../../../utility/i18n/translation';
import { calculatePercentage } from '../../utils';
import { TDSpringChange_mould$key } from './__generated__/TDSpringChange_mould.graphql';

export const TDSpringChange: FC<{ equipment: TDSpringChange_mould$key }> = ({ equipment: equipmentRef }) => {
  const { translate, locale } = useTranslation();
  const { shotsSinceLastSpringChange, springChangeInterval } = useFragment(
    graphql`
      fragment TDSpringChange_mould on Mould {
        shotsSinceLastSpringChange
        springChangeInterval
      }
    `,
    equipmentRef
  );

  const springChangePercentage = useMemo(() => {
    if (hasValue(springChangeInterval) && hasValue(shotsSinceLastSpringChange)) {
      return calculatePercentage(springChangeInterval, shotsSinceLastSpringChange);
    }
    return 0;
  }, [shotsSinceLastSpringChange, springChangeInterval]);

  if (!hasValue(springChangeInterval)) {
    return null;
  }
  return (
    <CardWithTitle title={translate('TICKET_DETAILS.CARDS.SPRING_CHANGE.TITLE', 'Spring Change')}>
      <Grid container direction="row">
        <Grid item xs={2}>
          <SysCleaningItem
            text={springChangeInterval?.toLocaleString(locale)}
            label={translate('TICKET_DETAILS.CARDS.SPRING_CHANGE.LABELS.INTERVAL', 'Interval')}
          />
        </Grid>
        <Grid item xs={10}>
          <SysCleaningItem
            text={`${springChangePercentage}%`}
            label={translate('TICKET_DETAILS.CARDS.SPRING_CHANGE.LABELS.CURRENT_STATUS', 'Current status')}
          />
        </Grid>
      </Grid>
    </CardWithTitle>
  );
};

const SysCleaningItem: FC<{
  label: string;
  text: string | undefined | null;
}> = ({ label, text }) => {
  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid item>
        <TextWithLabel text={text ?? '---'} label={label} />
      </Grid>
    </Grid>
  );
};

import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback, useState } from 'react';
import { useMutation } from 'react-relay';
import { SimpleDialog } from '../../components/shared/SimpleDialog';
import { useAreaAndProcessContext } from '../../contexts/area';
import { useTranslation } from '../../utility/i18n/translation';
import { useGMSnackbar } from '../../utility/snackbar';
import { PrintDialogContent } from './PrintDialogContent';
import { PrintEquipmentLabelDialogMutation } from './__generated__/PrintEquipmentLabelDialogMutation.graphql';

export const PrintEquipmentLabelDialog: FC<{
  open: boolean;
  onDismiss: () => void;
  equipmentId: string;
}> = ({ onDismiss, open, equipmentId }) => {
  const { translate } = useTranslation();
  const { showSnack } = useGMSnackbar();
  const { relayPlantId } = useAreaAndProcessContext();
  const [chosenPrinterId, setChosenPrinterId] = useState<string | undefined>(undefined);

  const [printMutation, loading] = useMutation<PrintEquipmentLabelDialogMutation>(graphql`
    mutation PrintEquipmentLabelDialogMutation($input: MutationPrintEquipmentLabelInput!) {
      printEquipmentLabel(input: $input) {
        ... on MutationPrintEquipmentLabelSuccess {
          __typename
        }
      }
    }
  `);

  const onPrintPressed = useCallback(() => {
    if (chosenPrinterId && relayPlantId) {
      printMutation({
        variables: {
          input: {
            equipmentId,
            plantId: relayPlantId,
            printerId: chosenPrinterId,
          },
        },
        onCompleted: () => {
          showSnack({
            message: translate('EQUIPMENT_HEADER.FAB.PRINT_DIALOG.ON_SUCCESS', 'Printing label on selected printer'),
            variant: 'success',
          });
          setChosenPrinterId(undefined);
          onDismiss();
        },
      });
    }
  }, [chosenPrinterId, equipmentId, onDismiss, printMutation, relayPlantId, showSnack, translate]);

  return (
    <SimpleDialog
      onDismiss={onDismiss}
      open={open}
      title={translate('EQUIPMENT_HEADER.FAB.PRINT_DIALOG.TITLE', 'Print equipment label')}
      secondaryAction={{
        secondaryActionLabel: translate('EQUIPMENT_HEADER.FAB.PRINT_DIALOG.CANCEL_BUTTON', 'Cancel'),
        secondaryActionPressed: onDismiss,
        secondaryActionDisabled: loading,
      }}
      primaryAction={{
        primaryActionLabel: translate('EQUIPMENT_HEADER.FAB.PRINT_DIALOG.PRINT_BUTTON', 'Print'),
        primaryActionPressed: onPrintPressed,
        primaryActionLoading: loading,
      }}
      content={{
        type: 'node',
        node: <PrintDialogContent onPrinterSelected={setChosenPrinterId} />,
      }}
      maxWidth={'sm'}
    />
  );
};

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Container, Fab, Grid } from '@mui/material';
import { FC, useCallback } from 'react';
import { TicketList } from '../components/ticket-list/TicketList';
import { TicketListFilters } from '../components/ticket-list/TicketListFilters';
import {
  TicketListSearchProvider,
  useTicketListSearchContext,
} from '../contexts/ticket-list-search/ticket-list-search-context';
import { CountAndSearch } from '../migration/components/CountAndSearch';

export const TicketsContainer: FC = () => {
  return (
    <TicketListSearchProvider>
      <Wrapped />
    </TicketListSearchProvider>
  );
};

const Wrapped: FC = () => {
  const {
    dispatch,
    state: { totalSearchResults },
  } = useTicketListSearchContext();

  const onSearchChanged = useCallback(
    (value: string) => {
      dispatch({ type: 'setSearchTerm', searchTerm: value });
    },
    [dispatch]
  );

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 10 }}>
      <Grid container direction="column" spacing={3}>
        <Grid item sx={{ ml: '25%' }}>
          <CountAndSearch count={totalSearchResults} onChange={onSearchChanged} showCount={true} />
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs md={3}>
            <TicketListFilters />
          </Grid>
          <Grid item xs md={9}>
            <TicketList />
          </Grid>
        </Grid>
      </Grid>
      <ScrollToTopFab />
    </Container>
  );
};

export const ScrollToTopFab: FC = () => {
  const { scrollToTop } = useScrollToTop();

  return (
    <Fab color="primary" aria-label="add" onClick={scrollToTop} sx={{ position: 'absolute', right: '15%', bottom: 50 }}>
      <ArrowUpwardIcon />
    </Fab>
  );
};

export const useScrollToTop = (): { scrollToTop: () => void } => {
  const scrollToTop = () => {
    document.querySelector('#root > div > main')?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return {
    scrollToTop,
  };
};

import { hasValue } from '@lego/mst-error-utilities';
import graphql from 'babel-plugin-relay/macro';
import { usePaginationFragment } from 'react-relay';
import { Divider, Grid } from '@mui/material';
import { FC, useCallback } from 'react';
import { ExtractNodeFromConnection } from '../../../utility-types';
import { useTranslation } from '../../../utility/i18n/translation';
import { ActivityIndicator } from '../../../components/shared/ActivityIndicator';
import { InfiniteList } from '../../components/InfiniteList';
import { skeletonify } from '../../skeleton';
import {
  SparePartSearchList_process$data,
  SparePartSearchList_process$key,
} from './__generated__/SparePartSearchList_process.graphql';
import { BomSparePartRow } from './BomSparePartRow';

type Item = ExtractNodeFromConnection<SparePartSearchList_process$data['sparePartSearch']>;

const ActualComponent: FC<{
  query: SparePartSearchList_process$key | null;
}> = (props) => {
  const { translate } = useTranslation();
  const { query: queryRef } = props;
  const { data, ...pagination } = usePaginationFragment(
    graphql`
      fragment SparePartSearchList_process on Process
      @refetchable(queryName: "SparePartSearchRefetchQuery")
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 30 }
        after: { type: "ID" }
        input: { type: "ProcessSparePartSearchInput!" }
        processId: { type: "ID!" }
      ) {
        sparePartSearch(first: $first, after: $after, input: $input)
          @connection(key: "SparePartSearch_sparePartSearch") {
          edges {
            node {
              id
              ...BomSparePartRow_sparePart @arguments(processId: $processId)
            }
          }
        }
      }
    `,
    queryRef ?? null
  );

  const emptyLabel = translate('SPARE_PART_SEARCH.HEADER', 'No spare parts found');

  const errorLabel = translate('SPARE_PART_SEARCH.HEADER', 'Unexpected error occured');

  const itemKeyExtractor = useCallback((item: Item) => item.id, []);

  const itemRender = useCallback((item: Item) => <BomSparePartRow sparePart={item} />, []);

  const dividerComponent = <Divider sx={{ borderBottomWidth: '2px' }} />;
  const filtered = (data?.sparePartSearch?.edges ?? []).filter(hasValue).map(({ node }) => node);

  return (
    <InfiniteList<Item>
      {...pagination}
      emptyLabel={emptyLabel}
      errorLabel={errorLabel}
      DividerComponent={dividerComponent}
      items={filtered}
      itemKeyExtractor={itemKeyExtractor}
      itemRender={itemRender}
      itemSpacing={0}
    />
  );
};

const SkeletonComponent: FC = () => (
  <Grid container justifyContent={'center'} alignItems={'center'}>
    <Grid item>
      <ActivityIndicator />
    </Grid>
  </Grid>
);

export const SparePartSearchList = skeletonify('SparePartSearchList', ActualComponent, SkeletonComponent);

import { hasValue } from '@lego/mst-error-utilities';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, styled, TextField, Typography } from '@mui/material';
import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useMachineHoursContext } from '../../contexts/add-machine-hours/add-machine-hours-context';
import { useTranslation } from '../../utility/i18n/translation';
import { AddTimeHourAndMinutesInput } from './AddTimeHourAndMinutesInput';
import { SearchForMachineWidget } from './SearchForMachineWidget';
import { MachineType } from './__apollo__/MachineType';

const MAX_LENGTH_FOR_ADD_TIME_COMMENT = 500;

const StyledGridForDeleteButton = styled(Grid)(({ theme }) => ({
  marginTop: 8,
  [theme.breakpoints.down('md')]: {
    alignSelf: 'center',
    marginTop: 0,
  },
}));

const StyledCommentTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: 20,
  },
}));

export const AddMachineHoursRow: FC<{
  ticketId: string;
  machine?: MachineType;
}> = ({ ticketId, machine }) => {
  const { translate } = useTranslation();
  const {
    dispatch,
    state: { additions },
  } = useMachineHoursContext();

  const commentValue = useMemo(() => {
    const currentMachineInAdditions = additions.find((val) => val.machine.id === machine?.id);

    return currentMachineInAdditions?.comment ?? '';
  }, [additions, machine?.id]);

  const onCommentValueChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (machine) {
        dispatch({
          type: 'update_comment_for_addition',
          machineId: machine.id,
          comment: e.target.value,
        });
      }
    },
    [dispatch, machine]
  );

  const onMachineSelectedInSearch = useCallback(
    (selected: MachineType) => {
      dispatch({
        type: 'add_machine_to_additions_array',
        machine: selected,
      });
    },
    [dispatch]
  );

  const onCloseButtonClicked = useCallback(() => {
    if (machine) {
      dispatch({
        type: 'remove_machine_from_additions',
        machineId: machine.id,
      });
    } else {
      dispatch({ type: 'set_show_empty_row', showEmptyRow: false });
    }
  }, [dispatch, machine]);

  const onTimeChanged = useCallback(
    (minutesSpent: number) => {
      if (machine) {
        dispatch({
          type: 'update_time_reg_for_machine',
          machineId: machine.id,
          minutesSpent,
        });
      }
    },
    [dispatch, machine]
  );

  const commentFieldRows = useMemo(() => {
    const charsPrLine = 40;
    return Math.max(1, Math.ceil(commentValue.length / charsPrLine));
  }, [commentValue.length]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={1}
      sx={{
        my: 5,
      }}
    >
      <Grid item md={5} style={{ flex: 1 }}>
        {hasValue(machine) ? (
          <Typography sx={{ mt: 2 }}>{machine.description}</Typography>
        ) : (
          <SearchForMachineWidget ticketId={ticketId} onMachineSelected={onMachineSelectedInSearch} />
        )}
      </Grid>
      <Grid item md={2}>
        <AddTimeHourAndMinutesInput onTimeChanged={onTimeChanged} machineOrEmployeeId={machine?.id} />
      </Grid>
      <Grid item md xs>
        <StyledCommentTextField
          rows={commentFieldRows}
          placeholder={translate('ADD_MACHINE_HOURS.COMMENT_PLACEHOLDER', 'Comment')}
          style={{ width: '100%' }}
          variant={'outlined'}
          multiline={true}
          value={commentValue}
          inputProps={{ maxLength: MAX_LENGTH_FOR_ADD_TIME_COMMENT }}
          helperText={`${commentValue.length}/${MAX_LENGTH_FOR_ADD_TIME_COMMENT}`}
          onChange={onCommentValueChanged}
        />
      </Grid>

      <StyledGridForDeleteButton item xs={1}>
        <IconButton onClick={onCloseButtonClicked} style={{ width: 40 }} size="large">
          <CloseIcon />
        </IconButton>
      </StyledGridForDeleteButton>
    </Grid>
  );
};

import { FormControlLabel, FormGroup, Grid } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from '../../../utility/i18n/translation';
import { IOSSwitch } from '../../components/IOSSwitch';
import { useSparePartSearchContext } from './spare-part-search-context';
import { SparePartSearchField } from './SparePartSearchField';

export const SparePartBomHeader: FC = () => {
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item md={11}>
        <SparePartSearchField />
      </Grid>
      <Grid item md={1}>
        <FormGroup style={{ alignItems: 'center' }}>
          <InStockToggle />
        </FormGroup>
      </Grid>
    </Grid>
  );
};
export const InStockToggle: FC = () => {
  const {
    state: { filterOnStock },
    dispatch,
  } = useSparePartSearchContext();
  const { translate } = useTranslation();

  const handleFilterToggle = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    dispatch({ type: 'filterOnStock', payload: checked });
  };

  return (
    <FormGroup style={{ alignItems: 'center' }}>
      <FormControlLabel
        style={{ marginLeft: 0 }}
        control={<IOSSwitch checked={filterOnStock} onChange={handleFilterToggle} />}
        labelPlacement="bottom"
        label={translate('SPARE_PART_BOM.HEADER.IN_STOCK', 'In stock')}
      />
    </FormGroup>
  );
};

/**
 * @generated SignedSource<<ac0cf20e38971ca4ed46f36c2a51263c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MouldStatusInput = "New" | "Operational" | "Other" | "Qualification" | "RampUp" | "Transit" | "Unknown" | "WornOut" | "%future added value";
export type OrderDirection = "Ascending" | "Descending" | "%future added value";
export type TicketsPriorityInput = "Priority1" | "Priority2" | "Priority3" | "Priority4" | "Priority5" | "Priority6" | "Priority7" | "Priority8" | "Priority9" | "PriorityA" | "%future added value";
export type TicketsStatusInput = "Closed" | "InProgress" | "NotStarted" | "Released" | "%future added value";
export type ProcessTicketsInput = {
  activity?: string | null;
  completedDate?: LocalTimestampFilterInput | null;
  damage?: string | null;
  equipmentGroup?: string | null;
  location?: string | null;
  mouldStatus?: ReadonlyArray<MouldStatusInput> | null;
  orderBy?: ReadonlyArray<TicketsOrderBy> | null;
  priority?: ReadonlyArray<TicketsPriorityInput> | null;
  searchTerm?: string | null;
  status?: ReadonlyArray<TicketsStatusInput> | null;
  sublocationId?: string | null;
};
export type LocalTimestampFilterInput = {
  ge?: LocalTimestampInput | null;
  le?: LocalTimestampInput | null;
};
export type LocalTimestampInput = {
  date: string;
  time: string;
};
export type TicketsOrderBy = {
  completedDate?: OrderDirection | null;
  createdDate?: OrderDirection | null;
  priority?: OrderDirection | null;
  requiredEndDate?: OrderDirection | null;
  requiredStartDate?: OrderDirection | null;
};
export type TicketSearchQuery$variables = {
  input: ProcessTicketsInput;
  plantId: string;
  processId: string;
};
export type TicketSearchQuery$data = {
  readonly plant: {
    readonly " $fragmentSpreads": FragmentRefs<"TicketSearch_Filters_plant">;
  } | null;
  readonly process: {
    readonly " $fragmentSpreads": FragmentRefs<"TicketSearch_Filters_process" | "TicketSearch_process">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"TicketSearch_Filters_query">;
};
export type TicketSearchQuery = {
  response: TicketSearchQuery$data;
  variables: TicketSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "plantId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "processId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "processId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "plantId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v8 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TicketSearchQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "process",
          "args": (v3/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "tickets",
                      "variableName": "input"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "TicketSearch_process"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TicketSearch_Filters_process"
                }
              ],
              "type": "Process",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "process"
      },
      {
        "alias": "plant",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TicketSearch_Filters_plant"
              }
            ],
            "type": "Plant",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "TicketSearch_Filters_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TicketSearchQuery",
    "selections": [
      {
        "alias": "process",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Sublocation",
                "kind": "LinkedField",
                "name": "sublocations",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "type": "Process",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "plant",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Location",
                "kind": "LinkedField",
                "name": "locations",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locationNumber",
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Plant",
            "abstractKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "QueryEquipmentGroupsConnection",
        "kind": "LinkedField",
        "name": "equipmentGroups",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "QueryEquipmentGroupsConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EquipmentGroup",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a825621f8d9e2fed96bf9db7c6f9af46",
    "id": null,
    "metadata": {},
    "name": "TicketSearchQuery",
    "operationKind": "query",
    "text": "query TicketSearchQuery(\n  $plantId: ID!\n  $processId: ID!\n) {\n  process: node(id: $processId) {\n    __typename\n    ... on Process {\n      ...TicketSearch_process_3Ft0PF\n      ...TicketSearch_Filters_process\n    }\n    id\n  }\n  plant: node(id: $plantId) {\n    __typename\n    ... on Plant {\n      ...TicketSearch_Filters_plant\n    }\n    id\n  }\n  ...TicketSearch_Filters_query\n}\n\nfragment TicketSearchEquipmentGroupFilter_query on Query {\n  equipmentGroups {\n    edges {\n      node {\n        id\n        code\n        description\n      }\n    }\n  }\n}\n\nfragment TicketSearchFilters_plant on Plant {\n  ...TicketSearchLocationFilter_plant\n}\n\nfragment TicketSearchFilters_process on Process {\n  ...TicketSearchSublocationFilter_process\n  type\n}\n\nfragment TicketSearchFilters_query on Query {\n  ...TicketSearchEquipmentGroupFilter_query\n}\n\nfragment TicketSearchLocationFilter_plant on Plant {\n  locations {\n    id\n    locationNumber\n    description\n  }\n}\n\nfragment TicketSearchSublocationFilter_process on Process {\n  sublocations {\n    id\n    code\n    description\n  }\n}\n\nfragment TicketSearch_Filters_plant on Plant {\n  ...TicketSearchFilters_plant\n}\n\nfragment TicketSearch_Filters_process on Process {\n  ...TicketSearchFilters_process\n}\n\nfragment TicketSearch_Filters_query on Query {\n  ...TicketSearchFilters_query\n}\n\nfragment TicketSearch_process_3Ft0PF on Process {\n  id\n}\n"
  }
};
})();

(node as any).hash = "9ebb5154ffdaabb892d7b01ed20e6f4d";

export default node;

import { AuthError } from '@azure/msal-browser';
import { MicrosoftADInstance, msalRequest } from './config';

export type AzurePrompt = 'login' | 'select_account' | 'none' | 'consent' | undefined;

const handleAuthError = async (prompt: AzurePrompt, loginError: AuthError): Promise<string | undefined> => {
  const instance = MicrosoftADInstance;
  switch (loginError.errorCode) {
    case 'user_cancelled':
      return;
    case 'no_account_error':
    case 'login_required':
      try {
        if (prompt === 'none') {
          const res = await instance.ssoSilent(msalRequest);
          return res.accessToken;
        }
      } catch (err) {
        // Do nothing
      }
      await instance.loginRedirect({
        ...msalRequest,
        prompt: prompt === 'none' ? undefined : prompt,
      });
      return;
    case 'interaction_in_progress':
    case 'no_tokens_found':
      return;
    default:
      throw loginError;
  }
};

export const getAccessToken = async (prompt: AzurePrompt): Promise<string | undefined> => {
  const instance = MicrosoftADInstance;
  const accounts = instance.getAllAccounts();
  try {
    const request = {
      ...msalRequest,
      account: accounts[0],
    };
    const response = await instance.acquireTokenSilent(request);

    return response.accessToken;
  } catch (error) {
    if (error instanceof AuthError) {
      return await handleAuthError(prompt, error);
    }
    throw error;
  }
};

import { hasValue } from '@lego/mst-error-utilities';
import { createContext, FC, ReactElement, useCallback, useContext, useMemo, useState } from 'react';
import { getCrashReporter } from '../../utility/crash-reporter';
import { useAnalytics } from '../../migration/hooks/analytics';

export type Area = {
  id: string;
  plantId: string;
  isDefaultArea: boolean;
};

const defaultArea: Area = {
  id: '1',
  plantId: '300',
  isDefaultArea: true,
};

type AreaAndProcessContext = {
  selectedArea: Area;
  setSelectedArea: (area: Omit<Area, 'isDefaultArea'>) => void;
  selectedProcessId?: string;
  relayProcessId?: string;
  relayPlantId: string;
  setSelectedProcessId: (processId: string) => void;
};

const AreaContext = createContext<AreaAndProcessContext>({
  selectedArea: defaultArea,
  relayPlantId: window.btoa(`Plant:${defaultArea.plantId}`),
  setSelectedArea: () => null,
  setSelectedProcessId: () => null,
});

const isValidArea = (area: unknown): area is Omit<Area, 'isDefaultArea'> => {
  if (!hasValue(area) || typeof area !== 'object') {
    return false;
  }

  const areaAsRecord = area as {
    id?: unknown;
    plantId?: unknown;
    processId?: string;
  };

  return typeof areaAsRecord.id === 'string' && typeof areaAsRecord.plantId === 'string';
};

const localStorageKeyForArea = 'MMS.Area';

export const getAreaFromStorage = (): Area => {
  const storedArea = window.localStorage.getItem(localStorageKeyForArea);

  if (storedArea === null) {
    return defaultArea;
  }

  const parsedArea = JSON.parse(storedArea);

  if (isValidArea(parsedArea)) {
    return {
      ...parsedArea,
      isDefaultArea: false,
    };
  } else {
    window.localStorage.removeItem(localStorageKeyForArea);

    return defaultArea;
  }
};

const saveAreaToStorage = (area: Omit<Area, 'isDefaultArea'>) => {
  try {
    window.localStorage.setItem(localStorageKeyForArea, JSON.stringify(area));
  } catch (error) {
    getCrashReporter().captureException({
      exception: error as Error,
    });
  }
};

const localStorageKeyForProcess = 'MMS.Process';

const getProcessFromStorage = (): string | null => {
  return window.localStorage.getItem(localStorageKeyForProcess);
};

const saveProcessToStorage = (processId: string) => {
  try {
    window.localStorage.setItem(localStorageKeyForProcess, processId);
  } catch (error) {
    getCrashReporter().captureException({
      exception: error as Error,
    });
  }
};

export const AreaProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [area, setArea] = useState(getAreaFromStorage());
  const [processId, setProcessId] = useState<string | undefined>(getProcessFromStorage() ?? undefined);
  useAnalytics({ area: area.plantId, process: processId });

  const saveArea = useCallback((newArea: Omit<Area, 'isDefaultArea'>) => {
    saveAreaToStorage(newArea);
    setArea({
      ...newArea,
      isDefaultArea: false,
    });
  }, []);

  const saveProcess = useCallback((newProcessId: string) => {
    saveProcessToStorage(newProcessId);
    setProcessId(newProcessId);
  }, []);

  // TODO: We need to use our new process ids
  const relayProcessId = useMemo(() => (processId ? window.btoa(`Process:${processId}`) : undefined), [processId]);
  const relayPlantId = useMemo(() => window.btoa(`Plant:${area.plantId}`), [area.plantId]);

  return (
    <AreaContext.Provider
      value={{
        selectedArea: area,
        setSelectedArea: saveArea,
        setSelectedProcessId: saveProcess,
        selectedProcessId: processId,
        relayProcessId,
        relayPlantId,
      }}
    >
      {children}
    </AreaContext.Provider>
  );
};

export const useAreaAndProcessContext = (): AreaAndProcessContext => {
  return useContext(AreaContext);
};

/**
 * @generated SignedSource<<b21ca6b9542d08d0f1b8c9044193105a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QAOperationsData_equipment$data = {
  readonly qualityManagementInspection?: {
    readonly operations: ReadonlyArray<{
      readonly activityNumber: string;
      readonly name: string;
      readonly validationResult: string;
      readonly " $fragmentSpreads": FragmentRefs<"QAOperationsData_characteristics">;
    }>;
  } | null;
  readonly " $fragmentType": "QAOperationsData_equipment";
};
export type QAOperationsData_equipment$key = {
  readonly " $data"?: QAOperationsData_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QAOperationsData_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QAOperationsData_equipment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QualityManagementInspection",
          "kind": "LinkedField",
          "name": "qualityManagementInspection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QualityManagementOperation",
              "kind": "LinkedField",
              "name": "operations",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "activityNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "validationResult",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "QAOperationsData_characteristics"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Mould",
      "abstractKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "40da202920a98b519e00ae1989990afa";

export default node;

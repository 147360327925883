/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Which flow to use for closing the mould ticket
 */
export enum CloseMouldTicketFlow {
  GAT = "GAT",
  Green = "Green",
  YellowToMPEE = "YellowToMPEE",
  YellowToProduction = "YellowToProduction",
}

export enum MouldMachineStatus {
  MOULD_REPAIR_IN_PROD = "MOULD_REPAIR_IN_PROD",
  WAITING_FOR_MAINTENANCE = "WAITING_FOR_MAINTENANCE",
}

/**
 * Indicates repair description flow to be used for the ticket
 */
export enum RepairDocumentationFlowVariant {
  Baseline = "Baseline",
  Packing = "Packing",
}

export enum SubLocationTypeEnum {
  SubLocation = "SubLocation",
  SubLocationText = "SubLocationText",
}

export enum TicketFlowType {
  CMS = "CMS",
  GatTest = "GatTest",
  Green = "Green",
  Normal = "Normal",
  YellowFunctionalTest = "YellowFunctionalTest",
  YellowRepair = "YellowRepair",
  YellowToMPEE = "YellowToMPEE",
  YellowToMPEERejected = "YellowToMPEERejected",
  YellowToProduction = "YellowToProduction",
  YellowToProductionRejected = "YellowToProductionRejected",
  YellowToQA = "YellowToQA",
  YellowToQAApproved = "YellowToQAApproved",
  YellowToQARejected = "YellowToQARejected",
}

export enum TicketNotificationTypeEnum {
  CMS = "CMS",
  MPEE = "MPEE",
  NonPmActivity = "NonPmActivity",
  Normal = "Normal",
  UnKnown = "UnKnown",
}

export enum TicketPriorityEnum {
  GAT = "GAT",
  LightCleaning = "LightCleaning",
  MouldCall = "MouldCall",
  OrderEndCleaning = "OrderEndCleaning",
  OrderEndRepair = "OrderEndRepair",
  PlannedCleaning = "PlannedCleaning",
  PlannedRepair = "PlannedRepair",
  Priority1 = "Priority1",
  Priority2 = "Priority2",
  Priority3 = "Priority3",
  Priority4 = "Priority4",
  Priority5 = "Priority5",
  Priority6 = "Priority6",
  Priority7 = "Priority7",
  Priority8 = "Priority8",
  Priority9 = "Priority9",
  PriorityA = "PriorityA",
  Wornout = "Wornout",
}

/**
 * The status of the Ticket
 */
export enum TicketStatus {
  Closed = "Closed",
  Deleted = "Deleted",
  InProgress = "InProgress",
  NotStarted = "NotStarted",
  Released = "Released",
}

export enum TicketTypeEnum {
  Corrective = "Corrective",
  Observation = "Observation",
  Preventive = "Preventive",
}

export enum TicketsSortingInputEnum {
  CompletedDate = "CompletedDate",
  DueDate = "DueDate",
  StartDate = "StartDate",
}

export enum UserLocale {
  CHINESE = "CHINESE",
  CZECH = "CZECH",
  DANISH = "DANISH",
  ENGLISH = "ENGLISH",
  HUNGARIAN = "HUNGARIAN",
  SPANISH = "SPANISH",
}

export interface AddManhoursInput {
  ticketId: string;
  minutes: number;
  employeeId: string;
  comment?: string | null;
}

export interface AddRepairDocumentationForTicketInput {
  ticketId: string;
  subEquipment?: string | null;
  component?: string | null;
  damage: string;
  cause: string;
  activities: string[];
  images?: ImageUploadInput[] | null;
  createdAt: string;
  customDamageDescription?: string | null;
  customCauseDescription?: string | null;
  customComponentDescription?: string | null;
  customSubEquipmentDescription?: string | null;
  repairComment?: string | null;
}

export interface AllLocationsForPlantInput {
  plantId: string;
}

export interface AllProcessesByAreaIdInput {
  areaId: string;
}

export interface AssignUserToTicketInput {
  ticketId: string;
}

export interface BulkMachineTimeAdditionInput {
  minutes: number;
  machineId: string;
  comment?: string | null;
}

export interface BulkTimeAdditionInput {
  minutes: number;
  employeeId: string;
  comment?: string | null;
}

export interface CloseMouldTicketGatInfo {
  isApproved: boolean;
  rejectionReason?: string | null;
}

export interface CloseMouldTicketInput {
  ticketId: string;
  mouldWasCleaned: boolean;
  springChanged?: boolean | null;
  flowType: CloseMouldTicketFlow;
  gatInfo?: CloseMouldTicketGatInfo | null;
  cleaningInterval?: number | null;
  springChangeInterval?: number | null;
}

export interface CloseTicketInput {
  ticketId: string;
}

export interface ConfidentialityAccessLogQueryInput {
  imageId: string;
}

export interface ConfidentialityQueryInput {
  areaId?: string | null;
  processId?: string | null;
  userSearchTerm?: string | null;
  createdAtFrom?: string | null;
  createdAtTo?: string | null;
}

export interface CreateTicketInput {
  system: string;
  description: string;
  createdDate: string;
  isRecurring: boolean;
  isChangeOverIssue?: boolean | null;
  priority: TicketPriorityEnum;
  equipmentId: string;
  images?: ImageUploadInput[] | null;
  assignedTo?: string | null;
  mouldMachineStatus?: MouldMachineStatus | null;
  requiredEndDate?: string | null;
}

export interface EmployeeSearchInput {
  searchTerm: string;
}

export interface EquipmentByIdInput {
  id: string;
}

export interface EquipmentPossibleLocationsQueryInput {
  equipmentId: string;
}

export interface EquipmentPossibleSublocationsQueryInput {
  equipmentId: string;
}

export interface EquipmentUpdateLocationInput {
  equipmentId: string;
  newLocationId: string;
  updatedDate: string;
}

export interface EquipmentUpdateSublocationInput {
  equipmentId: string;
  sublocationString: string;
  updatedDate: string;
}

export interface ImageUploadInput {
  guid: string;
  order: number;
}

export interface MachineTypesForMachineHoursInput {
  ticketId: string;
}

export interface ManageMachineHoursInBulkInput {
  ticketId: string;
  timeRegistrationIdsToDelete: string[];
  additions: BulkMachineTimeAdditionInput[];
}

export interface ManageTimeRegistrationsInBulkInput {
  ticketId: string;
  timeRegistrationNumbersToDelete: string[];
  additions: BulkTimeAdditionInput[];
}

export interface RepairDescriptionActivityOptionsForTicket {
  ticketId: string;
}

export interface RepairDescriptionCauseOptionsForTicket {
  ticketId: string;
  chosenDamage: string;
}

export interface RepairDescriptionDamageOptionsForTicket {
  ticketId: string;
  componentId?: string | null;
}

export interface RepairDocumentationComponentOptionsForTicket {
  ticketId: string;
  subEquipmentId: string;
}

export interface RepairDocumentationSubEquipmentOptionsForTicket {
  ticketId: string;
}

export interface SelectLanguageInput {
  language: UserLocale;
}

export interface SelectProcessInput {
  processId: string;
}

export interface SparePartsForTicketInput {
  ticketId: string;
}

export interface SublocationsForProcessInput {
  processId: string;
}

export interface TicketAddErrorDescriptionInput {
  ticketId: string;
  description: string;
  images?: ImageUploadInput[] | null;
}

export interface TicketByIdInput {
  id: string;
}

export interface TicketConnectionQueryInput {
  processId?: string | null;
  priorities: TicketPriorityEnum[];
  isAssigned?: boolean | null;
  assignedToEmployee?: string | null;
  endDateRange?: string | null;
  startDateRange?: string | null;
  sorting?: TicketsSortingInputEnum | null;
  locationIds?: string[] | null;
  sublocationIds?: string[] | null;
  status?: TicketStatus[] | null;
}

export interface TicketListConnectionQueryInput {
  processId?: string | null;
  priorities?: TicketPriorityEnum[] | null;
  assignedToEmployee?: string | null;
  searchTerm?: string | null;
  sorting?: TicketsSortingInputEnum | null;
  status?: TicketStatus[] | null;
  completedFromDate?: string | null;
  completedToDate?: string | null;
  equipmentId?: string | null;
  locationId?: string | null;
  sublocationId?: string | null;
}

export interface UnassignTicketInput {
  ticketId: string;
}

/**
 * Update the cleaning interval of a mould and its sister moulds
 */
export interface UpdateMouldCleaningIntervals {
  mouldId: number;
  newCleaningInterval: number;
  sisterMouldIdsToUpdate: number[];
  updatedAt: string;
}

export interface UpdateTicketPriorityInput {
  ticketId: string;
  requiredEndDate?: string | null;
  priority: TicketPriorityEnum;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

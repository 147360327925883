import { Grid, Skeleton, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import { useTranslation } from '../../utility/i18n/translation';
import { TicketRepairDocumentationAccordion } from '../repair-documentation/TicketRepairDocumentation';
import { skeletonify } from '../skeleton';
import { EquipmentHistoryCardRepairDocumentation_repairDocumentation$key } from './__generated__/EquipmentHistoryCardRepairDocumentation_repairDocumentation.graphql';

const ActualComponent = (props: {
  repairDocumentation: EquipmentHistoryCardRepairDocumentation_repairDocumentation$key;
}) => {
  const { repairDocumentation: repairDocumentationRef } = props;
  const { translate } = useTranslation();

  const repairDocumentation = useFragment(
    graphql`
      fragment EquipmentHistoryCardRepairDocumentation_repairDocumentation on RepairDocumentation {
        ...TicketRepairDocumentationAccordion_repairDocumentation
      }
    `,
    repairDocumentationRef
  );

  return (
    <Grid item container direction="column" spacing={1} pl={1}>
      <Grid item>
        <Typography variant="subtitle2" textAlign="start">
          {translate('TICKET_HISTORY_CARD.REPAIR_DOCUMENTATION', 'Repair documentation')}
        </Typography>
      </Grid>
      <Grid item>
        <TicketRepairDocumentationAccordion repairDocumentation={repairDocumentation} padding={0} />
      </Grid>
    </Grid>
  );
};

const SkeletonComponent = () => {
  return (
    <Grid item container direction="column" spacing={1} pl={1}>
      <Grid item>
        <Skeleton width={150} height={32} />
      </Grid>
      <Grid item>
        <TicketRepairDocumentationAccordion.Skeleton />
      </Grid>
    </Grid>
  );
};

export const EquipmentHistoryCardRepairDocumentation = skeletonify(
  'EquipmentHistoryCardRepairDocumentation',
  ActualComponent,
  SkeletonComponent
);
